import * as React from 'react';
import { useState, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
    AppBar,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Popover,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Toolbar,
    Tooltip,
    Checkbox
} from '@material-ui/core';
import {
    Done,
    GetApp,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Loop,
    PictureAsPdf,
    Print,
    Search,
    Today
} from '@material-ui/icons';
import { DateTime } from 'luxon';
import * as Styled from './styled';
import { Client, Matter, MatterTypeText, PdfFormatType, TimeKeeperAssignment } from '../../api/types/types';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import { MATTER_TYPE } from 'store/timeentry.store';
import TimeEntryList from 'components/TimeEntryList/TimeEntryList';
import TimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { InlineDatePicker } from 'material-ui-pickers';
import { StandardTextFieldProps } from '@material-ui/core/TextField';
import { FlexDiv } from 'common/flex';
import ImmutableTimeEntry from '../../api/immutables/ImmutableTimeEntry';
import Pagination from '../Pagination/Pagination';
import ExcelIcon from '../../images/excel.png';
import { exportToXLSX, toExcelFormat } from '../../util/ExportToExcel';
import { renderToStaticMarkup } from 'react-dom/server';
import PrintAsTimeEntryPdfExport from '../TimeEntries/PrintAsTimeEntryPdfExport';
import printJS from 'print-js';
import * as StyledPdf from '../TimeEntries/styled.pdf';
import { fileNameForExport, systemTimeZone } from '../../util/utils';
import { buildPdf } from '../../util/SaveAsTimeEntryPDF';
import { Platform } from '../../util/Platform';
import { isoDate, getDateFormat } from '../../util/date';
import { FabContainerView } from 'components/FabContainer/FabContainerView';
import { TKConsumer } from 'common/TKProvider';
import { RootStoreContext } from '../../App';
import BulkUploadButton from 'containers/BulkUploadButton';
const { STYLES_FOR_PDF } = StyledPdf;

const TimeEntries = () => {
    const [popOverPdfEl, setPopOverPdfEl] = useState<HTMLElement | null>(null);
    const [pdfFormatTypeValue, setPdfFormatTypeValue] = useState<PdfFormatType>(PdfFormatType.DATETYPE);
    const entriesListRef = useRef<HTMLDivElement>(null);
    const rootStore = useContext(RootStoreContext);
    const { t } = useTranslation([ 'timeentries', 'common' ]);
    const openedPdfEl = Boolean(popOverPdfEl);
    const { features, isProject, getActiveTimeKeeper } = rootStore.appStore;
    const matterLabel = features.EpochConfigMatterLabel;
    const billableTextKey = isProject ? 'billable' : 'matter_type';

    const {
        dirty,
        fromDate,
        untilDate,
        status,
        matterType,
        selectedEntryIds,
        expandedEntryIds,
        searchText,
        client,
        matter,
        loading,
        noEntries,
        validationState,
        referenceTE,
        durVstate,
        allEntriesSelected,
        filteredEntries,
        serverTimeEntries,
        currentPage,
        entriesPerPage,
        matterLoading,
        groupEntriesByClient,
        groupEntriesByMatter,
        timeEntriesMapForPDF,
        showOnlyRejectedEntries,
        loadEntry,
        getEntries,
        setTimeEntryRange,
        setReferenceTE,
        setClient,
        setMatter,
        setMatterType,
        setBillable,
        setStatus,
        setSearchText,
        setPageNum,
        setTimeEntryDateRange,
        setEntriesPerPage,
        setFieldLoaderFn,
        setSelectedTimeEntries,
        setExpandedTimeEntries,
        saveEntries,
        postEntries,
        unpostEntries,
        copyTimeEntry,
        revertEntry,
        splitEntry,
        mergeEntries,
        transferEntries,
        deleteEntries,
        changeEntry,
        saveDirtyEntries,
        postSelectedEntries,
        moveSelectedEntries,
        deleteSelectedEntries,
        determineCodeSets,
        toggleSelectAllFlag,
        toggleShowOnlyRejectedEntries,
        openNewTimeEntryDialog,
        getAggregateTotalsFor,
        resetTimeEntryStore
    } = rootStore.timeEntryStore;
    let fieldSize: 3 | 4 = (matter && matter.id === -1) ? 3 : 4;

    useEffect(() => {
        let loc = DateTime.local().setZone(systemTimeZone);
        let _fromDate = DateTime.utc(loc.year, loc.month, loc.day)
            .minus({ days: features.EpochConfigTimeEntriesPageDays ? features.EpochConfigTimeEntriesPageDays : 7 });
        let _untilDate = DateTime.utc(loc.year, loc.month, loc.day);
        setTimeEntryDateRange();
        setTimeEntryRange(_fromDate, _untilDate);
        getEntries();
        rootStore.timeEntryStore.expandedEntryIds = [];
        return () => {
            resetTimeEntryStore();
        }
    }, []);

    const handleFromDateChange = (d: Date) => {
        const workDate: DateTime = DateTime.local(
            d.getFullYear(),
            d.getMonth() + 1,
            d.getDate(),
            0,
            0,
            0
        );

        let _fromDate = workDate;
        let _untilDate = untilDate;
        if (_untilDate <= workDate) {
            _untilDate = workDate;
        }
        setTimeEntryRange(_fromDate, _untilDate);
    }
    const handleUntilDateChange = (d: Date) => {
        const workDate: DateTime = DateTime.local(
            d.getFullYear(),
            d.getMonth() + 1,
            d.getDate(),
            0,
            0,
            0
        );

        let _fromDate = fromDate;
        let _untilDate = workDate;
        if (_fromDate >= _untilDate) {
            _fromDate = workDate;
        }
        setTimeEntryRange(_fromDate, _untilDate);
    }
    const handleClientChange = (c: Client | null | undefined) => {
        setClient(c);
        handleMatterChange(null);
    }
    const handleMatterChange = (m: Matter | null | undefined) => {
        changePage(1);
        rootStore.timeEntryStore.selectedEntryIds = [];
        setMatter(m);
        setReferenceTE(null);
    }
    const handleSearchTextChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        changePage(1);
        setSearchText(evt.target.value);
    }
    const handleStatusChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        changePage(1);
        setStatus(evt.target.value);
    }
    const handleMatterTypeChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const value = evt.target.value;
        changePage(1);
        setMatterType(value);
        if (isProject) {
            _setBillable(value);
        }
    }
    const _setBillable = (val: string) => {
        if (MATTER_TYPE[val] === MATTER_TYPE.BILLABLE) {
            setBillable(true);
        } else if (MATTER_TYPE[val] === MATTER_TYPE.NON_BILLABLE) {
            setBillable(false);
        } else {
            setBillable(undefined);
        }
    }
    const reset = () => {
        let loc = DateTime.local();
        setSearchText('');
        setStatus('All');
        setMatterType(MATTER_TYPE.All);
        setTimeEntryDateRange();
        setClient(null);
        handleMatterChange(null);
        getEntries();
        rootStore.timeEntryStore.expandedEntryIds = [];
        // resetting pagination values
        setPageNum(1);
        setEntriesPerPage(25);
    }
    const search = () => {
        getEntries();
        setPageNum(1);
    }
    const editEntry = async (entry: TimeEntry) => {
        await loadEntry(entry.clone());
    }
    const deleteEntry = (entry: TimeEntry) => {
        deleteEntries([entry]);
    }
    const saveEntry = (entry: TimeEntry) => {
        saveEntries([entry]);
    }
    const postEntry = async (entry: TimeEntry) => {
        let expandedIds = expandedEntryIds;
        if (!expandedIds.includes(entry.id!)) {
            await determineCodeSets(entry.id!);
        }
        postEntries([entry.id!]);
    }
    const unpostEntry = async (entry: TimeEntry) => {
        unpostEntries([entry.id!]);
    }
    const clearSelectedEntries = () => {
        setSelectedTimeEntries([]);
    }
    const fetchRefs = async (text: string) => {
        let ret: TimeEntry[] = [];
        const uniqueRet = new Map();
        
        serverTimeEntries.forEach((entry) => {
            uniqueRet.set(entry.reference, entry);
        });
        uniqueRet.forEach((te, k) => {
            if (k) { ret.push(te); }
        });
        return ret;
    }
    const exportToExcel = async () => {
        const currentTimeKeeper = getActiveTimeKeeper();
        
        const fileName = fileNameForExport(currentTimeKeeper, t('export.pdf.title'));
        
        const sortByDateEntries = filteredEntries.sort((e1, e2) => {
            if (DateTime.fromISO(e1.workDateTime) > DateTime.fromISO(e2.workDateTime)) { return 1; }
            if (DateTime.fromISO(e1.workDateTime) < DateTime.fromISO(e2.workDateTime)) { return -1; }
            return 0;
        });
        const excelEntries = toExcelFormat(sortByDateEntries, false, t);
        
        exportToXLSX(excelEntries, fileName, t, false);
    }
    const customizeHeadersForPdf = (): Map<string, ImmutableTimeEntry[]> => {
        let timeEntriesMap = new Map<string, ImmutableTimeEntry[]>();

        switch (pdfFormatTypeValue) {
            case PdfFormatType.CLIENTTYPE:
                timeEntriesMap = groupEntriesByClient;
                break;
            case PdfFormatType.MATTERTYPE:
                timeEntriesMap = groupEntriesByMatter;
                break;
            case PdfFormatType.DATETYPE:
                [...timeEntriesMapForPDF.entries()].sort()
                    .forEach(([date, tentries]) => {
                        let d = DateTime.fromISO(date).toFormat('DDDD');
                        timeEntriesMap.set(d, tentries);
                    });
                break;
            default:
                timeEntriesMap = timeEntriesMapForPDF;
                break;
        }
        
        return timeEntriesMap;
    }
    const printPDF = () => {
        const currentTimeKeeper = getActiveTimeKeeper();
        const fileName = fileNameForExport(currentTimeKeeper, t('export.pdf.title'));

        let timeEntriesMap = customizeHeadersForPdf();

        let html = renderToStaticMarkup(
            <PrintAsTimeEntryPdfExport
                timeEntriesMap={timeEntriesMap}
                exportType={pdfFormatTypeValue}
                timeKeeper={currentTimeKeeper ? currentTimeKeeper.name : ''}
                fromDate={fromDate}
                toDate={untilDate}
                isMgmtDashboard={false}
            />);
        
        let pdfDiv = new DOMParser().parseFromString(html, 'text/html');
        let pdfContainer = document.createElement('div');
        
        if (pdfDiv.body.firstChild) {
            pdfContainer.appendChild(pdfDiv.body.firstChild);
            pdfContainer.setAttribute('style', 'visibility: hidden')
            document.body.appendChild(pdfContainer);
            printJS({
                printable: 'printjs-form',
                type: 'html',
                documentTitle: t('export.pdf.title'),
                style: STYLES_FOR_PDF,
                onLoadingStart: () => { document.title = fileName; },
                onPrintDialogClose: () => { document.title = 'Epoch'; },
                honorColor: true,
                honorMarginPadding: true
            });
            
            document.body.removeChild(pdfContainer);
        }
        
        handleClosePdfPopOver();
    }
    const saveAsPDF = async () => {
        const currentTimeKeeper = getActiveTimeKeeper();
        const timeEntriesMap = customizeHeadersForPdf();
        const fileName = fileNameForExport(currentTimeKeeper, t('export.pdf.title'));

        let doc = await buildPdf(
            timeEntriesMap,
            pdfFormatTypeValue,
            currentTimeKeeper ? currentTimeKeeper.name : '',
            fromDate,
            untilDate,
            false,
            t
        );
        
        if (Platform.isElectron()) {
            let pdfString = doc.output('datauristring');
            const {dialog} = require('electron').remote;
            const fs = require('fs-jetpack');
            let dataUriToBuffer = require('data-uri-to-buffer');
            const path = require('path');
            const bufferData = dataUriToBuffer(pdfString);

            dialog.showSaveDialog({
                    defaultPath: fileName,
                    filters: [{
                            name: 'pdf',
                            extensions: ['pdf']
                    }]
                }, (file: string) => {
                    if (file === undefined) {
                        return;
                    }
                    fs.write(
                        path.normalize(file),
                        bufferData
                    );
                }
            );
        } else {
            doc.save(`${fileName}.pdf`);
        }
        
        handleClosePdfPopOver();
    }
    const openPdfPopOver = async (evt: React.MouseEvent<HTMLElement>) => {
        setPopOverPdfEl(evt.currentTarget);
    }
    const handleClosePdfPopOver = () => {
        setPopOverPdfEl(null);
    }
    const handlePdfFormatTypeChange = (event: React.ChangeEvent<HTMLFormElement>, val: string) => {
        setPdfFormatTypeValue(val as PdfFormatType);
    }
    const changePage = (pageNum: number) => {
        setPageNum(pageNum);
        entriesListRef.current!.scrollTop = 0;
    }
    const changeEntriesPerPage = (n: number) => {
        setEntriesPerPage(n);
        changePage(1);
    }
    const fetchMatters = async (text: string, clientId: number | undefined) => {
        let  results: Matter[] = await rootStore.api.Matter.searchMatters(text, true, clientId!);

        if (!features.EpochConfigTrackedMatterClientsEnabled && results.length === 0) {
            results = await rootStore.api.Matter.getAvailableMatters(text, false, clientId!);
        }
        return results;
    }
    const handleRejectedChange = () => {
        changePage(1);
        toggleShowOnlyRejectedEntries();
    }

    return (
        <>
            <AppBar position={'static'}>
                <Toolbar variant={'regular'}>
                    <Grid container={true} xs={12}>
                        <Styled.StyledGrid
                            container={true}
                            xs={12}
                            spacing={8}
                            disabled={loading}
                        >
                            <Grid item={true} xs={2} md={(fieldSize - 1) as 2 | 3}>
                                <TextField
                                    id={'input-with-icon-textfield'}
                                    label={t('header.field.search.label')}
                                    placeholder={t('header.field.search.place_holder')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position={'start'}>
                                                <Search/>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={searchText}
                                    fullWidth={true}
                                    onChange={handleSearchTextChange}
                                />
                            </Grid>
                            <Grid item={true} xs={2}>
                                <InlineDatePicker
                                    format={fromDate.toFormat(getDateFormat())}
                                    value={isoDate(fromDate)}
                                    label={t('header.field.date.from.label')}
                                    onChange={handleFromDateChange}
                                    leftArrowIcon={<KeyboardArrowLeft/>}
                                    rightArrowIcon={<KeyboardArrowRight/>}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={'end'}>
                                                <Today/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    TextFieldComponent={
                                        (props: StandardTextFieldProps) =>
                                            <TextField {...props} fullWidth={true}/>
                                    }
                                />
                            </Grid>
                            <Grid item={true} xs={2}>
                                <InlineDatePicker
                                    format={untilDate.toFormat(getDateFormat())}
                                    value={isoDate(untilDate)}
                                    label={t('header.field.date.to.label')}
                                    onChange={handleUntilDateChange}
                                    leftArrowIcon={<KeyboardArrowLeft/>}
                                    rightArrowIcon={<KeyboardArrowRight/>}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={'end'}>
                                                <Today/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    TextFieldComponent={
                                        (props: StandardTextFieldProps) =>
                                            <TextField {...props} fullWidth={true}/>
                                    }
                                />
                            </Grid>
                            <Grid item={true}>
                                <FlexDiv style={{marginTop: 7}}>
                                    <IconButton title={t('header.action.reset.tooltip')} onClick={reset}>
                                        <Loop />
                                    </IconButton>
                                    <IconButton title={t('header.action.apply_date.tooltip')} onClick={search}>
                                        <Done />
                                    </IconButton>
                                    <IconButton
                                        key={'csv'}
                                        onClick={exportToExcel}
                                        title={t('header.action.download_excel.tooltip')}
                                        disabled={noEntries}
                                    >
                                        <img src={ExcelIcon}/>
                                    </IconButton>
                                    <IconButton
                                        key={'pdf'}
                                        onClick={openPdfPopOver}
                                        title={t('header.action.pdf.tooltip')}
                                        disabled={noEntries}
                                    >
                                        <PictureAsPdf/>
                                    </IconButton>
                                    {features.EpochConfigTimeEntryUploadEnabled && <BulkUploadButton />}
                                    <Popover
                                        id="download-pdf-pop"
                                        open={openedPdfEl}
                                        anchorEl={popOverPdfEl}
                                        onClose={handleClosePdfPopOver}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <div>
                                            <FormControl style={{width: '140px', padding: '10px'}}>
                                                <FormLabel>{t('header.action.pdf.menu.group_by.label')}:</FormLabel>
                                                <RadioGroup
                                                    aria-label={t('header.action.pdf.menu.group_by.label')}
                                                    name={'groupBy'}
                                                    value={pdfFormatTypeValue}
                                                    onChange={handlePdfFormatTypeChange}
                                                >
                                                    {Object.keys(PdfFormatType)
                                                        .filter(p => PdfFormatType[p] !== PdfFormatType.TIMEKEEPER)
                                                        .map((key) => {
                                                            const typeKey = PdfFormatType[key].toLowerCase().replace(' ', '_');
                                                            const transKey = `header.action.pdf.menu.group_by.option.${typeKey}`;
                                                            return(
                                                                <FormControlLabel
                                                                    key={key}
                                                                    control={<Radio/>}
                                                                    label={t(transKey, {matterLabel})}
                                                                    value={PdfFormatType[key]}
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </RadioGroup>
                                                <Styled.PrintIcons>
                                                    <Tooltip title={t('header.action.pdf.menu.action.print.tooltip')}>
                                                        <IconButton
                                                            onClick={printPDF}
                                                            aria-label={t('header.action.pdf.menu.action.print.tooltip')}
                                                        >
                                                            <Print/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={t('header.action.pdf.menu.action.download.tooltip')}>
                                                        <IconButton
                                                            onClick={saveAsPDF}
                                                            aria-label={t('header.action.pdf.menu.action.download.tooltip')}
                                                        >
                                                            <GetApp/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Styled.PrintIcons>
                                            </FormControl>
                                        </div>
                                    </Popover>
                                </FlexDiv>
                            </Grid>
                            <TKConsumer>
                                {(tk: TimeKeeperAssignment) =>
                                    <Grid item={true}>
                                        {matter && matter.id === -1 ?
                                            <FlexDiv
                                                fillContainer={true}
                                                style={{ marginTop: 5, alignItems: 'center' }}
                                            >
                                                <Tooltip title={t('select_all', { ns: 'common' })}>
                                                    <Checkbox
                                                        checked={allEntriesSelected}
                                                        onChange={toggleSelectAllFlag}
                                                        style={{ padding: 0 }}
                                                        disabled={!tk.writable}
                                                    />
                                                </Tooltip>
                                                &nbsp;
                                                {tk.writable &&
                                                    <span> {t('select_all', { ns: 'common' })} </span>
                                                }
                                            </FlexDiv>
                                        :
                                            null
                                        }
                                    </Grid>
                                }
                            </TKConsumer>
                            <Grid item={true}>
                                {features.EpochConfigManagementDashboard ?
                                    <FlexDiv
                                        fillContainer={true}
                                        style={{ marginTop: 5, alignItems: 'center' }}
                                    >
                                        <Checkbox
                                            checked={showOnlyRejectedEntries}
                                            onChange={handleRejectedChange}
                                            style={{ padding: 0 }}
                                        />
                                        &nbsp;
                                        <span style={{color: 'rgba(0, 0, 0, 0.38)'}}>{t('header.checkbox.rejected.label')}</span>
                                    </FlexDiv>
                                    :
                                    null
                                }
                            </Grid>
                        </Styled.StyledGrid>
                        <Styled.StyledGrid
                            container={true}
                            xs={12}
                            spacing={8}
                            disabled={loading}
                        >
                            <Grid item={true} xs={2}>
                                <FormControl
                                    fullWidth={true}
                                >
                                    <InputLabel shrink={true}>
                                        {t('header.field.status.label')}
                                    </InputLabel>
                                    <Select
                                        input={<Input name={'status'} id={'status-label-placeholder'}/>}
                                        displayEmpty={true}
                                        name={'select-status'}
                                        value={status}
                                        onChange={handleStatusChange}
                                    >
                                        <MenuItem value={'All'}>{t('header.field.status.option.all')}</MenuItem>
                                        <MenuItem value={SapStatus.POSTED}>{t('header.field.status.option.posted')}</MenuItem>
                                        <MenuItem value={SapStatus.UNSUBMITTED}>{t('header.field.status.option.draft')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={2}>
                                <FormControl
                                    fullWidth={true}
                                >
                                    <InputLabel shrink={true}>
                                        {t(`header.field.${billableTextKey}.label`, {matterLabel})}
                                    </InputLabel>
                                    <Select
                                        input={<Input name={'matter'} id={'matter-label-placeholder'}/>}
                                        displayEmpty={true}
                                        name={'select-matter-type'}
                                        value={matterType}
                                        onChange={handleMatterTypeChange}
                                    >
                                        {Object.keys(MATTER_TYPE).map((key) => {
                                            const typeKey = MATTER_TYPE[key].toLowerCase().replace(' ', '_');
                                            return (
                                                <MenuItem
                                                    key={key}
                                                    value={key}
                                                >
                                                    {t(`header.field.${billableTextKey}.option.${typeKey}`)}
                                                </MenuItem>
                                            )}
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={3} md={fieldSize}>
                                <AutoCompleteField
                                    label={t('field.client')}
                                    fetch={rootStore.api.Client.searchClients}
                                    currentItem={client}
                                    getItemText={(c: Client) => `${c.number} - ${c.name}`}
                                    clearable={true}
                                    onSelect={handleClientChange}
                                    onClear={() => handleClientChange(null)}
                                />
                            </Grid>
                            <Grid item={true} xs={3} md={fieldSize}>
                                <AutoCompleteField
                                    label={t('field.matter', {matterLabel})}
                                    fetch={(text: string) => fetchMatters(text, client ? client.id : undefined)
                                        .then((res) => {
                                            let blank: Matter = {
                                                id: -1,
                                                number: '',
                                                name: 'Blank',
                                                description: '',
                                                clientId: -1,
                                                isPhaseCode: false,
                                                isActCode: false,
                                                isFfTaskCode: false,
                                                clientName: '',
                                                clientNumber: '',
                                                status: '',
                                                language: '',
                                                languageText: '',
                                                timeEntryUnit: '',
                                                entryType: '',
                                                type: '',
                                                typeText: MatterTypeText.NON_BILLABLE,
                                                statusDescription: '',
                                                lastModified: '',
                                                startDate: '',
                                                endDate: '',
                                                bannedWords: [],
                                                blockBillingWords: []
                                            };
                                            if (!client && !text) {
                                                res.unshift(blank)
                                            }
                                            return res;
                                        }
                                    )}
                                    currentItem={matter}
                                    getItemText={(m: Matter) => (m.id === -1) ? m.name : `${m.number} - ${m.name}`}
                                    clearable={true}
                                    onSelect={handleMatterChange}
                                    onClear={() => handleMatterChange(null)}
                                    tooltip={(m: Matter) => m.description}
                                />
                            </Grid>
                            {matter && matter.id === -1 &&
                                <Grid item={true} xs={2} lg={2} md={2}>
                                    <AutoCompleteField
                                        label={t('field.reference')}
                                        fetch={fetchRefs}
                                        currentItem={referenceTE}
                                        getItemText={(entry: TimeEntry) => entry.reference!}
                                        clearable={true}
                                        onSelect={setReferenceTE}
                                        onClear={() => setReferenceTE(null)}
                                    />
                                </Grid>
                            }
                        </Styled.StyledGrid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Pagination
                entriesPerPage={entriesPerPage}
                currentPage={currentPage}
                entriesLength={filteredEntries.length}
                changeEntriesPerPage={changeEntriesPerPage}
                onPageChange={changePage}
            />
            <Styled.TimeEntryList innerRef={entriesListRef}>
                {[...rootStore.timeEntryStore.timeEntriesMap].map(([date, entries]) => (
                    <TimeEntryList
                        entries={entries}
                        validationMap={validationState}
                        durValidationMap={durVstate}
                        date={DateTime.fromISO(date)}
                        onChange={changeEntry}
                        selected={selectedEntryIds}
                        onSelect={setSelectedTimeEntries}
                        expanded={expandedEntryIds}
                        onExpand={setExpandedTimeEntries}
                        onCreateEntry={openNewTimeEntryDialog}
                        onPost={postEntry}
                        onUnpost={unpostEntry}
                        onSave={saveEntry}
                        onDelete={deleteEntry}
                        onCopy={copyTimeEntry}
                        onEdit={editEntry}
                        onCancel={revertEntry}
                        onSplit={splitEntry}
                        onMerge={mergeEntries}
                        onTransfer={transferEntries}
                        buildCodeSets={determineCodeSets}
                        aggregateTotals={getAggregateTotalsFor}
                        onSetFieldLoader={setFieldLoaderFn}
                    />
                ))}
                {loading && <Styled.Loading>
                    <div style={{top: '-15%', position: 'relative'}}>
                        <CircularProgress size={100} />
                    </div>
                </Styled.Loading>}
                {noEntries && <Styled.NoEntry>{t('list.empty')}</Styled.NoEntry>}
            </Styled.TimeEntryList>
            <FabContainerView
                dirty={dirty}
                saveDirtyEntries={saveDirtyEntries}
                clearSelectedEntries={clearSelectedEntries}
                postSelectedEntries={postSelectedEntries}
                moveSelectedEntries={moveSelectedEntries}
                mergeEntries={mergeEntries}
                transferEntries={transferEntries}
                deleteSelectedEntries={deleteSelectedEntries}
                selectedEntryIds={selectedEntryIds}
                disableSavePost={matterLoading}
            />
        </>
    );
}

export default observer(TimeEntries);