import { observable, action, makeObservable } from 'mobx';
import DialogRootStore from 'store/dialog.root.store';
import { RootStore } from 'store/root.store';
import { RejectionCode } from 'api/types/types';

export default class RejectionCodeDialogStore extends DialogRootStore<RejectionCode[], string> {
    @observable rejectionCodes: RejectionCode[];
    @observable selectedCode: string = '';

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    async onOpen(codes: RejectionCode[]) {
        this.rejectionCodes = codes;
        this.selectedCode = '';
    }

    @action.bound
    setSelectedCode(code: string) {
        this.selectedCode = code;
    }
}