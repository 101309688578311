import { action, observable, makeObservable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import { Narrative } from '../api/types/types';
import DialogRootStore from 'store/dialog.root.store';
import Mutex from '../api/util';
import { RootStore } from 'store/root.store';

// tslint:disable-next-line:no-any
export default class NarrativeCodesDialogStore extends DialogRootStore<Narrative[], any> {
    @observable selectedNarrative: Narrative;
    @observable narratives: Narrative[] = [];
    @observable narrative: Narrative;
    @observable currentOffset: number = 0;
    caughtEmAll: boolean = false;
    loadMutex = new Mutex();
    limit: number = 50;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action.bound
    setNarrative(narrative: Narrative) {
        this.selectedNarrative = narrative;
    }

    @action.bound 
    resetNarratives () {
        this.currentOffset = 0;
        this.caughtEmAll = false;
        this.narratives = []; 
    }

    @loadable()
    @action.bound
    async searchAvailableNarratives(offset?: number, limit?: number) {
        return await this.rootStore.api.Narrative.getAllNarratives(offset, limit);
    }

    fetchMoreAvailNarratives = async () => {
        if (this.caughtEmAll) {
            return;
        }
        await this.loadMutex.execute(async () => {
            const newElems = await this.searchAvailableNarratives(this.currentOffset, this.limit);
            this.currentOffset = this.currentOffset + newElems.length;
            if (newElems.length === 0) {
                this.caughtEmAll = true;
            }
            this.narratives = this.narratives.concat(newElems);
        });
    }

    @action.bound
    async onOpen(narratives: Narrative[]) {
        this.resetNarratives();
        await this.fetchMoreAvailNarratives();
    }

    @action setNarratives(narratives: Narrative[]) {
        this.narratives = narratives;
    }

    @action.bound
    selectNarrative(narrative: Narrative) {
        this.resolveAndClose(narrative);
    }
}