import { action, computed, observable, makeObservable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import ImmutableTimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { Matter, TimeEntryType } from 'api/types/types';
import { RootStore } from 'store/root.store';
import { DateTime } from 'luxon';
import TimeEntry from '../api/immutables/ImmutableTimeEntry';
import { debounce } from 'typescript-debounce-decorator';
import logger from '../logging/logging';
import DialogRootStore from 'store/dialog.root.store';
import { ApiResult } from 'api/util';

interface SplitEntry {
    duration: number;
    matter: Matter | null;
    actualDuration: number;
    timeEntryUnit?: string | null;
}

export default class SplitentryDialogStore extends DialogRootStore<ImmutableTimeEntry, TimeEntry[]> {
    @observable entry: ImmutableTimeEntry;
    @observable numSplit: number = 2;
    @observable splitEntries: SplitEntry[] = [];
    @observable invalidTotal: boolean;
    @observable saving: boolean = false;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action.bound 
    async onOpen(t: ImmutableTimeEntry) {
        this.entry = t;
        this.numSplit = 2;
        this.invalidTotal = false;
        this.resetSplitEntries();
    }

    @action resetSplitEntries() {
        this.splitEntries = [];
        let usedDuration = 0;
        let round = this.entry.roundingInterval;
        if (this.tooSmallIntervals) {
            return;
        }
        for (let x = 0; x < this.numSplit; x++) {
            this.splitEntries.push({
                duration: 0,
                matter: null,
                actualDuration: 0,
                timeEntryUnit: this.entry.timeEntryUnit
            });
        }
        let currIdx = 0;
        while (usedDuration < this.entry.duration) {
            let entry = this.splitEntries[currIdx];
            if (entry === undefined) {
                break;
            }
            this.splitEntries[currIdx].duration += round;
            this.splitEntries[currIdx].actualDuration += round;
            usedDuration += round;
            currIdx += 1;
            if (currIdx >= this.splitEntries.length) {
                currIdx = 0;
            }
        }
        if (this.splitEntries.length > 0 ) {
            this.splitEntries[0].matter = this.entry.matter;
        }
    }

    @action setSplitNumber(split: number) {
        this.numSplit = split;
        this.resetSplitEntries();
    }

    @action setEntries(entries: SplitEntry[]) {
        this.splitEntries = entries;
    }

    @debounce(500, {leading: false})
    @action.bound
    async wrappedSplitSave() {
        if (this.saving) {
            return;
        }
        this.saving = true;
        try {
            await this.saveSplit();
        } finally {
            this.saving = false;
        }
    }

    @loadable()
    @action.bound
    async saveSplit() {
        const { api } = this.rootStore;
        try {
            let newEntries = this.splitEntries.map((s: SplitEntry) => {
                let date = DateTime.fromISO(this.entry.workDateTime);
                let result = new ImmutableTimeEntry()
                    .setMatter(s.matter)
                    .setDuration(s.duration)
                    .setWorkDate(date)
                    .setOffice(this.entry.office!)
                    .setOfficeName(this.entry.officeName!)
                    .setNarrative(this.entry.narrative!)
                    .setStatus(SapStatus.UNSUBMITTED)
                    .setWorkLocaleId(this.entry.workLocaleId);
                result.timeKeeperId = api.Session.currentTimeKeeper!;
                result.timeEntryType = TimeEntryType.SPLIT;
                result.collaborateTks = '';
                result.collaborateInfo = null;
                return result;
            });

            let splitEntriesDuration = this.splitEntries.reduce((prev: number, curr: SplitEntry) => {
                return prev + curr.duration
            }, 0);

            let totalDuration = await api.TimeEntry.getTotalForDateExclusive(
                this.entry.workDateTime,
                [this.entry.id!]
            );
            if ((splitEntriesDuration + totalDuration) <= ( 60 * 60 * 24 )) {
                this.invalidTotal = false;
                this.entry.deleted = true;
                let timerChunks = await api.Timer.getChunksByTimeEntryId([this.entry.id!]);
                let timeCastSegments = await api.TimeCast.getTimeCastSegmentsByTimeEntryIds([this.entry.id!]);
                let results = await api.TimeEntry.updateEntries([this.entry, ...newEntries]);
                if (results.some(r => r.status.failed)) {
                    const errorMessages = results.reduce((prev: string, curr: ApiResult<ImmutableTimeEntry>) =>
                        curr.status.failed ? prev.concat(curr.status.message) : prev
                    , '');
                    this.rootStore.snackbarStore.triggerSnackbar(errorMessages);
                    return;
                }
                timerChunks.forEach(ch => ch.timeEntryId = results[1].object.id);
                if (timerChunks.length > 0) {
                    await api.Timer.updateChunks(timerChunks);
                }
                timeCastSegments.forEach(seg => seg.associatedTimeEntry = results[1].object.id);
                let tcResult = timeCastSegments.map(tcs => tcs.id!);
                if (timeCastSegments.length > 0) {
                    await api.TimeCast.updateTimeCastSegments(tcResult, results[1].object.id!);
                }
                let entries = results.map((r) => Object.assign(new TimeEntry(), JSON.parse(JSON.stringify(r.object))));
                this.resolveAndClose(entries);
                this.rootStore.snackbarStore.triggerSnackbar('dialog.split.action.save.snackbar.success', { ns: 'timeentries' });
            } else {
                this.invalidTotal = true;
            }
        } catch (e) {
            logger.info('Time Entries, Saving Split Entries failed.\n', e);
            throw e;
        }
    }

    @computed get unequalDuration(): boolean {
        if (!this.entry) {
            return false;
        }
        let sumEntries: number = this.splitEntries.reduce((previousValue: number, currentValue: SplitEntry) =>
            previousValue + currentValue.duration
        , 0);
        return sumEntries !== this.entry.duration;
    }
    @computed get filledEntries(): boolean {
        return this.splitEntries.every((e) => e.matter !== null);
    }
    @computed get savable(): boolean {
        return this.filledEntries && !this.tooSmallIntervals && !this.invalidDuration && this.numSplit > 1;
    }
    @computed get tooSmallIntervals() {
        if (!this.entry) {
            return true;
        }
        return this.entry.duration / this.numSplit < this.entry.roundingInterval;
    }
    @computed get invalidDuration() {
        if (!this.entry) {
            return false;
        }
        let sumEntries: number = this.splitEntries.reduce((previousValue: number, currentValue: SplitEntry) =>
            previousValue + currentValue.duration
        , 0);
        return sumEntries > (60 * 60 * 24);
    }
}