import { action, makeObservable, observable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import BaseStore from './base.store';
import { RootStore } from './root.store';
import { Report } from '../api/types/types';

export default class ReportsStore extends BaseStore {
    session = this.rootStore.api.SupersetSession;
    report = this.rootStore.api.Report;
    @observable currentDashboard: string = '';
    @observable dashboardsByCategory: { [key: string]: Array<Report>; } = {};

    constructor(root: RootStore) {
        super(root);
        makeObservable(this);
    }

    @action.bound
    setDashboard(id: string) {
        this.currentDashboard = id;
    }

    async initializeSession() {
        await this.session.initialize();
    }

    @loadable()
    @action.bound
    async fetchGuestToken() {
        let response = await this.session.axios.post('/security/guest_token/', {
            resources: [
                {
                    id: this.currentDashboard,
                    type: 'dashboard'
                }
            ],
            user: {
                first_name: 'API',
                last_name: 'USER',
                username: 'api_user'
            },
            rls: []
        });

        return response.data.token;
    }

    @loadable()
    @action.bound
    async fetchReports() {
        this.dashboardsByCategory = await this.report.all()
            .then((a) => a.reduce((entry, e) => {
                (entry[e.category] = entry[e.category] || []).push(e);
                return entry;
            }, {}));
    }
}
