import * as React from 'react';
import { useState, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
    AppBar,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Popover,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Toolbar,
    Tooltip,
    Checkbox,
    ListItemText
} from '@material-ui/core';
import {
    Add,
    DoneAll,
    PlaylistAddCheck,
    Done,
    GetApp,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Loop,
    PictureAsPdf,
    Print,
    Search,
    Today
} from '@material-ui/icons';
import { DateTime } from 'luxon';
import * as Styled from './styled';
import { ApprovalStatus, Client, Code, Delegator, Employee, Matter, MatterTypeText, PdfFormatType, Role } from 'api/types/types';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import TimeEntryList from 'components/TimeEntryList/TimeEntryList';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import { InlineDatePicker } from 'material-ui-pickers';
import { StandardTextFieldProps } from '@material-ui/core/TextField';
import { FlexDiv } from 'common/flex';
import ImmutableTimeEntry from 'api/immutables/ImmutableTimeEntry';
import Pagination from '../Pagination/Pagination';
import ExcelIcon from '../../images/excel.png';
import { exportToXLSX, toExcelFormat } from 'util/ExportToExcel';
import { renderToStaticMarkup } from 'react-dom/server';
import PrintAsTimeEntryPdfExport from '../TimeEntries/PrintAsTimeEntryPdfExport';
import printJS from 'print-js';
import * as StyledPdf from '../TimeEntries/styled.pdf';
import { fileNameForExport, parseCode, systemTimeZone } from 'util/utils';
import { buildPdf } from 'util/SaveAsTimeEntryPDF';
import { Platform } from 'util/Platform';
import { isoDate, getDateFormat } from 'util/date';
import { FabContainerView } from 'components/FabContainer/FabContainerView';
import { RootStoreContext } from '../../App';
const { STYLES_FOR_PDF } = StyledPdf;

interface Props {
    draftTime?: boolean;
}

const ManagementDashboard = (props: Props) => {
    const [popOverPdfEl, setPopOverPdfEl] = useState<HTMLElement | null>(null);
    const [pdfFormatTypeValue, setPdfFormatTypeValue] = useState<PdfFormatType>(PdfFormatType.DATETYPE);
    const entriesListRef = useRef<HTMLDivElement>(null);
    const rootStore = useContext(RootStoreContext);
    const { t } = useTranslation([ 'management_dashboard', 'common' ]);
    const openedPdfEl = Boolean(popOverPdfEl);
    const { features, getActiveTimeKeeper, approvalStages } = rootStore.appStore;
    const matterLabel = features.EpochConfigMatterLabel;

    const {
        dirty,
        roles,
        selectedRole,
        approvalTimekeeperId,
        fromDate,
        untilDate,
        approvalStatus,
        selectedEntryIds,
        expandedEntryIds,
        searchText,
        delegator,
        client,
        matter,
        phase,
        task,
        billable,
        employee,
        loading,
        noEntries,
        validationState,
        referenceTE,
        durVstate,
        filteredEntries,
        serverTimeEntries,
        currentPage,
        entriesPerPage,
        matterLoading,
        groupEntriesByClient,
        groupEntriesByMatter,
        groupEntriesByTimeKeeper,
        timeEntriesMapForPDF,
        totalHours,
        approvedHours,
        unreviewedHours,
        rejectedHours,
        loadEntry,
        getProjectEntries,
        getDraftEntries,
        setSelectedRole,
        setTimeEntryRange,
        setDelegator,
        setReferenceTE,
        setClient,
        setMatter,
        setPhase,
        setTask,
        setEmployee,
        setBillable,
        setApprovalStatus,
        setSearchText,
        setPageNum,
        setTimeEntryDateRange,
        setEntriesPerPage,
        setFieldLoaderFn,
        setSelectedTimeEntries,
        setExpandedTimeEntries,
        approveEntries,
        rejectEntries,
        revertEntry,
        changeEntry,
        postDraftEntries,
        determineCodeSets,
        openNewTimeEntryDialog,
        getAggregateTotalsFor,
        resetTimeEntryStore,
        currentStart,
        currentEnd
    } = rootStore.managementDashboardStore;
    let fieldSize: 2 | 3 = (matter && matter.id === -1) ? 2 : 3;

    useEffect(() => {
        let loc = DateTime.local().setZone(systemTimeZone);
        let _fromDate = DateTime.utc(loc.year, loc.month, loc.day).minus({
            days: features.EpochConfigTimeEntriesPageDays ? features.EpochConfigTimeEntriesPageDays : 7
        });
        let _untilDate = DateTime.utc(loc.year, loc.month, loc.day);
        setTimeEntryDateRange();
        setTimeEntryRange(_fromDate, _untilDate);
        rootStore.managementDashboardStore.expandedEntryIds = [];
        getEntries();
        return () => {
            resetTimeEntryStore();
        }
    }, []);

    const getEntries = async() => {
        props.draftTime ? await getDraftEntries() : await getProjectEntries();
    }
    const handleSelectRole = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedRole(evt.target.value as Role);
        reset();
    }
    const handleFromDateChange = (d: Date) => {
        const workDate: DateTime = DateTime.local(
            d.getFullYear(),
            d.getMonth() + 1,
            d.getDate(),
            0,
            0,
            0
        );

        let _fromDate = workDate;
        let _untilDate = untilDate;
        if (_untilDate <= workDate) {
            _untilDate = workDate;
        }
        setTimeEntryRange(_fromDate, _untilDate);
    }
    const handleUntilDateChange = (d: Date) => {
        const workDate: DateTime = DateTime.local(
            d.getFullYear(),
            d.getMonth() + 1,
            d.getDate(),
            0,
            0,
            0
        );

        let _fromDate = fromDate;
        let _untilDate = workDate;
        if (_fromDate >= _untilDate) {
            _fromDate = workDate;
        }
        setTimeEntryRange(_fromDate, _untilDate);
    }
    const handleClientChange = (c: Client | null | undefined) => {
        setClient(c);
        handleMatterChange(null);
    }
    const handleMatterChange = (m: Matter | null | undefined) => {
        setMatter(m);
        setReferenceTE(null);
        setPhase(null);
        setTask(null);
    }
    const reset = () => {
        resetTimeEntryStore();
        getEntries();
    }
    const applyFilters = async() => {
        await getEntries();
        rootStore.managementDashboardStore.selectedEntryIds = [];
        setExpandedTimeEntries([]);
        changePage(1);
    }
    const createEntry = () => {
        let loc = DateTime.local().setZone(systemTimeZone);
        openNewTimeEntryDialog(DateTime.local(loc.year, loc.month, loc.day), undefined, undefined, undefined, undefined, true);
    }
    const approveEntry = async (entry: TimeEntry) => {
        await approveEntries([entry.id!]);
    }
    const approveSelectedEntries = async () => {
        await approveEntries(selectedEntryIds, 'selected');
    }
    const handleApproveAll = async () => {
        const offset = (currentPage - 1) * entriesPerPage;
        const limit = offset + entriesPerPage;
        const ids = filteredEntries.slice(offset, limit)
            .filter(ent => ent.approvalStatus === ApprovalStatus.UNREVIEWED)
            .map(e => e.id!);

        await approveEntries(ids, 'all');
    }
    const rejectEntry = async (entry: TimeEntry) => {
        await rejectEntries([entry.id!], true);
    }
    const rejectSelectedEntries = async () => {
        await rejectEntries(selectedEntryIds);
    }
    const postEntry = async (entry: TimeEntry) => {
        await postDraftEntries([entry.id!]);
    }
    const postSelectedEntries = async () => {
        await postDraftEntries(selectedEntryIds);
    }
    const handlePostAll = async () => {
        const offset = (currentPage - 1) * entriesPerPage;
        const limit = offset + entriesPerPage;
        const ids = filteredEntries.slice(offset, limit).map(e => e.id!);

        await postDraftEntries(ids);
    }
    const editEntry = async (entry: TimeEntry) => {
        await loadEntry(entry.clone(), true);
    }
    const clearSelectedEntries = () => {
        setSelectedTimeEntries([]);
    }
    const fetchRefs = async (text: string) => {
        let ret: TimeEntry[] = [];
        const uniqueRet = new Map();
        
        serverTimeEntries.forEach((entry) => {
            uniqueRet.set(entry.reference, entry);
        });
        uniqueRet.forEach((te, k) => {
            if (k) { ret.push(te); }
        });
        return ret;
    }
    const exportToExcel = async () => {
        const currentTimeKeeper = getActiveTimeKeeper();
        const fileName = fileNameForExport(currentTimeKeeper, t('export.pdf.title'));
        const sortByDateEntries = filteredEntries.sort((e1, e2) => {
            if (DateTime.fromISO(e1.workDateTime) > DateTime.fromISO(e2.workDateTime)) { return 1; }
            if (DateTime.fromISO(e1.workDateTime) < DateTime.fromISO(e2.workDateTime)) { return -1; }
            return 0;
        });
        const excelEntries = toExcelFormat(sortByDateEntries, true, t);

        exportToXLSX(excelEntries, fileName, t, true);
    }
    const customizeHeadersForPdf = (): Map<string, ImmutableTimeEntry[]> => {
        let timeEntriesMap = new Map<string, ImmutableTimeEntry[]>();

        switch (pdfFormatTypeValue) {
            case PdfFormatType.CLIENTTYPE:
                timeEntriesMap = groupEntriesByClient;
                break;
            case PdfFormatType.MATTERTYPE:
                timeEntriesMap = groupEntriesByMatter;
                break;
            case PdfFormatType.DATETYPE:
                [...timeEntriesMapForPDF.entries()].sort()
                    .forEach(([date, tentries]) => {
                        let d = DateTime.fromISO(date).toFormat('DDDD');
                        timeEntriesMap.set(d, tentries);
                    });
                break;
            case PdfFormatType.TIMEKEEPER:
                timeEntriesMap = groupEntriesByTimeKeeper;
                break;
            default:
                timeEntriesMap = timeEntriesMapForPDF;
                break;
        }
        
        return timeEntriesMap;
    }
    const printPDF = () => {
        const currentTimeKeeper = getActiveTimeKeeper();
        const fileName = fileNameForExport(currentTimeKeeper, t('export.pdf.title'));

        let timeEntriesMap = customizeHeadersForPdf();

        let html = renderToStaticMarkup(
            <PrintAsTimeEntryPdfExport
                timeEntriesMap={timeEntriesMap}
                exportType={pdfFormatTypeValue}
                timeKeeper={currentTimeKeeper ? currentTimeKeeper.name : ''}
                fromDate={currentStart}
                toDate={currentEnd}
                isMgmtDashboard={true}
            />);
        
        let pdfDiv = new DOMParser().parseFromString(html, 'text/html');
        let pdfContainer = document.createElement('div');
        
        if (pdfDiv.body.firstChild) {
            pdfContainer.appendChild(pdfDiv.body.firstChild);
            pdfContainer.setAttribute('style', 'visibility: hidden')
            document.body.appendChild(pdfContainer);
            printJS({
                printable: 'printjs-form',
                type: 'html',
                documentTitle: t('export.pdf.title'),
                style: STYLES_FOR_PDF,
                onLoadingStart: () => { document.title = fileName; },
                onPrintDialogClose: () => { document.title = 'Epoch'; },
                honorColor: true,
                honorMarginPadding: true
            });
            
            document.body.removeChild(pdfContainer);
        }
        
        handleClosePdfPopOver();
    }
    const saveAsPDF = async () => {
        const currentTimeKeeper = getActiveTimeKeeper();
        const timeEntriesMap = customizeHeadersForPdf();
        const fileName = fileNameForExport(currentTimeKeeper, t('export.pdf.title'));

        let doc = await buildPdf(
            timeEntriesMap,
            pdfFormatTypeValue,
            currentTimeKeeper ? currentTimeKeeper.name : '',
            currentStart,
            currentEnd,
            true,
            t
        );
        
        if (Platform.isElectron()) {
            let pdfString = doc.output('datauristring');
            const {dialog} = require('electron').remote;
            const fs = require('fs-jetpack');
            let dataUriToBuffer = require('data-uri-to-buffer');
            const path = require('path');
            const bufferData = dataUriToBuffer(pdfString);

            dialog.showSaveDialog({
                    defaultPath: fileName,
                    filters: [{
                            name: 'pdf',
                            extensions: ['pdf']
                    }]
                }, (file: string) => {
                    if (file === undefined) {
                        return;
                    }
                    fs.write(
                        path.normalize(file),
                        bufferData
                    );
                }
            );
        } else {
            doc.save(`${fileName}.pdf`);
        }
        
        handleClosePdfPopOver();
    }
    const openPdfPopOver = async (evt: React.MouseEvent<HTMLElement>) => {
        setPopOverPdfEl(evt.currentTarget);
    }
    const handleClosePdfPopOver = () => {
        setPopOverPdfEl(null);
    }
    const handlePdfFormatTypeChange = (event: React.ChangeEvent<HTMLFormElement>, val: string) => {
        setPdfFormatTypeValue(val as PdfFormatType);
    }
    const changePage = (pageNum: number) => {
        setPageNum(pageNum);
        entriesListRef.current!.scrollTop = 0;
    }
    const changeEntriesPerPage = (n: number) => {
        setEntriesPerPage(n);
        changePage(1);
    }
    const fetchMatters = async (text: string, clientId: number | undefined) => {
        let  results: Matter[] = await rootStore.api.Matter.searchMatters(text, undefined, clientId!,
            undefined, undefined, undefined, selectedRole, approvalTimekeeperId);

        if (!features.EpochConfigTrackedMatterClientsEnabled && results.length === 0) {
            results = await rootStore.api.Matter.getAvailableMatters(text, false, clientId!);
        }
        return results;
    }

    return (
        <>
            <AppBar position={'static'}>
                <Toolbar variant={'regular'}>
                    <Grid container={true} xs={12}>
                        <Styled.StyledGrid
                            container={true}
                            xs={12}
                            spacing={8}
                        >
                            <Grid item={true} xs={3} lg={fieldSize} md={fieldSize}>
                                <FormControl fullWidth={true}>
                                    <InputLabel shrink={true}>{t('header.field.role.label')}</InputLabel>
                                    <Select
                                        input={<Input name={'role'} id={'role-label-placeholder'}/>}
                                        displayEmpty={true}
                                        name={'select-role'}
                                        value={selectedRole}
                                        onChange={handleSelectRole}
                                    >
                                        {(roles || []).map(role => <MenuItem key={role} value={role}>{approvalStages[role]}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={2}>
                                <InlineDatePicker
                                    format={fromDate.toFormat(getDateFormat())}
                                    value={isoDate(fromDate)}
                                    label={t('header.field.date.from.label')}
                                    onChange={handleFromDateChange}
                                    leftArrowIcon={<KeyboardArrowLeft/>}
                                    rightArrowIcon={<KeyboardArrowRight/>}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={'end'}>
                                                <Today/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    TextFieldComponent={
                                        (_props: StandardTextFieldProps) =>
                                            <TextField {..._props} fullWidth={true}/>
                                    }
                                />
                            </Grid>
                            <Grid item={true} xs={2}>
                                <InlineDatePicker
                                    format={untilDate.toFormat(getDateFormat())}
                                    value={isoDate(untilDate)}
                                    label={t('header.field.date.to.label')}
                                    onChange={handleUntilDateChange}
                                    leftArrowIcon={<KeyboardArrowLeft/>}
                                    rightArrowIcon={<KeyboardArrowRight/>}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={'end'}>
                                                <Today/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    TextFieldComponent={
                                        (_props: StandardTextFieldProps) =>
                                            <TextField {..._props} fullWidth={true}/>
                                    }
                                />
                            </Grid>
                            <Grid item={true} xs={2}>
                                <FlexDiv style={{marginTop: 7}}>
                                    <IconButton title={t('header.action.reset.tooltip')} onClick={reset}>
                                        <Loop/>
                                    </IconButton>
                                    <IconButton title={t('header.action.apply_filter.tooltip')} onClick={applyFilters}>
                                        <Done/>
                                    </IconButton>
                                </FlexDiv>
                            </Grid>
                            <Grid item={true} xs={3} lg={fieldSize} md={fieldSize}>
                                <AutoCompleteField
                                    label={t('header.field.delegator.label')}
                                    fetch={() => rootStore.api.ManagementDashboard.getDelegators(selectedRole)}
                                    currentItem={delegator}
                                    getItemText={(tk: Delegator) => `${tk.sourceTkId} - ${tk.sourceTkName}`}
                                    clearable={true}
                                    onSelect={setDelegator}
                                    onClear={() => setDelegator(null)}
                                />
                            </Grid>
                        </Styled.StyledGrid>
                        <Styled.StyledGrid
                            container={true}
                            xs={12}
                            spacing={8}
                        >
                            <Grid item={true} xs={3}>
                                <AutoCompleteField
                                    label={t('header.field.client.label')}
                                    fetch={rootStore.api.Client.searchClients}
                                    currentItem={client}
                                    getItemText={(c: Client) => `${c.number} - ${c.name}`}
                                    clearable={true}
                                    onSelect={handleClientChange}
                                    onClear={() => handleClientChange(null)}
                                />
                            </Grid>
                            <Grid item={true} xs={3}>
                                <AutoCompleteField
                                    label={t('header.field.matter.label', {matterLabel})}
                                    fetch={(text: string) => fetchMatters(text, client ? client.id : undefined)
                                        .then((res) => {
                                            let blank: Matter = {
                                                id: -1,
                                                number: '',
                                                name: 'Blank',
                                                description: '',
                                                clientId: -1,
                                                isPhaseCode: false,
                                                isActCode: false,
                                                isFfTaskCode: false,
                                                clientName: '',
                                                clientNumber: '',
                                                status: '',
                                                language: '',
                                                languageText: '',
                                                timeEntryUnit: '',
                                                entryType: '',
                                                type: '',
                                                typeText: MatterTypeText.NON_BILLABLE,
                                                statusDescription: '',
                                                lastModified: '',
                                                startDate: '',
                                                endDate: '',
                                                bannedWords: [],
                                                blockBillingWords: []
                                            };
                                            if (!client && !text) {
                                                res.unshift(blank)
                                            }
                                            return res;
                                        })}
                                    currentItem={matter}
                                    getItemText={(m: Matter) => (m.id === -1) ? m.name : `${m.number} - ${m.name}`}
                                    clearable={true}
                                    onSelect={handleMatterChange}
                                    onClear={() => handleMatterChange(null)}
                                    tooltip={(m: Matter) => m.description}
                                />
                            </Grid>
                            {matter && matter.id === -1 &&
                                <Grid item={true} xs={2} lg={2} md={2}>
                                    <AutoCompleteField
                                        label={t('header.field.reference.label')}
                                        fetch={fetchRefs}
                                        currentItem={referenceTE}
                                        getItemText={(entry: TimeEntry) => entry.reference!}
                                        clearable={true}
                                        onSelect={setReferenceTE}
                                        onClear={() => setReferenceTE(null)}
                                    />
                                </Grid>
                            }
                            <Grid item={true} xs={3} lg={fieldSize} md={fieldSize}>
                                <AutoCompleteField
                                    label={t('header.field.phase.label')}
                                    fetch={(text) => matter ? rootStore.api.Code.getPhaseCodes(matter!.id, undefined, text) : Promise.resolve()}
                                    currentItem={phase}
                                    getItemText={(c: Code) => parseCode(c.name, c.description)}
                                    clearable={true}
                                    onSelect={setPhase}
                                    onClear={() => setPhase(null)}
                                />
                            </Grid>
                            <Grid item={true} xs={3} lg={fieldSize} md={fieldSize}>
                                <AutoCompleteField
                                    label={t('header.field.task.label')}
                                    fetch={(text) => phase ? rootStore.api.Code.getTaskCodes(phase!.id, undefined, text) : Promise.resolve()}
                                    currentItem={task}
                                    getItemText={(c: Code) => parseCode(c.name, c.description)}
                                    clearable={true}
                                    onSelect={setTask}
                                    onClear={() => setTask(null)}
                                />
                            </Grid>
                        </Styled.StyledGrid>
                        <Styled.StyledGrid
                            container={true}
                            xs={12}
                            spacing={8}
                        >
                            <Grid item={true} xs={3} lg={fieldSize} md={fieldSize}>
                                <AutoCompleteField
                                    label={t('header.field.timekeeper.label')}
                                    fetch={(searchTxt) => rootStore.api.ManagementDashboard.getEmployees(selectedRole, approvalTimekeeperId, searchTxt)}
                                    currentItem={employee}
                                    getItemText={(tk: Employee) => `${tk.timeKeeperId} - ${tk.name}`}
                                    clearable={true}
                                    onSelect={setEmployee}
                                    onClear={() => setEmployee(null)}
                                />
                            </Grid>
                            <Grid item={true} xs={2}>
                                <FormControl fullWidth={true}>
                                    <InputLabel shrink={true}>{t('header.field.billable.label', {matterLabel})}</InputLabel>
                                    <Select
                                        input={<Input name={'billable'} id={'billable-label-placeholder'}/>}
                                        displayEmpty={true}
                                        name={'select-billable'}
                                        value={billable}
                                        onChange={(e) => setBillable(e.target.value ? (e.target.value === 'true') : undefined)}
                                    >
                                        <MenuItem value={undefined}>{t('header.field.billable.option.all')}</MenuItem>
                                        <MenuItem value={'true'}>{t('header.field.billable.option.yes')}</MenuItem>
                                        <MenuItem value={'false'}>{t('header.field.billable.option.no')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {!props.draftTime &&
                                <Grid item={true} xs={3}>
                                    <FormControl fullWidth={true}>
                                        <InputLabel>{t('header.field.approval_status.label')}</InputLabel>
                                        <Select
                                            input={<Input name={'approval-status'} id={'approval-status-label-placeholder'}/>}
                                            displayEmpty={true}
                                            name={'select-approval-status'}
                                            value={approvalStatus}
                                            multiple={true}
                                            onChange={(e) => setApprovalStatus(e.target.value)}
                                            renderValue={(selected: string[]) => selected.join(', ')}
                                        >
                                            <MenuItem value={ApprovalStatus.APPROVED}>
                                                <Checkbox checked={approvalStatus.indexOf(ApprovalStatus.APPROVED) > -1}/>
                                                <ListItemText primary={t('header.field.approval_status.option.approved')}/>
                                            </MenuItem>
                                            <MenuItem value={ApprovalStatus.UNREVIEWED}>
                                                <Checkbox checked={approvalStatus.indexOf(ApprovalStatus.UNREVIEWED) > -1}/>
                                                <ListItemText primary={t('header.field.approval_status.option.unreviewed')}/>
                                            </MenuItem>
                                            <MenuItem value={ApprovalStatus.REJECTED}>
                                                <Checkbox checked={approvalStatus.indexOf(ApprovalStatus.REJECTED) > -1}/>
                                                <ListItemText primary={t('header.field.approval_status.option.rejected')}/>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item={true} xs={props.draftTime ? 6 : 4}>
                                <TextField
                                    id={'input-with-icon-textfield'}
                                    label={t('header.field.search.label')}
                                    placeholder={t('header.field.search.place_holder')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position={'start'}>
                                                <Search/>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={searchText}
                                    fullWidth={true}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </Grid>
                        </Styled.StyledGrid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <FlexDiv style={{margin: 5, minHeight: '48px', backgroundColor: 'white'}}>
                {!props.draftTime &&
                    <>
                        <IconButton
                            aria-label={t('action_bar.add.tooltip')}
                            title={t('action_bar.add.tooltip')}
                            onClick={createEntry}
                        >
                            <Add/>
                        </IconButton>
                        <IconButton
                            aria-label={t('action_bar.approve_selected.tooltip')}
                            title={t('action_bar.approve_selected.tooltip')}
                            onClick={approveSelectedEntries}
                            disabled={selectedEntryIds.length < 1}
                        >
                            <PlaylistAddCheck/>
                        </IconButton>
                        <IconButton
                            aria-label={t('action_bar.approve_all.tooltip')}
                            title={t('action_bar.approve_all.tooltip')}
                            onClick={handleApproveAll}
                        >
                            <DoneAll/>
                        </IconButton>
                    </>
                }
                {props.draftTime &&
                    <IconButton
                        aria-label={t('action_bar.post_all.tooltip')}
                        title={t('action_bar.post_all.tooltip')}
                        onClick={handlePostAll}
                    >
                        <DoneAll/>
                    </IconButton>
                }
                <IconButton
                    key={'csv'}
                    onClick={exportToExcel}
                    title={t('action_bar.download_excel.tooltip')}
                    disabled={noEntries}
                >
                    <img src={ExcelIcon}/>
                </IconButton>
                <IconButton
                    key={'pdf'}
                    onClick={openPdfPopOver}
                    title={t('action_bar.pdf.tooltip')}
                    disabled={noEntries}
                >
                    <PictureAsPdf/>
                </IconButton>
                <Popover
                    id="download-pdf-pop"
                    open={openedPdfEl}
                    anchorEl={popOverPdfEl}
                    onClose={handleClosePdfPopOver}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div>
                        <FormControl style={{width: '140px', padding: '10px'}}>
                            <FormLabel>{t('action_bar.pdf.menu.group_by.label')}:</FormLabel>
                            <RadioGroup
                                aria-label={t('action_bar.pdf.menu.group_by.label')}
                                name={'groupBy'}
                                value={pdfFormatTypeValue}
                                onChange={handlePdfFormatTypeChange}
                            >
                                {Object.keys(PdfFormatType).map((key) => {
                                    const typeKey = PdfFormatType[key].toLowerCase().replace(' ', '_');
                                    const transKey = `action_bar.pdf.menu.group_by.option.${typeKey}`;
                                    return (
                                        <FormControlLabel
                                            key={key}
                                            control={
                                                <Radio/>
                                            }
                                            label={t(transKey, {matterLabel})}
                                            value={PdfFormatType[key]}
                                        />
                                    )
                                })}
                            </RadioGroup>
                            <Styled.PrintIcons>
                                <Tooltip title={t('action_bar.pdf.menu.action.print.tooltip')}>
                                    <IconButton
                                        onClick={printPDF}
                                        aria-label={t('action_bar.pdf.menu.action.print.tooltip')}
                                    >
                                        <Print/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t('action_bar.pdf.menu.action.download.tooltip')}>
                                    <IconButton
                                        onClick={saveAsPDF}
                                        aria-label={t('action_bar.pdf.menu.action.download.tooltip')}
                                    >
                                        <GetApp/>
                                    </IconButton>
                                </Tooltip>
                            </Styled.PrintIcons>
                        </FormControl>
                    </div>
                </Popover>
                <Styled.TimeContainer>
                    {!props.draftTime &&
                        <>
                            <Styled.TimeItem>{`${t(`action_bar.${'total_hours'}`)} ${totalHours / 3600}`}</Styled.TimeItem>
                            <Styled.TimeItem>{`${t('action_bar.approved_hours')} ${approvedHours / 3600}`}</Styled.TimeItem>
                            <Styled.TimeItem>{`${t('action_bar.unreviewed_hours')} ${unreviewedHours / 3600}`}</Styled.TimeItem>
                            <Styled.TimeItem>{`${t('action_bar.rejected_hours')} ${rejectedHours / 3600}`}</Styled.TimeItem>
                        </>
                    }
                </Styled.TimeContainer>
                <Pagination
                    entriesPerPage={entriesPerPage}
                    currentPage={currentPage}
                    entriesLength={filteredEntries.length}
                    changeEntriesPerPage={changeEntriesPerPage}
                    onPageChange={changePage}
                />
            </FlexDiv>

            <Styled.TimeEntryList innerRef={entriesListRef}>
                {[...rootStore.managementDashboardStore.timeEntriesMap].map(([date, entries]) => (
                    <TimeEntryList
                        entries={entries}
                        validationMap={validationState}
                        durValidationMap={durVstate}
                        date={DateTime.fromISO(date)}
                        onChange={changeEntry}
                        selected={selectedEntryIds}
                        onSelect={setSelectedTimeEntries}
                        expanded={expandedEntryIds}
                        onExpand={setExpandedTimeEntries}
                        onPost={postEntry}
                        onEdit={editEntry}
                        onCancel={revertEntry}
                        onApprove={approveEntry}
                        onReject={rejectEntry}
                        buildCodeSets={determineCodeSets}
                        aggregateTotals={getAggregateTotalsFor}
                        onSetFieldLoader={setFieldLoaderFn}
                        isMgmtDashboard={true}
                    />
                ))}
                {loading &&
                    <Styled.Loading>
                        <div style={{top: '-15%', position: 'relative'}}>
                            <CircularProgress size={100}/>
                        </div>
                    </Styled.Loading>
                }
                {noEntries && <Styled.NoEntry>{t('list.empty')}</Styled.NoEntry>}
            </Styled.TimeEntryList>
            {props.draftTime ?
                <FabContainerView
                    dirty={dirty}
                    clearSelectedEntries={clearSelectedEntries}
                    postSelectedEntries={postSelectedEntries}
                    selectedEntryIds={selectedEntryIds}
                    disableSavePost={matterLoading}
                />
            :
                <FabContainerView
                    dirty={dirty}
                    clearSelectedEntries={clearSelectedEntries}
                    approveSelectedEntries={approveSelectedEntries}
                    rejectSelectedEntries={rejectSelectedEntries}
                    selectedEntryIds={selectedEntryIds}
                />
            }
        </>
    );
}

export default observer(ManagementDashboard);
