import * as React from 'react';
import {
    Dialog,
    DialogContent,
    TextField,
    DialogTitle,
    Button,
    DialogActions,
    FormControlLabel,
    Checkbox,
    FormHelperText
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TimeEntryDialogStore from 'store/timeentry.dialog.store';
import TimeEntryForm from 'components/TimeEntryForm/TimeEntryForm';
import { FlexDiv } from 'common/flex';
import { InlineDatePicker } from 'material-ui-pickers';
import { KeyboardArrowLeft, KeyboardArrowRight, GroupWorkOutlined } from '@material-ui/icons';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from '../../api/interfaces/RootAPI';
import Template from 'api/immutables/ImmutableTemplate';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { Features, TimeEntryType, TimeKeeperAssignment, CollaborateType, ApprovalStatus } from '../../api/types/types';
import { DateTime } from 'luxon';
import { isoDate, getDateFormat } from '../../util/date';
import * as Styled from './styled';
import TimeKeepersList from 'components/TimeKeepersList/TimeKeepersList';
import { LinearProgressBar } from 'components/LoadingSpinner/LinearProgressBar';
import { TKConsumer } from 'common/TKProvider';
import { getTemplateTooltipText } from 'util/template';
import { withTranslation } from 'react-i18next';
import { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { getSelectedTks, setCollaborateInfo } from 'util/timeEntry';

interface Props {
    dialogStore?: TimeEntryDialogStore;
    // tslint:disable-next-line:no-any
    t: any;
}
interface State {
    setFieldLoader: boolean;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        dialogStore: rootStore.timeEntryDialogStore
    };
})
@observer
class TimeEntryDialog extends React.Component<Props, State> {
    state = {
        setFieldLoader: false
    }
    changeReference = (event: React.ChangeEvent<HTMLInputElement>) => {
        let entry = this.props.dialogStore!.entry.setReference(event.target.value);
        this.props.dialogStore!.changeEntry(entry);
    }
    
    setTemplateName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.dialogStore!.setTemplateName(event.target.value);
    }
    
    cancel = () => {
        this.props.dialogStore!.clear();
        this.props.dialogStore!.cancel();
        this.props.dialogStore!.createAnotherFlag = false;
    }
    setWorkDate = (date: Date) => {
        const workDate: DateTime = DateTime.local(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            0,
            0,
            0
        );
        this.props.dialogStore!.setWorkDate(workDate);
    }
    setTemplate = async (template: Template | undefined) => {
        const { setTemplate, rootStore } = this.props.dialogStore!;
        this.setState({ setFieldLoader: true });
        rootStore.timeEntryStore.resetNarrativePreviousPopulatedCodes();
        await setTemplate(template);
        this.setState({ setFieldLoader: false });
    }
    disableCollaborateBtn = (writable: boolean) => {
        const { entry, rootStore } = this.props.dialogStore!;
        return rootStore.appStore.online && ((entry && entry.timeEntryType !== TimeEntryType.COLLABORATE && writable
            && !((entry.collaborateTks || entry.collaborateInfo) && entry.id) && entry.matterId)
            || ((entry.collaborateTks || entry.collaborateInfo) &&
            rootStore.timeEntryStore.collaborateEntriesIds.includes(entry.id!)));
    }

    getAuditLabel = () => {
        const { auditLog, auditLogIndex } = this.props.dialogStore!;
        let label = (auditLogIndex === auditLog.length - 1) ? 'Current'
            : (auditLogIndex === 0) ? 'Original' : `Audit - ${auditLogIndex}`;
        return label;
    }

    render() {
        const { t, dialogStore } = this.props;
        const { 
            isOpen, 
            entry, 
            templateName,
            createAnotherFlag,
            selectedTemplate,
            changeEntry, 
            saving,
            wrappedPost,
            wrappedUnpost,
            wrappedSave,
            wrappedApprove,
            wrappedReject,
            durationValidationState,
            toggleCreateAnotherFlag,
            validationState,
            templateValidationState,
            disableCreateAnother,
            setFieldLoaderFn,
            auditLog,
            auditLogIndex,
            setAuditLogEntry,
            isMgmtDashboard,
            rootStore
        } = dialogStore!;
        
        let workDateErrText: string = '';
        if (validationState && validationState.invalidWorkDate) {
            workDateErrText = t('validation.date.invalid');
        }
        const fetchTemplates = rootStore.templateStore.fetchTemplatesInAutoComplate;
        const { getAllTimeKeepers, filteredAllTimekeepersList, setTkSearchText } = rootStore.appStore;
        // Fetching the Offices to show in the office list
        let tkOfficesToShow = rootStore.appStore.getActiveTKOfficesForDate(DateTime.fromISO(entry && entry.workDateTime));
        const { setFieldLoader } = this.state;

        let collaborators: string;
        let numberOfCollaborators: number = 0;
        // get author/collaborators name(s) from entry collaborateInfo and convert to a string
        if (entry && entry.collaborateInfo) {
            const collaborateInfo = JSON.parse(entry.collaborateInfo);
            if (entry.timeEntryType === TimeEntryType.COLLABORATE) {
                collaborators = collaborateInfo.author.tkName;
            } else {
                collaborators = collaborateInfo.collaborators.map((tk: CollaborateType) => tk.tkName).join('\n');
                numberOfCollaborators = collaborateInfo.collaborators.length;
            }
        } else if (entry && entry.collaborateTks) {
            const collaborateTks = entry.collaborateTks.split(',');
            if (entry.timeEntryType !== TimeEntryType.COLLABORATE) {
                collaborators = collaborateTks.join('\n');
                numberOfCollaborators = collaborateTks.length;
            }
        }

        const isRejectedOrApproved = entry && (entry.approvalStatus === ApprovalStatus.REJECTED || entry.approvalStatus === ApprovalStatus.APPROVED);

        return (
            <FeaturesConsumer>
                {(features: Features) =>
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
                    <>
                        <Dialog
                            PaperProps={{ style: { overflow: 'visible', maxWidth: '1024px' } }}
                            disableBackdropClick={true}
                            disableEscapeKeyDown={true}
                            open={isOpen}
                            onClose={this.cancel}
                            fullWidth={true}
                            maxWidth={false}
                            disableRestoreFocus={true}
                        >
                            <DialogTitle
                                id="timeentry-dialog-title"
                                style={{ paddingBottom: 0 }}
                            >
                                {t('dialog.new_edit_copy.title')} {(entry && entry.timeEntryType === TimeEntryType.COLLABORATE &&
                                    (entry.collaborateTks || entry.collaborateInfo)) &&
                                    <>
                                        <span
                                            style={{ float: 'right', fontSize: '15px' }}
                                        >
                                            {entry.collaborateTks || collaborators}
                                        </span>
                                        <span style={{ float: 'right' }}><GroupWorkOutlined /></span>
                                    </>
                                }
                                {auditLog.length > 0 &&
                                    <Styled.NavAuditLogContainer>
                                        <Styled.AuditLabel>{this.getAuditLabel()}</Styled.AuditLabel>
                                        <Styled.StyledIconButton
                                            onClick={() => setAuditLogEntry(auditLogIndex - 1)}
                                            aria-label={t('dialog.new_edit_copy.action.navigate.previous')}
                                            title={t('dialog.new_edit_copy.action.navigate.previous')}
                                            disabled={auditLogIndex === 0}
                                        >
                                            <KeyboardArrowLeft/>
                                        </Styled.StyledIconButton>
                                        <Styled.StyledIconButton
                                            onClick={() => setAuditLogEntry(auditLogIndex + 1)}
                                            aria-label={t('dialog.new_edit_copy.action.navigate.next')}
                                            title={t('dialog.new_edit_copy.action.navigate.next')}
                                            disabled={auditLogIndex === auditLog.length - 1}
                                        >
                                            <KeyboardArrowRight/>
                                        </Styled.StyledIconButton>
                                    </Styled.NavAuditLogContainer>
                                }
                            </DialogTitle>
                            <DialogContent style={{ overflow: 'visible', paddingBottom: 0 }}>
                                <FlexDiv direction="row" style={{ alignItems: 'flex-end' }}>
                                    <InlineDatePicker
                                        format={entry ? DateTime.fromISO(entry.workDateTime).toFormat(getDateFormat())
                                            : 'MM/dd/yyyy'}
                                        onlyCalendar={true}
                                        value={entry ? isoDate(DateTime.fromISO(entry.workDateTime)) : ''}
                                        disabled={entry && (entry.isPosted() || !!entry.id) || !tk.writable}
                                        onChange={this.setWorkDate}
                                        leftArrowIcon={<KeyboardArrowLeft/>}
                                        rightArrowIcon={<KeyboardArrowRight/>}
                                    />
                                    <FlexDiv flex={1}/>
                                    {entry && !entry.isPosted() && !isMgmtDashboard &&
                                        <ApiConsumer>
                                            {(api: RootAPI) =>
                                                <Styled.TemplateField>
                                                    <AutoCompleteField
                                                        label={t('field.template.title')}
                                                        fetch={(search: string) => fetchTemplates(search, entry)}
                                                        currentItem={selectedTemplate ? selectedTemplate : null}
                                                        clearable={true}
                                                        disabled={false || !tk.writable}
                                                        onClear={() => this.setTemplate(undefined)}
                                                        getItemText={(m: Template) => `${m.name}`}
                                                        onSelect={this.setTemplate}
                                                        tooltip={(m: Template) => getTemplateTooltipText(m, t)}
                                                    />
                                                    {setFieldLoader &&
                                                    <LinearProgressBar color={'primary'} progressBar={50}/>
                                                    }
                                                </Styled.TemplateField>}

                                        </ApiConsumer>
                                    }
                                </FlexDiv>
                                {workDateErrText &&
                                    <FormHelperText error={true}>
                                        {workDateErrText}
                                    </FormHelperText>
                                }
                                <TimeEntryForm
                                    validationState={validationState}
                                    durValidationState={durationValidationState}
                                    minHeight={144}
                                    timeEntry={entry}
                                    onChange={changeEntry}
                                    onSetFieldLoader={setFieldLoaderFn}
                                    actionCodesRequired={features.EpochConfigActionCodesRequired}
                                    minNarrativeLength={features.EpochConfigNarrativesMinimumChars}
                                    maxNarrativeLength={features.EpochConfigNarrativesMaximumChars}
                                    tkOfficesToShow={tkOfficesToShow!}
                                    isMgmtDashboard={isMgmtDashboard}
                                />
                            </DialogContent>
                            <DialogActions>
                                {entry && !entry.isPosted() && !isMgmtDashboard &&
                                    <div style={{ paddingLeft: 12 }}>
                                        {entry && !entry.matterId &&
                                            <TextField
                                                label={t('field.reference')}
                                                error={validationState && validationState.isReferenceEmpty}
                                                helperText={validationState && validationState.isReferenceEmpty ? t('validation.reference.invalid') : ''}
                                                value={entry && entry.reference ? entry.reference : ''}
                                                onChange={this.changeReference}
                                                disabled={!tk.writable}
                                            />
                                        }
                                        {entry && entry.matterId &&
                                            <TextField
                                                label={t('field.save_as_template')}
                                                inputProps={{ maxLength: 100 }}
                                                value={templateName}
                                                error={templateValidationState && !templateValidationState.valid}
                                                helperText={
                                                    (templateValidationState && !templateValidationState.valid) ?
                                                        t(`validation.template.${templateValidationState.duplicateName ? 'duplicate' : 'start_with'}`)
                                                    : ''
                                                }
                                                onChange={this.setTemplateName}
                                                disabled={!tk.writable}
                                            />
                                        }
                                    </div>
                                }

                                <FlexDiv flex={1}/>
                                {entry && !entry.isPosted() &&
                                    <>
                                        {!isMgmtDashboard &&
                                            <>
                                                {!entry.id &&
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={createAnotherFlag}
                                                            onChange={toggleCreateAnotherFlag}
                                                            disabled={disableCreateAnother || !tk.writable}
                                                        />
                                                    }
                                                    label={t('dialog.new_edit_copy.field.checkbox.create_another')}
                                                />
                                                }

                                                <TimeKeepersList
                                                    render={(tkMenuAnchor, openTkList) =>
                                                        <Styled.CollaborateBtn
                                                            innerRef={tkMenuAnchor}
                                                            title={entry.timeEntryType !== TimeEntryType.COLLABORATE ? collaborators : ''}
                                                        >
                                                            <Button
                                                                onClick={(evt) => {
                                                                    setTkSearchText('')
                                                                    getAllTimeKeepers(entry.workDateTime, entry.matterId)
                                                                        .then(() => openTkList(evt))
                                                                }}
                                                                aria-label={t('action.collaborate')}
                                                                disabled={!this.disableCollaborateBtn(tk.writable)}
                                                            >
                                                                {t('action.collaborate')} ({numberOfCollaborators})
                                                            </Button>
                                                        </Styled.CollaborateBtn>
                                                    }
                                                    collaborate={true}
                                                    selectedTimeKeepers={getSelectedTks(entry)}
                                                    saveCollaboratees={(tks: CollaborateType[]) => setCollaborateInfo(tks, entry, changeEntry)}
                                                    menuWidth={300}
                                                    timeKeepers={filteredAllTimekeepersList}
                                                    workDate={entry.workDateTime}
                                                    matterId={entry.matterId}
                                                />

                                            </>
                                        }
                                        {!(isMgmtDashboard && !entry.id) &&
                                            <>
                                                <Button disabled={saving || !tk.writable} onClick={wrappedSave}>{t('save', { ns: 'common' })}</Button>
                                                <Button disabled={saving || !tk.writable} onClick={wrappedPost}>{t('action.post')}</Button>
                                            </>
                                        }
                                    </>
                                }
                                {rootStore.appStore.isProject && !isMgmtDashboard &&
                                    (entry && entry.sapStatus === SapStatus.QUEUED && entry.approvalStatus === ApprovalStatus.UNREVIEWED) &&
                                    <Button disabled={saving || !tk.writable} onClick={wrappedUnpost}>{t('action.unpost')}</Button>
                                }
                                {isMgmtDashboard && !isRejectedOrApproved &&
                                    <>
                                        <Button disabled={saving || !tk.writable} onClick={wrappedApprove}>
                                            {t('action.approve', { ns: 'management_dashboard' })}
                                        </Button>
                                        {entry.isPosted() &&
                                            <Button disabled={saving || !tk.writable} onClick={wrappedReject}>
                                                {t('action.reject', { ns: 'management_dashboard' })}
                                            </Button>
                                        }
                                    </>
                                }
                                <Button onClick={this.cancel}>{t('cancel', { ns: 'common'})}</Button>
                            </DialogActions>

                        </Dialog>
                    </>
                }
            </TKConsumer>}
            </FeaturesConsumer>
        );
    }
}

export default withTranslation(['timeentries', 'common', 'management_dashboard'])(TimeEntryDialog);