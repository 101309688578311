import { Button } from '@material-ui/core';
import { CallMerge, Check, Delete, DoneAll, NotInterested, Save, Send, SwapHoriz, Undo } from '@material-ui/icons';
import * as Styled from '../../containers/Home/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    dirty?: boolean;
    saveDirtyEntries?: () => void;
    clearSelectedEntries: () => void;
    postSelectedEntries?: () => void;
    moveSelectedEntries?: () => void;
    mergeEntries?: () => void;
    transferEntries?: () => void;
    deleteSelectedEntries?: () => void;
    approveSelectedEntries?: () => void;
    rejectSelectedEntries?: () => void;
    selectedEntryIds: number[];
    disableSavePost?: boolean;
}

export const FabContainerView = (props: Props) => {
    const { t } = useTranslation(['timeentries', 'common']);
    const {
        dirty,
        saveDirtyEntries,
        clearSelectedEntries,
        postSelectedEntries,
        moveSelectedEntries,
        mergeEntries,
        transferEntries,
        deleteSelectedEntries,
        approveSelectedEntries,
        rejectSelectedEntries,
        selectedEntryIds,
        disableSavePost
    } = props;

    return (
        <Styled.FabContainer onScreen={selectedEntryIds.length > 0 || !!dirty}>
            {dirty && saveDirtyEntries &&
                <Button
                    onClick={saveDirtyEntries}
                    variant="extendedFab"
                    aria-label={t('save', { ns: 'common' })}
                    disabled={disableSavePost}
                >
                    <Save/>
                    {t('fab_container.action.save')}
                </Button>
            }
            {(selectedEntryIds.length > 0) && <>
                <Button
                    onClick={clearSelectedEntries}
                    variant="extendedFab"
                    color="secondary"
                    aria-label={t('clear', { ns: 'common' })}
                >
                    <Undo/>
                    {t('clear', { ns: 'common' })} ({selectedEntryIds.length})
                </Button>
                {postSelectedEntries &&
                    <Button
                        onClick={postSelectedEntries}
                        variant="extendedFab"
                        aria-label={t('action.post')}
                        disabled={disableSavePost}
                    >
                        <Check/>
                        {t('action.post')} ({selectedEntryIds.length})
                    </Button>
                }
                {moveSelectedEntries &&
                    <Button
                        onClick={moveSelectedEntries}
                        variant="extendedFab"
                        aria-label={t('fab_container.action.move')}
                    >
                        <Send/>
                        {t('fab_container.action.move')} ({selectedEntryIds.length})
                    </Button>
                }
                {selectedEntryIds.length > 1 && mergeEntries &&
                    <Button
                        onClick={mergeEntries}
                        variant="extendedFab"
                        aria-label={t('action.merge')}
                    >
                        <CallMerge/>
                        {t('action.merge')} ({selectedEntryIds.length})
                    </Button>
                }
                {selectedEntryIds.length > 1 && transferEntries &&
                    <Button
                        onClick={transferEntries}
                        variant="extendedFab"
                        aria-label={t('action.transfer')}
                    >
                        <SwapHoriz/>
                        {t('action.transfer')} ({selectedEntryIds.length})
                    </Button>
                }
                {deleteSelectedEntries &&
                    <Button
                        onClick={deleteSelectedEntries}
                        variant="extendedFab"
                        aria-label={t('delete', { ns: 'common' })}
                    >
                        <Delete/>
                        {t('delete', { ns: 'common' })} ({selectedEntryIds.length})
                    </Button>
                }
                {approveSelectedEntries &&
                    <Button
                        onClick={approveSelectedEntries}
                        variant="extendedFab"
                        aria-label={t('action.approve', { ns: 'management_dashboard' })}
                    >
                        <DoneAll/>
                        {t('action.approve', { ns: 'management_dashboard' })} ({selectedEntryIds.length})
                    </Button>
                }
                {rejectSelectedEntries &&
                    <Button
                        onClick={rejectSelectedEntries}
                        variant="extendedFab"
                        aria-label={t('action.reject', { ns: 'management_dashboard' })}
                    >
                        <NotInterested/>
                        {t('action.reject', { ns: 'management_dashboard' })} ({selectedEntryIds.length})
                    </Button>
                }
            </>}
        </Styled.FabContainer>
    )
}