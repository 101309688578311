import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const StyledGrid = styled(Grid)<{disabled: boolean}>`
    padding: 5px 5px 10px 5px !important;
    ${({ disabled }) => disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`;

export const TimeEntryList = styled.div`
    position: relative;
    height: 100%;
    overflow-y: auto;
    padding: 5px 5px 100px;
`;

export const NoEntry = styled.div`
    margin-top: 100px;
    color: grey;
    font-size: 30px;
    text-align: center;
`;

export const Loading = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:99;
`;

export const PrintIcons = styled.div`
    text-align: center;
`;
