import { action, computed, observable, makeObservable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import { Client, Matter, Code, ActionCode, TimeEntryType } from '../api/types/types';
import TimeEntry, { SapStatus } from '../api/immutables/ImmutableTimeEntry';
import { DateTime } from 'luxon';
import TimeEntryDialogStore from 'store/timeentry.dialog.store';
import { ValidateSave, ValidatePost } from 'api/immutables/validators';
import ImmutableTimeEntry from 'api/immutables/ImmutableTimeEntry';
import { debounce } from 'typescript-debounce-decorator';
import { RootStore } from 'store/root.store';
import logger from '../logging/logging';

export default class TransferEntryDialogStore extends TimeEntryDialogStore {
    @observable transferNarrativeFlag: boolean = false;
    @observable transferdNarrative: string;
    @observable entries: TimeEntry[] = [];

    constructor(root: RootStore) {
        super(root);
        makeObservable(this);
        this.wrappedPostTransferredEntries = this.wrappedPostTransferredEntries.bind(this);
        this.wrappedSaveTransferredEntries = this.wrappedSaveTransferredEntries.bind(this);
    }

    @computed get matters(): Matter[] {
        let matterList: Matter[] = [];
        this.entries.forEach((te) => {
            if (te.matter) {
                matterList.push(te.matter);
            }
        });
        return matterList;
    }

    @loadable()
    async getTotalDurationExclusive (workDate: string) {
        let totalDuration = await this.rootStore.api.TimeEntry.getTotalForDateExclusive(
            workDate,
            this.entries.map(e => e.id!)
        );
        return totalDuration;
    }

    @loadable()
    async openTransfer (entries: TimeEntry[]) {
        try {
            this.entries = entries;
            this.entry = new TimeEntry();
            this.transferNarrativeFlag = false;
            this.selectedTemplate = undefined;
            this.validationState = undefined;
            this.durationValidationState = false;

            // Check if same client
            let isSameClient: boolean = entries.every((c: TimeEntry, ind, cArray) => c.clientId === cArray[0].clientId),
                mClient: Client | null = isSameClient ? entries[0].client : null;

            // Check if all selected entries has same matter, then set matter obj with that matter.
            let isSameMatter: boolean = entries.every((mat: TimeEntry, index, array) => mat.matterId === array[0].matterId),
                mMatter: Matter | null = isSameMatter ? entries[0].matter : null;

            // getting banned words on opening the dialog when matter ids are same, coz bannned words array will be empty.
            if (mMatter) {
                mMatter = await this.rootStore.api.Matter.get(mMatter.id);
            }

            let isSamePhase: boolean = entries.every((phs: TimeEntry, index, array) => phs.phaseId === array[0].phaseId),
                mPhase: Code | null = isSamePhase ? entries[0].phase : null;

            let isSameTask: boolean = entries.every((tsk: TimeEntry, index, array) => tsk.taskCodeId === array[0].taskCodeId),
                mTask: Code | null = isSameTask ? entries[0].task : null;

            let isSameActivity: boolean = entries.every((act: TimeEntry, index, array) => act.actCodeId === array[0].actCodeId),
                mActivity: Code | null = isSameActivity ? entries[0].activity : null;

            let isSameActionCode: boolean = entries.every((accd: TimeEntry, index, array) => accd.actionCodeId === array[0].actionCodeId),
                mActionCode: ActionCode | null = isSameActionCode ? entries[0].actionCodeObj : null;

            let isSameFFTask: boolean = entries.every((accd: TimeEntry, index, array) => accd.ffTaskCodeId === array[0].ffTaskCodeId),
                mFFTask: Code | null = isSameFFTask ? entries[0].ffTask : null;

            let isSameFFActivity: boolean = entries.every((accd: TimeEntry, index, array) => accd.ffActCodeId === array[0].ffActCodeId),
                mFFActivity: Code | null = isSameFFActivity ? entries[0].ffActivity : null;

            let isReference: boolean = entries.every((ref: TimeEntry, index, array) => ref.reference === array[0].reference),
                mReference: string | null = isReference ? entries[0].reference : null;
            // If entries are from same work date, else today's date.
            let isSameWorkDate: boolean = entries.every((e: TimeEntry, i, arr) => e.workDateTime === arr[0].workDateTime),
                workDate: string = isSameWorkDate ? entries[0].workDateTime : DateTime.local().startOf('day').toISO();
            this.setWorkDate(DateTime.fromISO(workDate));

            // Set office of the TimeKeeper
            let actTk = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(workDate));

            let isSameOffice: boolean = entries.every((so: TimeEntry, index, array) => so.office === array[0].office),
                mOffice: string | undefined = isSameOffice ? entries[0].office : actTk ? actTk.office : undefined;

            let isSameOfficeName: boolean = entries.every((so: TimeEntry, index, array) => so.officeName === array[0].officeName),
                mOfficeName: string | undefined = isSameOfficeName ? entries[0].officeName : actTk ? actTk.officeName : undefined;

            this.entry = this.entry
                .setClient(mClient)
                .setMatter(mMatter)
                .setNarrative('')
                .setOffice(mOffice)
                .setOfficeName(mOfficeName)
                .setPhase(mPhase)
                .setTask(mTask)
                .setAct(mActivity)
                .setActionCode(mActionCode)
                .setFFTask(mFFTask)
                .setFFAct(mFFActivity)
                .setReference(mReference);
            if (mMatter) {
                const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(mMatter.id, workDate);
                this.entry.isActCode = codeSetFlags.isActCode;
                this.entry.isPhaseCode = codeSetFlags.isPhaseCode;
                this.entry.isFfTaskCode = codeSetFlags.isFfTaskCode;
            }
            this.entry.sapStatus = SapStatus.UNSUBMITTED;
            this.entry.timeKeeperId = this.rootStore.api.Session.currentTimeKeeper!;
            this.entry.timeEntryType = TimeEntryType.TRANSFER;
            this.entry.collaborateTks = '';
            this.entry.collaborateInfo = null;
            return await this.open(this.entry);
        } catch (e) {
            if (e) {
                throw e;
            }
            return {};
        }
    }

    @computed get changedEntries() {
        return this.entries.map((entry) => {
            let actTk = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(entry.workDateTime!));
            return entry.setClient(this.entry.client)
                .setMatter(this.entry.matter)
                .setPhase(this.entry.phase)
                .setTask(this.entry.task)
                .setAct(this.entry.activity)
                .setActionCode(this.entry.actionCodeObj)
                .setFFTask(this.entry.ffTask)
                .setFFAct(this.entry.ffActivity)
                .setOffice(this.entry.office ? this.entry.office : actTk ? actTk.office : undefined)
                .setOfficeName(this.entry.officeName ? this.entry.officeName : actTk ? actTk.officeName : undefined)
                .setNarrative(entry.narrative || '');
        });
    }

    @debounce(500, {leading: false})
    @action
    async wrappedPostTransferredEntries () {
        if (this.saving) {
            return;
        }
        this.saving = true;
        await this.postTransferredEntries();
        this.saving = false;
    }
    @debounce(500, {leading: false})
    @action
    async wrappedSaveTransferredEntries() {
        if (this.saving) {
            return;
        }
        this.saving = true;
        await this.saveTransferredEntries();
        this.saving = false;
    }

    @loadable()
    @action.bound
    async saveTransferredEntries() {
        const { features, getActiveTimeKeeperForDate } = this.rootStore.appStore;
        try {
            let vstate = ValidateSave(
                this.entry,
                await this.getTotalDurationExclusive(this.entry.workDateTime),
                features,
                getActiveTimeKeeperForDate(DateTime.fromISO(this.entry.workDateTime))
            );
            vstate.missing.matter = !this.entry.matter;
            if (!vstate.valid) {
                this.validationState = vstate;
                return;
            }
            let movableTotal = 0;
            this.changedEntries.forEach( entry => movableTotal = movableTotal + entry.duration);
            this.rootStore.timeEntryStore.saveEntries(this.changedEntries, false);
            this.resolveAndClose(this.changedEntries);
        } catch (e) {
            logger.info('Time Entries, Saving Transfer Entries failed.\n', e);
            throw e;
        }
    }

    @loadable()
    @action.bound
    async postTransferredEntries() {
        try {
            let postedEntries: ImmutableTimeEntry[] = [];
            let notPosted: number = 0;
            await Promise.all(this.changedEntries.map(async (entry) => {
                const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(entry.workDateTime));
                let matterEntryType: string = '';
                let matterStatusDesc: string = '';
                let narrativeMinLength;
                let narrativeMaxLength;
                if (entry.matterId) {
                    const matter = await this.rootStore.api.Matter.get(entry.matterId);
                    if (matter) {
                        matterEntryType = matter.entryType;
                        matterStatusDesc = matter.statusDescription;
                        narrativeMinLength = matter.minLength;
                        narrativeMaxLength = matter.maxLength;
                        entry.bannedWords = matter.bannedWords;
                        entry.blockBillingWords = matter.blockBillingWords;
                    }
                }
                let vstate = ValidatePost(
                    entry,
                    0,
                    matterStatusDesc,
                    matterEntryType,
                    this.rootStore.appStore.features,
                    activeTimeKeeper,
                    narrativeMinLength,
                    narrativeMaxLength
                );
                if (vstate.valid) {
                    entry = entry.setPosted();
                    postedEntries.push(entry);
                } else {
                    this.validationState = vstate;
                    notPosted = notPosted + 1;
                }
            }));
            if (postedEntries.length > 0) {
                this.entries = postedEntries;
                await this.saveTransferredEntries();
            }
            if (notPosted > 0) {
                // TODO: internationalization
                let type = notPosted === 1 ? 'entry' : 'entries';
                this.rootStore.snackbarStore.triggerSnackbar(notPosted + ' time ' + type + ' failed to post');
            }
        } catch (e) {
            logger.info('Time Entries, Posting Transfer Entries failed.\n', e);
            throw e;
        }
    }
}