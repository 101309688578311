import { Features, Setting } from '../../api/types/types';

const constants = require('../../constants.json');

export class TimeCastSettings {
    private _systemIdleSeconds: number;
    private _minimumIntervalSeconds: number;
    private _mergeIntervalSeconds: number;
    private _allowedPrograms: string[];
    private _allowedURLs: string[];
    private _isServerPushEnabled: boolean;
    private _connectAutomatically: boolean;

    public static from(
        systemIdleSeconds: number,
        minimumIntervalSeconds: number,
        mergeIntervalSeconds: number,
        allowedPrograms: string[],
        allowedURLs: string[],
        isServerPushEnabled: boolean,
        connectAutomatically: boolean
    ): TimeCastSettings {
        const setting = new TimeCastSettings();
        setting._systemIdleSeconds = systemIdleSeconds;
        setting._minimumIntervalSeconds = minimumIntervalSeconds;
        setting._mergeIntervalSeconds = mergeIntervalSeconds;
        setting._allowedPrograms = allowedPrograms;
        setting._allowedURLs = allowedURLs;
        setting._isServerPushEnabled = isServerPushEnabled;
        setting._connectAutomatically = connectAutomatically;
        return setting;
    }

    public static default() {
        const features = (JSON.parse(localStorage.getItem('features') || '{}')) as Features;
        
        return TimeCastSettings.from(
            1800,
            90,
            90,
            [],
            [],
            true,
            !!features.EpochConfigTimeCastConnectAutomaticallyByDefault 
        );
    }

    /**
     * Returns defaults if the passed in settings object doesn't contain the value
     */
    public static fromSetting(setting?: Setting): TimeCastSettings {
        const result: TimeCastSettings = JSON.parse((setting || {value: '{}'}).value);
        const defaults = TimeCastSettings.default();
        return TimeCastSettings.from(
            result._systemIdleSeconds !== undefined
                ? result._systemIdleSeconds
                : defaults._systemIdleSeconds,
            result._minimumIntervalSeconds !== undefined
                ? result._minimumIntervalSeconds
                : defaults._minimumIntervalSeconds,
            result._mergeIntervalSeconds !== undefined
                ? result._mergeIntervalSeconds
                : defaults._mergeIntervalSeconds,
            result._allowedPrograms  !== undefined
                ? result._allowedPrograms
                : defaults._allowedPrograms,
            result._allowedURLs !== undefined
                ? result._allowedURLs
                : defaults._allowedURLs,
            result._isServerPushEnabled !== undefined
                ? result._isServerPushEnabled
                : defaults._isServerPushEnabled,
            result._connectAutomatically !== undefined
                ? result._connectAutomatically
                : defaults._connectAutomatically
        );
    }

    public getSystemIdleSeconds(): number {
        return this._systemIdleSeconds;
    }

    public setSystemIdleSeconds(value: number): TimeCastSettings {
        const clone = this.clone();
        clone._systemIdleSeconds = value;
        return clone;
    }

    public getMinimumIntervalSeconds(): number {
        return this._minimumIntervalSeconds;
    }

    public setMinimumIntervalSeconds(value: number): TimeCastSettings {
        const clone = this.clone();
        clone._minimumIntervalSeconds = value;
        return clone;
    }

    public getMergeIntervalSeconds(): number {
        return this._mergeIntervalSeconds;
    }

    public setMergeIntervalSeconds(value: number): TimeCastSettings {
        const clone = this.clone();
        clone._mergeIntervalSeconds = value;
        return clone;
    }

    public getAllowedPrograms(): string[] {
        return this._allowedPrograms;
    }

    public setAllowedPrograms(value: string[]): TimeCastSettings {
        const clone = this.clone();
        clone._allowedPrograms = value;
        return clone;
    }

    public addAllowedProgram(program: string): TimeCastSettings {
        if (this._allowedPrograms.includes(program)) {
            return this;
        } else {
            return this.setAllowedPrograms([...this._allowedPrograms, program]);
        }
    }

    public removeAllowedProgram(program: string): TimeCastSettings {
        if (!this._allowedPrograms.includes(program)) {
            return this;
        } else {
            return this.setAllowedPrograms(this._allowedPrograms.filter(u => program !== u));
        }
    }

    public getAllowedURLs(): string[] {
        return this._allowedURLs;
    }

    public setAllowedURLs(value: string[]): TimeCastSettings {
        const clone = this.clone();
        clone._allowedURLs = value;
        return clone;
    }

    public addAllowedURL(url: string): TimeCastSettings {
        if (this._allowedURLs.includes(url)) {
            return this;
        } else {
            return this.setAllowedURLs([...this._allowedURLs, url]);
        }
    }

    public removeAllowedURL(url: string): TimeCastSettings {
        if (!this._allowedURLs.includes(url)) {
            return this;
        } else {
            return this.setAllowedURLs(this._allowedURLs.filter(u => url !== u));
        }
    }

    public isServerPushEnabled(): boolean {
        return this._isServerPushEnabled;
    }

    public setServerPushEnabled(value: boolean): TimeCastSettings {
        const clone = this.clone();
        clone._isServerPushEnabled = value;
        return clone;
    }

    public shouldConnectAutomatically(): boolean {
        return this._connectAutomatically;
    }

    public setConnectAutomatically(value: boolean): TimeCastSettings {
        const clone = this.clone();
        clone._connectAutomatically = value;
        return clone;
    }
    
    public toSetting(overrides: Partial<Setting>): Setting {
        const setting = {
            id: overrides.id,
            key: constants.timecast.settingKey,
            value: JSON.stringify(this),
            global: overrides.global || false,
            deleted: overrides.deleted || false
        };

        if (!setting.id) {
            delete setting.id;
        }

        return setting;
    }

    private clone(): TimeCastSettings {
        return Object.assign(new TimeCastSettings(), JSON.parse(JSON.stringify(this)));
    }

}