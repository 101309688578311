import { Spellcheck } from '@fulcrumgt/react-fulcrum-editor';

const dictionaryManager = Spellcheck.globalDictionaryManager;

async function newDictionary(affix: string, dic: string, key: string, label: string) {
    const affixBuffer = await (await fetch(affix)).arrayBuffer();
    const dictionaryBuffer = await (await fetch(dic)).arrayBuffer();
    
    await dictionaryManager.addDictionary({
            key: key,
            label: label
        },
        affixBuffer,
        dictionaryBuffer
    );
}

(async function loadDictionaries() {
    await dictionaryManager.initialize();
    
    await newDictionary(
        'dictionaries/Arabic/ar.aff',
        'dictionaries/Arabic/ar.dic',
        'AR',
        'Arabic'
    );
    await newDictionary(
        'dictionaries/Dutch/nl.aff',
        'dictionaries/Dutch/nl.dic',
        'NL',
        'Dutch'
    );
    await newDictionary(
        'dictionaries/English (American)/en_US.aff',
        'dictionaries/English (American)/en_US.dic',
        'EN',
        'English (US)'
    );
    await newDictionary(
        'dictionaries/English (Australian)/en_AU.aff',
        'dictionaries/English (Australian)/en_AU.dic',
        'en-AU',
        'English (Australian)'
    );
    await newDictionary(
        'dictionaries/English (British)/en_GB.aff',
        'dictionaries/English (British)/en_GB.dic',
        'en-GB',
        'English (British)'
    );
    await newDictionary(
        'dictionaries/English (Canadian)/en_CA.aff',
        'dictionaries/English (Canadian)/en_CA.dic',
        'en-CA',
        'English (Canadian)'
    );
    await newDictionary(
        'dictionaries/French/fr.aff',
        'dictionaries/French/fr.dic',
        'FR',
        'French'
    );
    await newDictionary(
        'dictionaries/French (Canadian)/fr_CA.aff',
        'dictionaries/French (Canadian)/fr_CA.dic',
        'fr-CA',
        'French (Canadian)'
    );
    await newDictionary(
        'dictionaries/German/de.aff',
        'dictionaries/German/de.dic',
        'DE',
        'German'
    );
    await newDictionary(
        'dictionaries/German (Austria)/de_AT.aff',
        'dictionaries/German (Austria)/de_AT.dic',
        'de-AT',
        'German (Austria)'
    );
    await newDictionary(
        'dictionaries/German (Swiss)/de_CH.aff',
        'dictionaries/German (Swiss)/de_CH.dic',
        'de-CH',
        'German (Swiss)'
    );
    await newDictionary(
        'dictionaries/Greek/el.aff',
        'dictionaries/Greek/el.dic',
        'EL',
        'Greek'
    );
    await newDictionary(
        'dictionaries/Italian/it.aff',
        'dictionaries/Italian/it.dic',
        'IT',
        'Italian'
    );
    await newDictionary(
        'dictionaries/Kazak/kk.aff',
        'dictionaries/Kazak/kk.dic',
        'KK',
        'Kazakh'
    );
    await newDictionary(
        'dictionaries/Polish/pl.aff',
        'dictionaries/Polish/pl.dic',
        'PL',
        'Polish'
    );
    await newDictionary(
        'dictionaries/Russian/ru.aff',
        'dictionaries/Russian/ru.dic',
        'RU',
        'Russian'
    );
    await newDictionary(
        'dictionaries/Spanish/es.aff',
        'dictionaries/Spanish/es.dic',
        'ES',
        'Spanish'
    );

    await dictionaryManager.setDefaultDictionaryByKey('EN');

})();
