// import English Language files
import en_common_translation from './en/common.json';
import en_home_translation from './en/home.json';
import en_timeentries_translation from './en/timeentries.json';
import en_matters_translation from './en/matters.json';
import en_templates_translation from './en/templates.json';
import en_timers_translation from './en/timers.json';
import en_narratives_translation from './en/narratives.json';
import en_management_dashboard_translation from './en/managementBoard.json';
import en_settings_translation from './en/settings.json';
// import German Language files
import de_common_translation from './de/common.json';
import de_home_translation from './de/home.json';
import de_timeentries_translation from './de/timeentries.json';
import de_matters_translation from './de/matters.json';
import de_templates_translation from './de/templates.json';
import de_timers_translation from './de/timers.json';
import de_narratives_translation from './de/narratives.json';
import de_management_dashboard_translation from './de/managementBoard.json';
import de_settings_translation from './de/settings.json';

/**
 * set up translation files; split into different major pages of the application
 * order of the keys is significant as the same will be reflected in user preferences dropdown.
 */

export const resources = {
  en: {
    common: en_common_translation,
    home: en_home_translation,
    timeentries: en_timeentries_translation,
    matters: en_matters_translation,
    templates: en_templates_translation,
    timers: en_timers_translation,
    narratives: en_narratives_translation,
    management_dashboard: en_management_dashboard_translation,
    settings: en_settings_translation
  },
  de: {
    common: de_common_translation,
    home: de_home_translation,
    timeentries: de_timeentries_translation,
    matters: de_matters_translation,
    templates: de_templates_translation,
    timers: de_timers_translation,
    narratives: de_narratives_translation,
    management_dashboard: de_management_dashboard_translation,
    settings: de_settings_translation
  }
};
