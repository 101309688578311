import BaseWebImplementation from './Base.impl';
import { Report } from '../../types/types';
import ReportAPI from '../../interfaces/ReportAPI';

export default class ReportImpl extends BaseWebImplementation implements ReportAPI {

    all = async (): Promise<Report[]> => {
        const {data} = await this.http.get(`reports`);
        return data;
    }
    
    getToken = async () => {
        let response = await this.http.get(`reports/token`);
        if (response.status === 200) {
            localStorage.setItem('superset-access-token', response.data.access_token);
            localStorage.setItem('superset-refresh-token', response.data.refresh_token);
        }
    }
}
