import * as React from 'react';
import { Menu, Item } from 'react-contexify';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import { withTranslation } from 'react-i18next';

interface Props {
    /** id for corresponding provider, must be unique */
    id: string;
    editHandler?: (t: TimeEntry) => void;
    postHandler?: (t: TimeEntry) => void;
    unpostHandler?: (t: TimeEntry) => void;
    deleteHandler?: (t: TimeEntry) => void;
    copyHandler?: (t: TimeEntry) => void;
    viewHandler?: (t: TimeEntry) => void;
    splitHandler?: (t: TimeEntry) => void;
    mergeHandler?: (t: TimeEntry) => void;
    transferHandler?: (t: TimeEntry) => void;
    approveHandler?: (t: TimeEntry) => void;
    rejectHandler?: (t: TimeEntry) => void;
    // tslint:disable-next-line:no-any
    t: any;
}

/**
 * context menu for TimeEntryItems
 * displays options only for handlers that are passed in. Blocked actions expect undefined
 */
class TEContextMenu extends React.Component<Props> {

    // tslint:disable-next-line:no-any
    onClick = (handler: (t: TimeEntry) => void) => (props: any) => {
        handler(props.entry);
    };

    render() {
        let {
            id,
            editHandler,
            postHandler,
            unpostHandler,
            deleteHandler,
            copyHandler,
            viewHandler,
            splitHandler,
            mergeHandler,
            transferHandler,
            approveHandler,
            rejectHandler,
            t
        } = this.props;
        return (
            <Menu id={id} animation="none">
                {editHandler ? <Item onClick={this.onClick(editHandler)}>{t('list.row.entry.right_click.edit')}</Item> : <React.Fragment/>}
                {viewHandler ? <Item onClick={this.onClick(viewHandler)}>{t('list.row.entry.right_click.view')}</Item> : <React.Fragment/>}
                {postHandler ? <Item onClick={this.onClick(postHandler)}>{t('action.post')}</Item> : <React.Fragment/>}
                {unpostHandler ? <Item onClick={this.onClick(unpostHandler)}>{t('action.unpost')}</Item> : <React.Fragment/>}
                {deleteHandler ? <Item onClick={this.onClick(deleteHandler)}>{t('delete', { ns: 'common' })}</Item> : <React.Fragment/>}
                {copyHandler ? <Item onClick={this.onClick(copyHandler)}>{t('list.row.entry.right_click.copy.label')}</Item> : <React.Fragment/>}
                {splitHandler ? <Item onClick={this.onClick(splitHandler)}>{t('list.row.entry.right_click.split')}</Item> : <React.Fragment/>}
                {mergeHandler ? <Item onClick={this.onClick(mergeHandler)}>{t('action.merge')}</Item> : <React.Fragment/>}
                {transferHandler ? <Item onClick={this.onClick(transferHandler)}>{t('action.transfer')}</Item> : <React.Fragment/>}
                {approveHandler ? <Item onClick={this.onClick(approveHandler)}>{t('action.approve', { ns: 'management_dashboard' })}</Item> : <React.Fragment/>}
                {rejectHandler ? <Item onClick={this.onClick(rejectHandler)}>{t('action.reject', { ns: 'management_dashboard' })}</Item> : <React.Fragment/>}
            </Menu>
        );
    }
}

export default withTranslation(['timeentries', 'common', 'management_dashboard'])(TEContextMenu);
