import BaseWebImplementation from './Base.impl';
import SettingsAPI from '../../interfaces/SettingsAPI';
import { Setting } from '../../types/types';
import { ApiResult } from '../../util';

export default class SettingsImpl extends BaseWebImplementation implements SettingsAPI {
    handlers: (((entries: Setting[]) => void) | null)[] = [];

    registerReceiver = (handler: (settings: Setting[]) => void): (() => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return () => {
            this.handlers[theIndex] = null;
        };
    }

    receiveSettings = (settings: Setting[]) => {
        this.handlers.filter(h => h !== null).forEach(h => h!(settings));
    }

    all = async (): Promise<Setting[]> => {
        const {data} = await this.http.get(`settings`);
        return data;
    }

    /**
     * Returns the user-specified setting. Inherits from any global settings with the same key.
     * 
     *      - If the user setting does not exist, it returns the global setting with:
     *              `global` property set to false, and,
     *              an undefined `id`.
     *        This way, you can push it back to the server without having to worry about whether
     *        it's global or not.
     *        
     *      - If the global setting does not exist, it returns undefined
     */
    getByKey = async (key: string): Promise<Setting | undefined> => {
        const {data} = await this.http.get(`settings`);
        
        const settings: Setting[] = (data || []).filter((s: Setting) => !s.deleted);
        const globalSetting = settings.find(s => s.global && s.key === key);
        const userSetting = settings.find(s => !s.global && s.key === key);
        
        if (!globalSetting) {
            return userSetting;
        }
        
        const setting: Setting = Object.assign(globalSetting, userSetting);
        
        if (setting.global) {
            // no user setting set.
            delete setting.id;
            setting.global = false;
        }
        
        return setting;
    }

    save = async (setting: Setting): Promise<ApiResult<Setting>> => {
        const {data} = await this.http.put(`settings`, [setting]);

        if (data.length > 0) {
            return data[0];
        } else {
            throw 'Could not save setting.';
        }
    }

    updateSettings = async (entries: Setting[]): Promise<ApiResult<Setting>[]> => {
        let entryData = entries.map((n) => ({
            id: n.id,
            key: n.key,
            value: n.value,
            deleted: n.deleted,
            global: n.global
        }));

        const {data} = await this.http.put('/settings', entryData);

        // tslint:disable-next-line:no-any
        let savedEntries = data.filter((r: any) => !r.status.failed).map((r: any) => r.object);

        if (savedEntries.length > 0) {
            let syntheticSync = {
                templates: [],
                glossaries: [],
                timers: [],
                timeEntries: [],
                settings: savedEntries
            };
            this.root.Session.tabexClient.emit('sync', syntheticSync);
        }

        return data;
    }
}