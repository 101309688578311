import { Report } from '../../types/types';
import ReportAPI from '../../interfaces/ReportAPI';
import BaseElectronImplementation from './Base.impl';

export default class ReportImpl extends BaseElectronImplementation implements ReportAPI {

    all = async (): Promise<Report[]> => {
        return await this.root.webImpl.Report.all();
    }
    
    getToken = async () => {
        await this.root.webImpl.Report.getToken();
    }
}
