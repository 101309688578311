import MatterAPI from 'api/interfaces/MatterAPI';
import BaseElectronImplementation from './Base.impl';
import { Matter } from 'api/types/types';
import { DateTime } from 'luxon';
import { MatterI } from './Dexie';

export default class MatterImpl extends BaseElectronImplementation implements MatterAPI {
    handlers: (((matters: Matter[]) => void) | null )[] = [];

    searchMatters = async (searchText: string, showTracked: boolean = true, clientId?: number, 
                           workDate?: string, offset: number = 0, limit: number = 50) => {
        try {
            return await this.root.webImpl.Matter.searchMatters(
                searchText, showTracked, clientId, workDate, offset, limit
            );
        } catch {
            const t = await this.getTrackedMatters(workDate, searchText, clientId);
            return t.slice(offset, offset + limit);
        }
    }

    getAvailableMatters = async (searchText: string, showTracked: boolean = true, clientId?: number,
                                 workDate?: string, offset: number = 0, limit: number = 50) => {
        try {
            return await this.root.webImpl.Matter.searchMatters(
                searchText, showTracked, clientId, workDate, offset, limit
            );
        } catch (e) {
            throw e;
        }
    }
    
    get = async (id: number) => {
        // tslint:disable-next-line:no-any
        let matter = await this.root.db.matters.get(id) as any as Matter;
        if (matter) {
            matter.isActCode = (await this.root.Code.getActivityCodes(id)).length > 0;
            matter.isFfTaskCode = (await this.root.Code.getFFTaskCodes(id)).length > 0;
            matter.isPhaseCode = (await this.root.Code.getPhaseCodes(id)).length > 0;
            matter.bannedWords = (await this.getBannedWords(id));
            matter.blockBillingWords = (await this.getBlockBillingWords(id));
        }
        return matter; 
    }

    // setCodeSetFlags = async (matter: Matter, date: string) => {
    //     const m = await this.get(matter.id);
    //     let flags: CodeSetFlags = {
    //         isPhaseCode: false,
    //         isFfTaskCode: false,
    //         isActCode: false,
    //         phases: [],
    //         ffTasks: [],
    //         activities: []
    //     };
    //     if (m) {
    //         flags = await this.root.Code.determineCodeSetFields(m.id, date)
    //         return flags;
    //     } else {
    //         return flags;
    //     }
    //    
    // }

    track = async (ids: number[]) => {
        const resp = await this.root.webImpl.Matter.track(ids);
        if (resp.status === 200) {
            await Promise.all([
                this.root.db.clients.bulkPut(resp.data.clients || []),
                this.root.db.matters.bulkPut(resp.data.matters || []),
                this.root.db.codeSetMappings.bulkPut(resp.data.codeSetMappings || []),
                this.root.db.codes.bulkPut(resp.data.codes || []),
                this.root.db.matterOfficeMappings.bulkPut(resp.data.matterOfficeMappings || []),
                this.root.db.matterTkMappings.bulkPut(resp.data.matterTkMappings || [])
            ]);
        }
        // await this.root.Session.sync();
    }

    untrack = async (ids: number[]) => {
        await this.root.webImpl.Matter.untrack(ids);
        this.root.Session.sync();
    }

    getTrackedMatters = async (workDate?: string, searchText?: string, clientId?: number) => {
        try {
            let trackedMatters = await this.root.webImpl.Matter.getTrackedMatters();
            // tslint:disable-next-line:no-any
            trackedMatters = trackedMatters.map((m: any) => {
                (m as unknown as Matter).tracked = true;
                return m;
            })
            return trackedMatters;
        } catch {
            let curTk = this.root.Session.currentTimeKeeper!;
            let tkMappings = (await this.root.db.matterTkMappings.toArray())
                .filter((tk) => tk.timeKeeperId === curTk && tk.tracked)
                .sort((tk1, tk2) => (new Date(tk1.lastModified) < new Date(tk2.lastModified)) ? 1 : -1);
            let mattersProms = tkMappings.map((tkm) => this.root.db.matters.get(tkm.matterId));
            let mattersArr = await Promise.all(mattersProms);
            let matters: MatterI[] = mattersArr.filter((m) => {
                if (!m) {
                    return false;
                }
                if (clientId) {
                    if ( m!.clientId !== clientId) {
                        return false;
                    }
                }
                let catText = `${m!.name}${m!.number}${m!.clientName}${m!.clientNumber}`.toUpperCase();
                if (!catText.includes((searchText || '').toUpperCase())) {
                    return false;
                }
                return true;
            }) as MatterI[];
            if (workDate) {
                let matterOfficeProms = matters.map(async (m) =>
                    (await this.root.db.matterOfficeMappings.where({matterId: m!.id}).toArray())
                    .map(moMap => moMap.office)
                );
                let matterOffices = await Promise.all(matterOfficeProms);
                let validOffices = (await this.root.db.timeKeeperAssignments.toArray())
                    .filter(tka => {
                        let tkStart = DateTime.fromISO(tka.startDate);
                        let tkEnd = DateTime.fromISO(tka.endDate);
                        let curDate = DateTime.fromISO(workDate)
                        return (tka.timeKeeperId === curTk) && curDate >= tkStart && curDate <= tkEnd;
                    })
                    .map(tka => tka.office);
                return matters.filter((m, idx) =>
                    matterOffices[idx]!.reduce((prev, cur) => (prev || validOffices.includes(cur)), false)
                ).map(m => {
                    // tslint:disable-next-line:no-any
                    (m as unknown as Matter).tracked = true;
                    return m;
                });
            }
            matters = matters.filter(m => m !== undefined);
            return matters.map(m => {
                // tslint:disable-next-line:no-any
                (m as unknown as Matter).tracked = true;
                return m;
            });
        }
    }

    getBannedWords = async (id: number) => {
        // let invalidWords: string[] = [];
        // try {
        //     invalidWords = await this.root.webImpl.Matter.getBannedWords(id);
        //     await this.root.db.bannedWordsMapping
        //                       .where({matterId: id})
        //                       .modify({words: invalidWords})
        // } catch {
        //     let bannedWordsMapping = (await this.root.db.bannedWordsMapping
        //                             .where({matterId: id}).toArray())
        //                             .map((m) => m.words)
        //     invalidWords = bannedWordsMapping.reduce(function(prev: string[], cur: string[]) {
        //                             return prev.concat(cur);
        //                         }, []);
        // }
        // return invalidWords;
        try {
            return (await this.root.db.bannedWordsMapping.where({matterId: id}).toArray())
                .map((m) => m.words)
                .reduce((prev: string[], cur: string[]) => prev.concat(cur), []);
        } catch (e) {
            throw e;
        }
    }

    getBlockBillingWords = async (id: number) => {
        // let invalidWords: string[] = [];
        // try {
        //     invalidWords = await this.root.webImpl.Matter.getBlockBillingWords(id);
        //     await this.root.db.blockBillingWordsMapping
        //                       .where({matterId: id})
        //                       .modify({words: invalidWords})
        // } catch {
        //     let blockBillingWordsMapping = (await this.root.db.blockBillingWordsMapping
        //                                     .where({ matterId: id }).toArray())
        //                                     .map((m) => m.words)
        //     invalidWords = blockBillingWordsMapping.reduce(function (prev: string[], cur: string[]) {
        //                     return prev.concat(cur);
        //                     }, []);
        // }
        //
        // return invalidWords;
        try {
            return (await this.root.db.blockBillingWordsMapping.where({matterId: id}).toArray())
                .map((m) => m.words)
                .reduce((prev: string[], cur: string[]) => prev.concat(cur), []);
        } catch (e) {
            throw e;
        }
    }

    registerReciever = (handler: (matters: Matter[]) => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return () => {
            this.handlers[theIndex] = null;
        };
    }

    recieve = (matters: MatterI[]) => {
        const mats = matters.map((m) => m as unknown as Matter);
        this.handlers.filter(h => h !== null).forEach(h => h!(mats));
    }

    updateLastUsedOnTkMappings = (matterId: number) => {
        const timeKeeperId: number = this.root.Session.currentTimeKeeper || -1;
        // const userId = localStorage.getItem('userId') || '-1';
        this.root.db.matterTkMappings
            .where({matterId: matterId, timeKeeperId: timeKeeperId})
            .modify({lastModified: new Date().toISOString()});
    }
}
