import { action, observable, makeObservable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import { DateTime } from 'luxon';
import DialogRootStore from 'store/dialog.root.store';
import logger from '../logging/logging';
import { RootStore } from 'store/root.store';

export default class MoveDateDialogStore extends DialogRootStore<TimeEntry[], TimeEntry[]> {
    @observable newDate: DateTime;
    @observable entries: TimeEntry[] = [];
    @observable invDur: boolean = false;
    @observable invWorkDate: boolean = false;
    
    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action setNewDate = (newDate: DateTime) => {
        this.newDate = newDate;
        this.invDur = false;
        this.invWorkDate = false;
    };

    @action.bound
    async onOpen(entries: TimeEntry[]) {
        let loc = new Date();
        let year = loc.getFullYear();
        let month = loc.getMonth() + 1; // getMonth() returns the month from 0 to 11
        let day = loc.getDate();
        this.newDate = DateTime.local(year, month, day);
        this.entries = entries;
    }

    @loadable()
    @action.bound 
    async saveEntries() {
        try {
            let excludeIds: number[] = this.entries ? this.entries.map(entry => entry.id!) : [];
            this.invWorkDate = this.entries.some(entry => this.newDate < DateTime.fromISO(entry.matterStartDate!));
            let totalForThatDay = await this.rootStore.api.TimeEntry.getTotalForDateExclusive(
                this.newDate.startOf('day').toISO(),
                excludeIds
            );
            let movableTotal = 0;
            this.entries.forEach( entry => movableTotal = movableTotal + entry.duration);
            if (totalForThatDay + movableTotal > ( 24 * 60 * 60)) {
                this.invDur = true;
            } else {
                this.invDur = false;
                if (!this.invWorkDate) {
                    let results = this.entries.map((entry) => entry.setWorkDate(this.newDate));
                    this.resolveAndClose(results);
                }
            }
        } catch (e) {
            logger.error('Time Entries, Saving Entries in Moving Date Dialog Failed.\n', e);
            throw e;
        }
    }

    @action clear() {
        this.invDur = false;
    }

    cancel() {
        this.resolveAndClose([]);
    }
}
