import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import * as Styled from './styled';
import { CallMerge, Check, Delete, PlayArrow, Save, Send, Stop, Undo } from '@material-ui/icons';
import { Button, Checkbox, CircularProgress, Fab, TextField, Tooltip } from '@material-ui/core';
import TimerStore from 'store/Timer/timer.store';
import TimerChunkItem from 'components/TimerChunkItem/TimerChunkItem';
import RunningTimerChunkItem from 'components/TimerChunkItem/RunningTimerChunkItem';
import { FlexDiv } from 'common/flex';
import { AutoSizer, List } from 'react-virtualized';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import { MatterItemFormatter } from 'components/TemplateForm';
import { Features, Matter, TimeKeeperAssignment } from '../../api/types/types';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from '../../api/interfaces/RootAPI';
import Template from '../../api/immutables/ImmutableTemplate';
import { FabContainer } from '../Home/styled';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { TKConsumer } from 'common/TKProvider';
import { getTimerDurationTxt } from 'util/timer';
import { withTranslation } from 'react-i18next';

interface HeaderTimerProps {
    timer: ImmutableTimer,
    onStart: () => void;
    onStop: () => void;
    loadingId?: number;
    includeSubmitted: boolean;
    // tslint:disable-next-line:no-any
    t: any;
}
interface HeaderTimerState {
    durTxt: string;
}

export class HeaderTimer extends React.Component<HeaderTimerProps, HeaderTimerState> {
    interval: NodeJS.Timeout;

    constructor(props: HeaderTimerProps) {
        super(props);
        this.state = { durTxt: '' };
        if (props.timer.active) {
            this.interval = setInterval(this.timerTick, 250);
        }
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    getSnapshotBeforeUpdate(prevProps: HeaderTimerProps) {
        if (prevProps.timer.active === this.props.timer.active) {
            return null;
        }
        clearInterval(this.interval);
        if (this.props.timer.active) {
            this.interval = setInterval(this.timerTick, 250);
        }
        return null;
    }
    timerTick = () => {
        const { timer, includeSubmitted } = this.props;
        this.setState({ durTxt: getTimerDurationTxt(timer, includeSubmitted) });
    }
    render() {
        const { timer, onStart, onStop, loadingId, includeSubmitted, t } = this.props;
        const dtxt = timer.active ? this.state.durTxt : getTimerDurationTxt(timer, includeSubmitted);
        return (
                <TKConsumer>
                    { (tk: TimeKeeperAssignment) =>
                    <Styled.TimerHeaderContainer>
                        <div style={{float: 'left'}}>
                    {!timer.active ?
                        <Tooltip title={t('action.start')}>
                        <Fab
                            disabled={!!loadingId || !tk.writable}
                            onClick={(e) => {
                                onStart!()
                            }}
                            size="medium"
                            color="primary"
                            aria-label={t('action.start')}
                        >
                            <PlayArrow />
                            {(loadingId === timer.id) && <Styled.FabProgress size={55} />}
                        </Fab></Tooltip> :
                        <Tooltip title={t('action.pause')}>
                        <Fab
                            disabled={!!loadingId || !tk.writable}
                            onClick={(e) => {
                                onStop!()
                            }}
                            size="medium"
                            // color="secondary"
                            style={{backgroundColor: 'rgb(255, 87, 34)', color: 'white'}}
                            aria-label={t('action.pause')}
                        >
                            <Stop />
                            {(loadingId === timer.id) && <Styled.FabProgress size={55} />}
                        </Fab></Tooltip>}
                        </div>
                        <Styled.TimerHeaderText>
                            {dtxt}
                        </Styled.TimerHeaderText>
                    </Styled.TimerHeaderContainer>
                    }
                    </TKConsumer>
                    
        );
    }
}

interface Props {
    timerStore?: TimerStore;
    match: {
        params: {
            id: string;
        }
    };
    // tslint:disable-next-line:no-any
    t: any;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        timerStore: rootStore.timerStore
    };
})
@observer
class TimerFormContainer extends React.Component<Props> {
    componentWillMount() {
        // this.props.templateStore!.resetValidation();
        if (this.props.match.params.id === 'new') {
            // this.props.templateStore!.newTemplate();
        } else {
            this.props.timerStore!.wrappedLoadTimer(Number(this.props.match.params.id));
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.timerStore!.wrappedLoadTimer(Number(this.props.match.params.id));
        }
    }
    fetchMatters = (api: RootAPI) => async (searchText: string, features: Features) => {
        let  results: Matter[] = await api.Matter.searchMatters(searchText, true);
        if (!features.EpochConfigTrackedMatterClientsEnabled && results.length === 0) {
            results = await api.Matter.getAvailableMatters(searchText, false);
        }
        return results;
    }

    render() {
        const { t, timerStore } = this.props;
        const {
            selectedTimer,
            sortedChunks,
            startTimer,
            loadingTimer,
            setMatter,
            deleteChunk,
            editChunk,
            toggleSelect,
            setTemplate,
            setName,
            matter,
            template,
            chunkEdit,
            stopTimer,
            dirty,
            selectedChunks,
            showSelectAll,
            selectAllStatus,
            selectAllTimeSegments,
            timerLoading,
            openTimeEntry,
            saveTimer,
            revert,
            clearSelections,
            deleteSelectedChunks,
            entryFromSelectedChunks,
            validation,
            originalTimer,
            addTimerNote,
            updateSegmentNarrative,
            shouldUpdateChunkProps,
            setUpdateChunkProps,
            rootStore
        } = timerStore!;
        const includeSubmitted = rootStore.appStore.features.EpochConfigTimeSegmentsSubmittedVisible;
        if (!selectedTimer || timerLoading) {
            return (
                <div style={{textAlign: 'center'}}>
                    <CircularProgress/>
                </div>
            );
        }

        const fetchTemplates = rootStore.templateStore.fetchTemplatesInAutoComplate;
        const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
        return (
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
            <>
                <Styled.TimerHeader>
                {showSelectAll && <FlexDiv>
                    <Checkbox
                        checked={selectAllStatus}
                        onChange={selectAllTimeSegments}
                        disableRipple={true}
                        disabled={!tk.writable}
                    />
                </FlexDiv>}
                <HeaderTimer
                    timer={selectedTimer}
                    loadingId={loadingTimer}
                    onStart={() => startTimer(originalTimer)}
                    onStop={() => stopTimer(selectedTimer.id!)}
                    includeSubmitted={includeSubmitted}
                    t={t}
                />
                    <FlexDiv flex={1}>
                        <TextField 
                            style={{width: '97%'}}
                            value={selectedTimer.name} 
                            disabled={selectedTimer.active || !tk.writable} 
                            label={t('selected_timer.header.field.name')}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setName(evt.target.value)}
                            fullWidth={true}
                            error={!!validation.name}
                            helperText={validation.name ? t(validation.name) : undefined}
                        />
                    </FlexDiv>
                    <FeaturesConsumer>
                        { (features: Features) =>
                        <ApiConsumer>
                            {(api: RootAPI) =>
                                <>
                                    <FlexDiv direction="column" flex={1}>
                                        <AutoCompleteField
                                            width="99%"
                                            label={t('selected_timer.header.field.matter', { matterLabel })}
                                            fetch={(searchText: string) => this.fetchMatters(api)(searchText, features)}
                                            currentItem={matter}
                                            clearable={true}
                                            onClear={() => setMatter()}
                                            formatItem={MatterItemFormatter}
                                            getItemText={(m: Matter) => `${m.number} - ${m.name}`}
                                            onSelect={setMatter}
                                            disabled={selectedTimer.active || !tk.writable}
                                            tooltip={(m: Matter) => m.description}
                                        />
                                    </FlexDiv>
                                    <FlexDiv direction="column" flex={1}>
                                        <AutoCompleteField
                                            width="98%"
                                            label={t('selected_timer.header.field.template')}
                                            fetch={fetchTemplates}
                                            currentItem={template}
                                            clearable={true}
                                            disabled={selectedTimer.active || !tk.writable}
                                            onClear={() => setTemplate()}
                                            getItemText={(m: Template) => `${m.name}`}
                                            onSelect={setTemplate}
                                        />
                                    </FlexDiv>
                                </>
                            }
                        </ApiConsumer>
                         }
                    </FeaturesConsumer>
                </Styled.TimerHeader>
                
                <FlexDiv direction="column" flex={1}>
                    {
                    selectedTimer.active &&
                    <RunningTimerChunkItem
                        timer={selectedTimer}
                        onAddNote={addTimerNote(selectedTimer!)}
                    />
                    }
                    <div style={{ flex: '1 1 auto' }}>
                        <AutoSizer>
                            {({ width, height }) => {
                                return (
                                <Styled.TimerChunkList>    
                                    <List
                                        height={height}
                                        rowHeight={80}
                                        rowCount={sortedChunks.length}
                                        width={width}
                                        onScroll={() => setUpdateChunkProps(true)}
                                        rowRenderer={({style, key, index}) => {
                                            return (
                                                <div key={sortedChunks[index].id}>
                                                    <TimerChunkItem
                                                        timeEntryOpen={() => 
                                                            openTimeEntry(
                                                                sortedChunks[index].timeEntryId!,
                                                                selectedTimer.id || 0
                                                            )
                                                        }
                                                        segmentChange={chunkEdit(index)}
                                                        toggleSelect={(evt) => {toggleSelect(evt, sortedChunks[index].id!)}}
                                                        selected={selectedChunks.includes(sortedChunks[index].id!)}
                                                        onDelete={() => deleteChunk(sortedChunks[index])}
                                                        onEdit={editChunk(sortedChunks[index])}
                                                        segment={sortedChunks[index]!}
                                                        key={sortedChunks[index].id}
                                                        style={style}
                                                        updateSegmentNarrative={updateSegmentNarrative}
                                                        clearSelections={clearSelections}
                                                        shouldUpdate={shouldUpdateChunkProps}
                                                        setChunkPropsUpdate={setUpdateChunkProps}
                                                    />
                                                </div>
                                            );
                                        }}
                                    />
                                </Styled.TimerChunkList>
                                );
                            }}
                        </AutoSizer>
                    </div>
                    
                </FlexDiv>
                <FabContainer onScreen={selectedChunks.length > 0 || dirty}>
                    {dirty &&
                        <>
                            <Button
                                onClick={saveTimer}
                                variant="extendedFab"
                                aria-label={t('selected_timer.fab_container.action.save')}
                            >
                                <Save />
                                {t('selected_timer.fab_container.action.save.label')}
                            </Button>
                            <Button
                                onClick={revert}
                                variant="extendedFab"
                                color="secondary"
                                aria-label={t('selected_timer.fab_container.action.revert')}
                            >
                                <Undo />
                                {t('selected_timer.fab_container.action.revert')}
                            </Button>
                        </>
                    }
                    
                    {(selectedChunks.length > 0) &&
                        <>
                            <Button
                                onClick={clearSelections}
                                variant="extendedFab"
                                color="secondary"
                                aria-label={t('clear', { ns: 'common' })}
                            >
                                {/*<Undo />*/}
                                {t('selected_timer.fab_container.action.deselect')} ({selectedChunks.length})
                            </Button>
                            <Button
                                onClick={entryFromSelectedChunks}
                                variant="extendedFab"
                                aria-label={t('selected_timer.fab_container.action.create_timeentry')}
                            >
                                <Check />
                                {t('selected_timer.fab_container.action.create_timeentry')} ({selectedChunks.length})
                            </Button>
                            <Button
                                onClick={deleteSelectedChunks}
                                variant="extendedFab"
                                aria-label={t('delete', { ns: 'common' })}
                            >
                                <Delete />
                                {t('delete', { ns: 'common' })} ({selectedChunks.length})
                            </Button>
                        </>
                    }
                </FabContainer>
            </>
            }
            </TKConsumer>
        );
    }
}

export default withTranslation(['timers', 'common'])(TimerFormContainer);