import XLSX from 'xlsx';
import ImmutableTimeEntry, { SapStatus } from '../api/immutables/ImmutableTimeEntry';
import { parseBillable, parseCode, parseWorkLocale } from './utils';
import { Platform } from './Platform';
import { DateTime } from 'luxon';
import rootStore from 'store/root.store';
import { ApprovalStatus } from '../api/types/types';

export const FileSaver = require('file-saver');

export class TimeEntriesExcelFormat {
    WorkDate: Date;
    Client: string;
    Matter: string;
    Office: string;
    WorkLocale?: string;
    Phase: string;
    Task: string;
    Activity: string;
    FFTask?: string;
    FFActivity?: string;
    ActionCode?: string;
    Hours: number;
    Narrative: string;
    Status: string;
    Billable?: string;
    ApprovalStatus?: string;
    RejectionCode?: string;
    TimeKeeper?: number;
    TimeKeeperName?: string | null;

    // tslint:disable-next-line:no-any
    constructor(entry: ImmutableTimeEntry, isMgmtDashboard: boolean, t: any) {
        const timeZoneOffset = new Date().getTimezoneOffset(); // minutes
        const utcWorkDate = DateTime
            .fromISO(entry.workDateTime)
            .startOf('day').plus({minutes: timeZoneOffset}).toISO();
        const ffCodeEnabled = rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled;
        const actionCodeEnabled = rootStore.appStore.features.EpochConfigActionCodesRequired;
        const isWorkLocaleEnabled = rootStore.appStore.features.EpochConfigWorkLocaleEnabled;
        const isProject = rootStore.appStore.isProject;
        const isApprovalEnabled = rootStore.appStore.isApprovalEnabled;

        this.WorkDate = new Date(utcWorkDate);
        this.Client = parseCode(entry.clientNumber, entry.clientName);
        this.Matter = parseCode(entry.matterNumber, entry.matterName);
        this.Office = parseCode(entry.office, entry.officeName) || '';
        if (isWorkLocaleEnabled) {
            this.WorkLocale = parseWorkLocale(rootStore.timeEntryStore.allWorkLocales, entry.workLocaleId);
        }
        this.Phase = parseCode(entry.phaseName, entry.phaseDesc);
        this.Task = parseCode(entry.taskCode, entry.taskCodeDesc);
        this.Activity = parseCode(entry.actCode, entry.actCodeDesc);
        this.Hours = Number((entry.duration / 3600).toFixed(2));
        this.Narrative = entry.narrative || '';

        let status: string = '';
        switch (entry.sapStatus) {
            case SapStatus.QUEUED:
                status = t('export.excel.column.status.posted');
                break;
            case SapStatus.SUBMITTED:
                status = t('export.excel.column.status.posted');
                break;
            case SapStatus.UNSUBMITTED:
                status = t('export.excel.column.status.draft');
                break;
            default:
                status = '';
        }
        this.Status = status;

        if (ffCodeEnabled) {
            this.FFTask = parseCode(entry.ffTaskCode, entry.ffTaskCodeDesc);
            this.FFActivity = parseCode(entry.ffActCode, entry.ffActCodeDesc);
        }
        if (actionCodeEnabled) {
            this.ActionCode = entry.actionCode || '';
        }

        if (isProject) {
            this.Billable = parseBillable(entry.billable);
        }
        if (isApprovalEnabled) {
            let approvalStatus: string = '';
            switch (entry.approvalStatus) {
                case ApprovalStatus.UNREVIEWED:
                    approvalStatus = t('export.excel.column.approval_status.unreviewed');
                    break;
                case ApprovalStatus.APPROVED:
                    approvalStatus = t('export.excel.column.approval_status.approved');
                    break;
                case ApprovalStatus.REJECTED:
                    approvalStatus = t('export.excel.column.approval_status.rejected');
                    break;
                default:
                    approvalStatus = '';
            }
            this.ApprovalStatus = approvalStatus;
            this.RejectionCode = entry.rejectionCode == null ? '' : parseCode(entry.rejectionCode, entry.rejectionDesc);
        }
        if (isMgmtDashboard) {
            this.TimeKeeper = entry.timeKeeperId;
            this.TimeKeeperName = entry.timeKeeperName;
        }
    }
}

// tslint:disable-next-line:no-any
export function exportToXLSX(data: TimeEntriesExcelFormat[], fileName: string, t: any, isMgmtDashboard: boolean) {
    /* Create Worksheet */
    let ws = XLSX.utils.json_to_sheet(data);
    
    /* Set Header for matter/job column from feature flag */
    const matterHeaderLabel = rootStore.appStore.features.EpochConfigMatterLabel;
    ws.C1 = { t: 's', v: matterHeaderLabel };

    /* Add to WorkBook */
    let wb = XLSX.utils.book_new();
    
    XLSX.utils.book_append_sheet(wb, ws, 'Time Entries');

    XLSX.utils.sheet_add_aoa(ws, [getColumnsForExcel(t, isMgmtDashboard)], { origin: 'A1' });
    
    // /* write */
    let wBout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'binary'
    });
    /* Generate download */
    function download(s: string) {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);

        for (let i = 0; i !== s.length; ++i) {
            // tslint:disable-next-line:no-bitwise
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }
    
    if (Platform.isElectron()) {
        const { dialog } = require('electron').remote;

        let customSaveAsDialog = dialog.showSaveDialog({
            title: 'Save file as',
            defaultPath: fileName,
            filters: [{
                name: 'xlsx',
                extensions: ['xlsx']
            }]
        });
        if (customSaveAsDialog) {
            XLSX.writeFile(wb, customSaveAsDialog);
        };
    } else {
        
        FileSaver.saveAs(new Blob(
            [download(wBout)],
            {
                type: 'application/octet-stream'
            }),
            `${fileName}.xlsx`
        );
    }
}

// tslint:disable-next-line:no-any
export function toExcelFormat(entries: ImmutableTimeEntry[], isMgmtDashboard: boolean, t: any): TimeEntriesExcelFormat[] {
    
    let excelEntries: TimeEntriesExcelFormat[] = [];
    entries.forEach((entry) => {
        const excelFormattedEntry = new TimeEntriesExcelFormat(entry, isMgmtDashboard, t);
        excelEntries.push(excelFormattedEntry);
    });
    return excelEntries;
}

// tslint:disable-next-line:no-any
function getColumnsForExcel(t: any, isMgmtDashboard: boolean) {
    // tslint:disable-next-line:no-any
    let header: any[] = [];
    const ffCodeEnabled = rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled;
    const actionCodeEnabled = rootStore.appStore.features.EpochConfigActionCodesRequired;
    const isWorkLocaleEnabled = rootStore.appStore.features.EpochConfigWorkLocaleEnabled;
    const isProject = rootStore.appStore.isProject;
    const isApprovalEnabled = rootStore.appStore.isApprovalEnabled;
    const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
    const translationKey = 'export.excel.header';

    header.push(
        t(`${translationKey}.date`),
        t(`${translationKey}.client`),
        t(`${translationKey}.matter`, { matterLabel }),
        t(`${translationKey}.office`),
        t(`${translationKey}.phase`),
        t(`${translationKey}.task`),
        t(`${translationKey}.activity`),
        t(`${translationKey}.hours`),
        t(`${translationKey}.narrative`),
        t(`${translationKey}.status`)
    );

    if (isWorkLocaleEnabled) {
        header.splice(4, 0, t(`${translationKey}.work_location`));
    }

    if (ffCodeEnabled) {
        header.push(
            t(`${translationKey}.ff_task`),
            t(`${translationKey}.ff_activity`)
        );
    }
    if (actionCodeEnabled) {
        header.push(
            t(`${translationKey}.action_code`)
        );
    }
    if (isProject) {
        header.push(
            t(`${translationKey}.billable`)
        );
    }
    if (isApprovalEnabled) {
        header.push(
            t(`${translationKey}.approval_status`),
            t(`${translationKey}.rejection_code`)
        );
    }
    if (isMgmtDashboard) {
        header.push(
            t(`${translationKey}.timekeeper`),
            t(`${translationKey}.timekeeper_name`)
        );
    }

    return header;
}
