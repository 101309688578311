import * as React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select
} from '@material-ui/core';
import { RootStoreContext } from '../../App';
import { RejectionCode } from 'api/types/types';

const RejectionCodeDialog = () => {
    const rootStore = React.useContext(RootStoreContext);
    const { t } = useTranslation([ 'management_dashboard', 'common' ]);
    const {
        rejectionCodes,
        selectedCode,
        isOpen,
        setSelectedCode,
        resolveAndClose
    } = rootStore.rejectionCodeDialogStore;

    return (
        <Dialog
            open={isOpen}
            maxWidth="md"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <DialogTitle>
                {t('dialog.reject.title')}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px'}}>
                <FormControl fullWidth={true}>
                    <InputLabel>{t('dialog.reject.select.label')}</InputLabel>
                    <Select
                        input={<Input name={'role'} id={'select-rejection-code-label-placeholder'}/>}
                        displayEmpty={true}
                        name={'select-rejection-code'}
                        value={selectedCode}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedCode(e.target.value)}
                    >
                        {(rejectionCodes || []).map((c: RejectionCode) =>
                            <MenuItem key={c.id} value={c.code}>{`${c.code} - ${c.description}`}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => resolveAndClose(selectedCode)}>{t('confirm', { ns: 'common' })}</Button>
                <Button onClick={() => resolveAndClose('')}>{t('cancel', { ns: 'common' })}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default observer(RejectionCodeDialog);