import * as React from 'react';
import * as Styled from './styled';
import { Paper, List, Input, IconButton, Snackbar, Tooltip } from '@material-ui/core';
import { Add, Delete, Search } from '@material-ui/icons';
import TemplateStore from 'store/template.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { Route } from 'react-router';
import TemplateFormContainer from './TemplateFormContainer';
import Template from 'api/immutables/ImmutableTemplate';
import { TimeKeeperAssignment } from '../../api/types/types';
import { TKConsumer } from 'common/TKProvider';
import { useTranslation, withTranslation } from 'react-i18next';
interface Props {
    rootStore?: RootStore;
    templateStore?: TemplateStore;
    match: {
        url: string;
    };
    // tslint:disable-next-line:no-any
    t: any;
}

const ListItemLink: React.FC<{
    primary: string, 
    onClick: () => void,
    selected?: boolean,
    onDelete: () => void,
}> = ({ primary, onClick, selected, onDelete }) => {
    const { t } = useTranslation(['common']);
    return (
        <Styled.CustomListItem
            button={true}
            onClick={onClick}
            selected={selected}
        >
            <Styled.ListItemContent>
                <Styled.ListText
                    title={primary}
                    primary={primary}
                    primaryTypographyProps={{
                        style: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 40px)'
                        }
                    }}
                />
                <TKConsumer>
                    { (tk: TimeKeeperAssignment) =>
                        tk.writable &&
                        <Styled.CustomSecondaryAction>
                            <Tooltip title={t('delete', { ns: 'common' })}>
                                <IconButton onClick={onDelete}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Styled.CustomSecondaryAction>}
                </TKConsumer>
            </Styled.ListItemContent>
        </Styled.CustomListItem>
    );
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        rootStore: rootStore,
        templateStore: rootStore.templateStore
    };
})
@observer
class Templates extends React.Component<Props> {
    
    componentDidMount() {
        const { loadTemplates, newTemplate, selectedTemplate } = this.props.templateStore!;
        loadTemplates();
        // condition to prevent push new to url when refreshing page with a selected template
        if (selectedTemplate || location.hash === '#/templates') {
            newTemplate();
        }
    }

    componentWillUnmount() {
        this.props.templateStore!.onSearchChange('');
    }
    
    render() {
        const {
            filteredNames, 
            selectedTemplate, 
            changeSelectedTemplate,
            onSearchChange,
            deleteTemplate,
            newTemplate,
            searchText
        } = this.props.templateStore!;
        const { match, t } = this.props;
        return (
            <Styled.Container>
                <Styled.Header>
                    <Styled.SearchContainer>
                        <Search
                            style={{ marginRight: '6px' }}
                        />
                        <Input
                            placeholder={t('header.field.search.placeholder')}
                            fullWidth={true}
                            style={{ maxWidth: '60%' }}
                            value={searchText}
                            onChange={e => onSearchChange(e.target.value)}
                        />
                    </Styled.SearchContainer>

                    <Styled.Title variant="h6">
                        {t('header.title')}
                    </Styled.Title>
                    <TKConsumer>
                        { (tk: TimeKeeperAssignment) =>
                            <div style={{ flex: '1' }}>
                                {  tk.writable &&
                                <Tooltip title={t('header.action.add')}>
                                    <Styled.New onClick={_ => { newTemplate() }}>
                                        <Add />
                                    </Styled.New>
                                </Tooltip>
                                }
                            </div>}
                    </TKConsumer>
                </Styled.Header>

                <Styled.ContentContainer>
                    <Styled.ListContainer square={true}>
                        <List>
                            {filteredNames.map((tmp: Template) => {
                                return (
                                    <ListItemLink
                                        key={tmp.id!.toString()}
                                        primary={tmp.name}
                                        onClick={() => changeSelectedTemplate(tmp.id)}
                                        selected={selectedTemplate && selectedTemplate.id === tmp.id}
                                        onDelete={() => deleteTemplate(tmp.id)}
                                    />
                                );
                            })}
                        </List>
                    </Styled.ListContainer>
                    <Styled.FormContainer>
                        <Paper square={true} style={{ padding: '10px' }}>
                            <Route 
                                path={`${match.url}/:id`} 
                                component={TemplateFormContainer}
                            />
                        </Paper>
                    </Styled.FormContainer>
                </Styled.ContentContainer>
            </Styled.Container>
        );
    }
}

export default withTranslation(['templates', 'common'])(Templates);