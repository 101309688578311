import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import * as Styled from './styled';
import { parseCode } from 'util/utils';
import {
    setMatter,
    setNarrative,
    setWorkLocale,
    setPhase,
    setTask,
    setAct,
    setFFTask,
    setFFAct,
    setActionCode,
    setCodeSetTemplate,
    changeNarrativeLanguage,
    fetchPhaseCodes,
    fetchTaskCodes,
    fetchActivityCodes,
    fetchFFTaskCodes,
    fetchFFActCodes,
    fetchMatters,
    getMatterTooltipText,
    getClientText,
    getWorkLocaleValue
} from 'util/timeEntry';
import RootAPI from 'api/interfaces/RootAPI';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import { ValidationState } from 'api/immutables/validators';
import {
    ActionCode,
    Code,
    CodeSetTemplate,
    Features,
    Matter,
    TimeKeeperAssignment,
    WorkLocale
} from 'api/types/types';
import { RootStoreContext } from '../../App';
import NarrativeField from 'components/NarrativeField';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import { MatterItemFormatter } from 'components/TemplateForm/TemplateForm';
import { LinearProgressBar } from 'components/LoadingSpinner/LinearProgressBar';
import { ApiConsumer } from 'common/ApiProvider';
import { TKConsumer } from 'common/TKProvider';
import { FlexDiv } from 'common/flex';
import { FeaturesConsumer } from 'common/FeaturesProvider';

interface Props {
    timeEntry: TimeEntry;
    onChange: (t: TimeEntry, newVState?: ValidationState) => void;
    setTempWorkLocaleId: (id: number) => void;
    disabled?: boolean;
    minHeight?: number;
    validationState?: ValidationState;
    minNarrativeLength: number;
    maxNarrativeLength: number;
    actionCodesRequired: boolean;
    onSetFieldLoader?: (value: boolean) => void;
    expand: boolean;
    onExpand: (expanded: boolean) => void;
    isNewGroup: boolean;
}

function GridTimeEntry(props: Props) {
    const {
        timeEntry,
        disabled,
        expand,
        validationState,
        minHeight,
        actionCodesRequired,
        onChange,
        onExpand,
        setTempWorkLocaleId
    } = props;
    const rootStore = useContext(RootStoreContext);
    const { t } = useTranslation(['timeentries']);
    const matterRef = useRef(null);
    const [fieldLoader, setFieldLoader] = useState(false);
    const [ expanded, setExpanded ] = useState(expand);

    useEffect(() => {
        if (props.isNewGroup) {
            let elem = ReactDOM.findDOMNode(matterRef.current) as HTMLElement;
            elem.getElementsByTagName('input')[0].focus();
        }
    }, []);

    useEffect(() => {
        setExpanded(expand);
    }, [expand])

    const isProject = rootStore.appStore.isProject;
    const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
    const client = getClientText(timeEntry);
    let conflictingBannedWords = [] as string[];
    let conflictingBlockBillingWords = [] as string[];
    let matterStatusErrText: string;
    if (validationState) {
        conflictingBannedWords = validationState.narrativeBannedWords;
        conflictingBlockBillingWords = validationState.narrativeBlockBillingWords;
    }
    let bwText = (conflictingBannedWords.length > 0) ?
    `${conflictingBannedWords.join(', ')}` : '';
    let bbText = (conflictingBlockBillingWords.length > 0)
    ? `${conflictingBlockBillingWords.join(', ')}` : '';
    let narBwBbWords = bwText ? `${bwText}` + (bbText && ', ') + `${bbText}` : `${bbText}`
    let narHelperText = (bwText || bbText) && 
        `${t('form.validation.narrative.banned_or_block_billing_words')}: ${narBwBbWords}`;
    let narrativeErrored = (validationState && validationState.narrativeLength) ||
    (validationState && validationState.maxNarrativeLength) ||
    ((conflictingBannedWords.length + conflictingBlockBillingWords.length) > 0);
    if (validationState && validationState.narrativeLength) {
        const minLength = validationState.narrativeMinLength;
        narHelperText = t('form.validation.narrative.length.min', { minLength });
    }
    if (validationState && validationState.matterStatusInvalid) {
        matterStatusErrText = `${t('form.validation.matter.status', { matterLabel })}: ${timeEntry.matterStatusDesc}`;
    }
    if (validationState && validationState.matterEntryTypeInvalid) {
        matterStatusErrText = t('form.validation.matter.type_cost', { matterLabel });
    }
    if (validationState && validationState.maxNarrativeLength) {
        const maxLength = validationState.narrativeMaxLength;
        narHelperText = t('form.validation.narrative.length.max', { maxLength });
    }

    const _setMatter = async (m: Matter | null) => {
        setFieldLoader(true);
        setExpanded(true);
        await setMatter(m, timeEntry, onChange, validationState, true);
        setFieldLoader(false);
        onExpand(false);
    }

    const _setWorkLocale = async (w: WorkLocale | null) => {
        await setWorkLocale(w, timeEntry, onChange, validationState);
        if (w) {
            setTempWorkLocaleId(w.id);
        }
    }

    const handleExpand = () => {
        onExpand(expanded);
        setExpanded(!expanded);
    }

    const handleBillableChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let entry = timeEntry.setBillable(event.target.value === 'true');
        onChange(entry);
    }
    
    return (
        <FeaturesConsumer>
            { (features: Features) =>
            <ApiConsumer>
                { (api: RootAPI) =>
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
                    <FlexDiv direction="column">
                        <Styled.FieldContainer minHeight={minHeight}>
                            <Styled.FieldsMain>
                                {features.EpochConfigTimeEntriesMattersRequired && <div ref={matterRef}>
                                    <AutoCompleteField
                                        label={t('field.matter', { matterLabel })}
                                        errorMessage={t('form.validation.matter.invalid', { matterLabel })}
                                        errored={expanded && validationState ? validationState.missing.matter : false}
                                        fetch={(searchText: string) => fetchMatters(timeEntry, searchText)}
                                        currentItem={timeEntry.matter}
                                        clearable={true}
                                        onClear={() => _setMatter(null)}
                                        formatItem={MatterItemFormatter}
                                        getItemText={(m: Matter) => parseCode(m.number, m.name)}
                                        onSelect={_setMatter}
                                        tooltip={getMatterTooltipText}
                                        disabled={disabled}
                                    />
                                    {fieldLoader && <LinearProgressBar color={'primary'} progressBar={50}/>}
                                </div>}
                                <Tooltip title={timeEntry.narrative}>
                                    <Styled.NarrativeFieldContainer>
                                        <NarrativeField
                                            showCharCount={false}
                                            style={{
                                                maxHeight: '48px',
                                                overflow: 'auto'
                                            }}
                                            helperText={expanded && narHelperText.length > 0 ? narHelperText : undefined}
                                            error={expanded && narrativeErrored}
                                            value={timeEntry.narrative}
                                            placeholder={timeEntry.billingLangText ?
                                                `${t('form.field.narrative.label')} (${timeEntry.billingLangText})`
                                                :
                                                t('form.field.narrative.label')
                                            }
                                            onChange={(text: string) => setNarrative(text, timeEntry, onChange, validationState)}
                                            maxLength={features.EpochConfigNarrativesMaximumChars}
                                            dictionaryKey={timeEntry.matterBillingLangDictionary}
                                            changeLanguage={(key: string) => changeNarrativeLanguage(key, timeEntry, onChange)}
                                            disabled={disabled}
                                        />
                                    </Styled.NarrativeFieldContainer>
                                </Tooltip>
                                <Tooltip title={t(`${expanded ? 'collapse' : 'expand'}`, { ns: 'common' })}>
                                    <IconButton onClick={handleExpand} style={{ height: '48px' }}>
                                        {expanded ? <KeyboardArrowUp/> : <KeyboardArrowDown />}
                                    </IconButton>
                                </Tooltip>
                            </Styled.FieldsMain>
                            {expanded && <Styled.Fields>
                                <TextField
                                    value={client}
                                    title={client}
                                    label={t('field.client')}
                                    disabled={true}
                                />
                                {features.EpochConfigWorkLocaleEnabled &&
                                    <AutoCompleteField
                                        label={t('form.field.work_location.label')}
                                        fetch={(search: string) => api.TimeEntry.searchWorkLocales(search)}
                                        currentItem={timeEntry.workLocaleId ? getWorkLocaleValue(timeEntry) : null}
                                        getItemText={(w: WorkLocale) => parseCode(w.localeSearch, null)}
                                        clearable={true}
                                        onClear={() => _setWorkLocale(null)}
                                        onSelect={_setWorkLocale}
                                        disabled={disabled || !tk.writable}
                                        errorMessage={t('form.validation.work_location')}
                                        errored={validationState ? validationState.missing.workLocale : false}
                                    />
                                }
                                {(timeEntry.matter && !timeEntry.isPosted()) &&
                                    features.EpochConfigCodeSetTemplatesEnabled &&
                                    <AutoCompleteField
                                        label={t('form.field.code_template.label')}
                                        fetch={(searchText) => api.CodeSet.getCodeSets(timeEntry.matterId!, searchText, 0, 200)}
                                        currentItem={timeEntry.selectedCodeSetTemplate}
                                        clearable={true}
                                        disabled={timeEntry.isPosted() || disabled || !tk.writable}
                                        onClear={() => setCodeSetTemplate(null, timeEntry, onChange, validationState)}
                                        getItemText={(cs: CodeSetTemplate) => parseCode(cs.codeSetName, cs.codeSetRef)}
                                        onSelect={(c: CodeSetTemplate) => setCodeSetTemplate(c, timeEntry, onChange, validationState)}
                                    />
                                }
                                {/** TODO figure out when to display phase and tasks sets */}
                                {<>
                                    {timeEntry.isPhaseCode && <>
                                        <AutoCompleteField
                                            errorMessage={t('form.validation.phase')}
                                            errored={validationState ? validationState.missing.phase : false}
                                            label={t('form.field.phase.label')}
                                            currentItem={timeEntry.phase}
                                            clearable={true}
                                            disabled={timeEntry.matterId === null || disabled || timeEntry.isPosted() || !tk.writable}
                                            onClear={() => setPhase(null, timeEntry, onChange, validationState)}
                                            onSelect={(c: Code) => setPhase(c, timeEntry, onChange, validationState)}
                                            fetch={(searchText) => fetchPhaseCodes(timeEntry, searchText)}
                                            getItemText={(c: Code) => parseCode(c.name, c.description)}
                                        />
                                        <AutoCompleteField
                                            errorMessage={t('form.validation.task')}
                                            errored={validationState ? validationState.missing.task : false}
                                            label={t('form.field.task.label')}
                                            currentItem={timeEntry.task}
                                            clearable={true}
                                            onSelect={(c: Code) => setTask(c, timeEntry, onChange, validationState)}
                                            onClear={() => setTask(null, timeEntry, onChange, validationState)}
                                            fetch={(searchText) => fetchTaskCodes(timeEntry, searchText)}
                                            disabled={timeEntry.phase === null || disabled || timeEntry.isPosted() || !tk.writable}
                                            getItemText={(c: Code) => parseCode(c.name, c.description)}
                                        />
                                        {isProject &&
                                            <FormControl
                                                fullWidth={true}
                                                disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                            >
                                                <InputLabel shrink={typeof timeEntry.billable === 'boolean'}>{t('form.field.billable.label')}</InputLabel>
                                                <Select
                                                    displayEmpty={true}
                                                    name={'select-billable'}
                                                    value={timeEntry.billable !== null ? timeEntry.billable : undefined}
                                                    onChange={handleBillableChange}
                                                >
                                                    <MenuItem value={'false'}>{t('form.field.billable.option.no')}</MenuItem>
                                                    <MenuItem value={'true'}>{t('form.field.billable.option.yes')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        }
                                    </>}
                                    {timeEntry.isActCode &&
                                    <AutoCompleteField
                                        errorMessage={t('form.validation.activity')}
                                        errored={validationState ? validationState.missing.activity : false}
                                        label={t('form.field.activity.label')}
                                        currentItem={timeEntry.activity}
                                        clearable={true}
                                        onClear={() => setAct(null, timeEntry, onChange, validationState)}
                                        onSelect={(c: Code) => setAct(c, timeEntry, onChange, validationState)}
                                        fetch={(searchText) => fetchActivityCodes(timeEntry, searchText)}
                                        disabled={!timeEntry.matterId || disabled || timeEntry.isPosted() || !tk.writable}
                                        getItemText={(c: Code) => parseCode(c.name, c.description)}
                                    />
                                    }
                                </>}
                                {
                                    features.EpochConfigFlatFeeCodesEnabled && timeEntry.isFfTaskCode &&
                                    <>
                                        <AutoCompleteField
                                            errorMessage={t('form.validation.ff_task')}
                                            errored={validationState ? validationState.missing.ffTask : false}
                                            label={t('form.field.ff_task.label')}
                                            currentItem={timeEntry.ffTask}
                                            clearable={true}
                                            onClear={() => setFFTask(null, timeEntry, onChange, validationState)}
                                            onSelect={(c: Code) => setFFTask(c, timeEntry, onChange, validationState)}
                                            fetch={(searchText) => fetchFFTaskCodes(timeEntry, searchText)}
                                            getItemText={(c: Code) => parseCode(c.name, c.description)}
                                            disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                        />
                                        <AutoCompleteField
                                            errorMessage={t('form.validation.ff_activity')}
                                            errored={validationState ? validationState.missing.ffAct : false}
                                            label={t('form.field.ff_activity.label')}
                                            currentItem={timeEntry.ffActivity}
                                            disabled={!timeEntry.ffTaskCodeId || disabled || timeEntry.isPosted() || !tk.writable}
                                            clearable={true}
                                            onClear={() => setFFAct(null, timeEntry, onChange, validationState)}
                                            onSelect={(c: Code) => setFFAct(c, timeEntry, onChange, validationState)}
                                            fetch={(searchText) => fetchFFActCodes(timeEntry, searchText)}
                                            getItemText={(c: Code) => parseCode(c.name, c.description)}
                                        />
                                    </>}
                                {actionCodesRequired &&
                                    <AutoCompleteField
                                        errorMessage={t('form.validation.action')}
                                        errored={validationState ? validationState.missing.action : false}
                                        label={t('form.field.action_code.label')}
                                        currentItem={timeEntry.actionCodeObj}
                                        disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                        clearable={true}
                                        onClear={() => setActionCode(null, timeEntry, onChange, validationState)}
                                        onSelect={(c: ActionCode) => setActionCode(c, timeEntry, onChange, validationState)}
                                        fetch={(searchText) => api.Code.getActionCodes(timeEntry.matterId!, searchText)}
                                        getItemText={(code: ActionCode) =>
                                                `${code.actionCode}${code.actionText ? `
                                                    - ${code.actionText}` : ''}`}
                                    />
                                }
                            </Styled.Fields>}
                        </Styled.FieldContainer>
                        <FlexDiv flex={1} />
                        <Styled.FieldContainer
                            minHeight={minHeight}
                            style={{display: 'grid', alignItems: 'end'}}
                        >
                            {timeEntry.actionResponse &&
                                <FormHelperText error={true}>
                                    {timeEntry.actionResponse}
                                </FormHelperText>
                            }
                            {matterStatusErrText &&
                                <FormHelperText error={true}>
                                    {matterStatusErrText}
                                </FormHelperText>
                            }
                        </Styled.FieldContainer>
                    </FlexDiv>}
            </TKConsumer>}
                </ApiConsumer>
            }
        </FeaturesConsumer>
    );
}

export default GridTimeEntry;