import ManagementDashboardAPI from 'api/interfaces/ManagementDashboardAPI';
import BaseWebImplementation from './Base.impl';
import { DateTime } from 'luxon';
import ImmutableTimeEntry from 'api/immutables/ImmutableTimeEntry';
import { Role } from 'api/types/types';

export default class ManagementDashboardImpl extends BaseWebImplementation implements ManagementDashboardAPI {
    async getRoles() {
        return await this.root.webImpl.ManagementDashboard.getRoles();
    }

    async getEntries(
        fromDate: DateTime, toDate: DateTime, role: Role, approverTkId: number, tkId?: number, approvalStatus?: string, billable?: boolean, sapStatus?: string,
        offset?: number, limit?: number, matterId?: number, clientId?: number, actCodeId?: number, phaseId?: number, taskId?: number
    ) {
        return await this.root.webImpl.ManagementDashboard.getEntries(
            fromDate,
            toDate,
            role,
            approverTkId,
            tkId,
            approvalStatus,
            billable,
            sapStatus,
            offset,
            limit,
            matterId,
            clientId,
            actCodeId,
            phaseId,
            taskId
        );
    }

    async getDelegators(role: Role) {
        return await this.root.webImpl.ManagementDashboard.getDelegators(role);
    }

    async getEmployees(role: Role, approverTkId: number, search?: string, offset?: number, limit?: number) {
        return await this.root.webImpl.ManagementDashboard.getEmployees(
            role,
            approverTkId,
            search,
            offset,
            limit
        );
    }

    async postEntries(role: Role, approverTkId: number, ids: number[]) {
        return await this.root.webImpl.ManagementDashboard.postEntries(
            role,
            approverTkId,
            ids
        );
    }

    async approveEntries(role: Role, approverTkId: number, ids: number[]) {
        return await this.root.webImpl.ManagementDashboard.approveEntries(
            role,
            approverTkId,
            ids
        );
    }

    async rejectEntries(role: Role, rejectorTkId: number, ids: number[], rejectionCodeId: string) {
        return await this.root.webImpl.ManagementDashboard.rejectEntries(
            role,
            rejectorTkId,
            ids,
            rejectionCodeId
        );
    }

    async getRejectionCodes(offset?: number, limit?: number) {
        return await this.root.webImpl.ManagementDashboard.getRejectionCodes(offset, limit);
    }

    // // Delegation Page APIs
    // async getManagerDelegation() {
    //     return await this.root.webImpl.ManagementDashboard.getManagerDelegation();
    // }

    // async addManagerDelegation(delegator: any) {
    //     return await this.root.webImpl.ManagementDashboard.addManagerDelegation(delegator);
    // }
}