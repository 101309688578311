import * as React from 'react';
import { useState, useReducer, useEffect, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import * as Styled from './styled';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Features } from '../../api/types/types';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { EntriesGroup } from 'store/gridView.store';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import GridTimeEntry from './GridTimeEntry';
import { ValidationState } from 'api/immutables/validators';
import { parseDuration } from 'util/date';
import { RootStoreContext } from '../../App';

interface Props {
    group: EntriesGroup;
    validationState?: ValidationState;
    validationStateDurations?: string[];
    expandAll: boolean;
}

interface State {
    Mon: string;
    Tue: string;
    Wed: string;
    Thu: string;
    Fri: string;
    Sat: string;
    Sun: string;
}

function GridItem(props: Props) {
    const componentRef = useRef(null);
    const { t } = useTranslation(['timeentries', 'common']);
    const { group, validationState, validationStateDurations, expandAll } = props;
    const { id, sampleEntry, ...obj } = group;
    const rootStore = useContext(RootStoreContext);
    const {
        weekDays,
        utcStartDate,
        totalPerDayMap,
        onChange,
        deleteGroup,
        setTotalPerDay,
        setValidationStateDurations,
        setTempWorkLocaleId
    } = rootStore.gridViewStore;
    // state for invalid time format for week, if error day present in array
    const [durError, setDurError] = useState<string[]>([]);
    const initialDuration: State = { Mon: '', Tue: '', Wed: '', Thu: '', Fri: '', Sat: '', Sun: '' };
    const [state, updateState] = useReducer(
        (prevState, updates) => ({ ...prevState, ...updates }),
        initialDuration
    );

    useEffect(() => {
        updateState({ ...getDurations() });
    }, [group]);

    const getDurations = () => {
        const durations = initialDuration;
        Object.keys(obj).forEach(key => {
            durations[key] = (obj[key].duration / (60 * 60)).toString();
        });
        return durations;
    }

    const setDayDuration = (day: string, duration: string) => {
        let entry: TimeEntry = group[day];
        let error = durError.filter(d => d !== day);
        setDurError(error);
        setValidationStateDurations(group.id, day);
        if (!entry && duration === '') {
            return;
        }
        if (duration === '') {
            // delete entry if duration cleared
            entry = entry.setDuration(0);
            entry.deleted = true;
        } else {
            try {
                let dur: number = parseDuration(duration.trim());
                dur = dur < 0 ? 0 : dur;
                if (!entry) {
                    entry = group.sampleEntry.clone();
                    const date: DateTime = utcStartDate.plus({ 'day': weekDays.indexOf(day) });
                    entry.workDateTime = date.toISO();
                }
                entry = entry.setDuration(dur * 60);
                entry.deleted = false;
                updateState({ [day]: (entry.duration / (60 * 60)).toString() });
            } catch {
                error.push(day);
                setDurError(error);
                return;
            }
        }
        let newVState;
        if (props.validationState) {
            newVState = props.validationState!.clone();
            newVState.zeroDuration = false;
            newVState.twentyFourDuration = false;
        }
        onChange(group.id, entry, newVState, day);
        setTotalPerDay(day);
    }

    const onChangeDuration = (day: string, value: string) => {
        updateState({ [day]: value });
    }

    const onExpand = (expanded: boolean) => {
        if (!expanded) {
            setTimeout(() => {
                let elem: HTMLElement = ReactDOM.findDOMNode(componentRef.current) as HTMLElement;
                scrollIntoView(elem, { behavior: 'smooth', scrollMode: 'if-needed' });
            }, 0);
        }
    }

    const getStyle = (day: string) => {
        const totalPerDay = totalPerDayMap.get(day);
        // totalPerDay to highlight all durations in the errored column
        if (
            durError.includes(day) ||
            (totalPerDay && totalPerDay > 24) ||
            (validationStateDurations && validationStateDurations.includes(day))
        ) {
            return {
                'padding': '12px 2px',
                'border': '2px red solid',
                'border-radius': '5px'
            };
        }
        return { 'padding': '14px 2px' };
    }

    let durErrKey = '';
    if (validationStateDurations && validationStateDurations.length > 0) {
        durErrKey = 'validation.duration.zero';
    }
    if (durError.length > 0) {
        durErrKey = 'validation.duration.invalid';
    }

    const keys = Object.keys(obj);
    const isPosted: boolean = keys.length !== 0 && keys.some((key: string) => group[key].isPosted())
    const isAllPosted: boolean = keys.length !== 0 && keys.every((key: string) => group[key].isPosted());
    const isError: boolean = (durError.length > 0) || (validationState ? !validationState.valid : false);

    return (
        <FeaturesConsumer>
            {(features: Features) =>
                <Styled.ItemContainer
                    ref={componentRef}
                    elevation={1}
                    square={true}
                    color={isError ? 'red' : isAllPosted ? 'green' : 'orange'}
                >
                    <Styled.FormContainer>
                        <Styled.GridItemEntry>
                            <GridTimeEntry
                                timeEntry={sampleEntry}
                                validationState={validationState}
                                isNewGroup={Object.keys(obj).length === 0}
                                disabled={isPosted}
                                onChange={(entry, newVState) => onChange(id, entry, newVState)}
                                setTempWorkLocaleId={setTempWorkLocaleId}
                                expand={expandAll}
                                onSetFieldLoader={rootStore.homeStore.setFieldLoaderFn}
                                onExpand={onExpand}
                                actionCodesRequired={features.EpochConfigActionCodesRequired}
                                minNarrativeLength={rootStore.timeEntryDialogStore.minNarrativeLength}
                                maxNarrativeLength={rootStore.timeEntryDialogStore.maxNarrativeLength}
                            />
                        </Styled.GridItemEntry>
                        <Styled.GridItemDuration>
                            <Styled.Days>
                                {weekDays.map((d: string, i: number) => (
                                    <Styled.Day key={i}>
                                        <TextField
                                            variant="outlined"
                                            value={state[d]}
                                            disabled={group[d] && group[d].isPosted()}
                                            onChange={(e) => onChangeDuration(d, e.target.value)}
                                            onBlur={(e) => setDayDuration(d, e.target.value)}
                                            inputProps={{ style: getStyle(d) }}
                                        />
                                    </Styled.Day>
                                ))}
                            </Styled.Days>
                            {durErrKey &&
                                <Styled.Error>
                                    {t(durErrKey, { ns: 'timeentries' })}
                                </Styled.Error>
                            }
                        </Styled.GridItemDuration>
                        <Styled.GridItemAction disabled={false}>
                            <Tooltip title={t('delete', { ns: 'common' })}>
                                <IconButton
                                    disabled={isAllPosted}
                                    onClick={() => deleteGroup(group.id)}
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Styled.GridItemAction>
                    </Styled.FormContainer>
                </Styled.ItemContainer>
            }
        </FeaturesConsumer>
    );
}

export default GridItem;