import { action, observable, makeObservable, override, computed } from 'mobx';
import { DateTime } from 'luxon';
import { RootStore } from 'store/root.store';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import { Code, ApprovalStatus, Role, TimeKeeperAssignment, Delegator, RejectionCode } from '../api/types/types';
import TimeEntryStore, { MATTER_TYPE } from './timeentry.store';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import { ValidatePost, ValidationState } from 'api/immutables/validators';
import ImmutableTimeEntry from 'api/immutables/ImmutableTimeEntry';
import { ApiResult } from 'api/util';

const managementRoles: Role[] = [Role.ADMIN, Role.PROJMANAGER, Role.MANAGER];

export default class ManagementDashboardStore extends TimeEntryStore {
    @observable roles: Role[] = [];
    @observable selectedRole: Role;
    @observable delegator: Delegator | undefined | null;
    @observable phase: Code | undefined | null;
    @observable task: Code | undefined | null;
    @observable approvalStatus: string[] = [];
    @observable employee: TimeKeeperAssignment | undefined | null;

    constructor(root: RootStore) {
        super(root);
        makeObservable(this);
        this.initializeHandler();
    }

    @loadable()
    @action.bound
    async getManagmentRoles() {
        try {
            this.loading = true;
            const roles = await this.rootStore.api.ManagementDashboard.getRoles();
            this.roles = roles.filter((r: Role) => managementRoles.includes(r));
            if (!this.roles.includes(this.selectedRole)) {
                this.selectedRole = this.roles[0];
            }
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async getProjectEntries() {
        await this.loadManagementDashboardEntries(
            this.fromDate,
            this.untilDate,
            'SUBMITTED,QUEUED'
        );        
    }

    @action.bound
    async getDraftEntries() {
        await this.loadManagementDashboardEntries(
            this.fromDate,
            this.untilDate,
            'UNSUBMITTED'
        );
    }

    @loadable()
    @action.bound
    async loadManagementDashboardEntries(fromDate: DateTime, untilDate: DateTime, sapStatus: string) {
        try {
            this.selectedEntryIds = [];
            this.loading = true;
            this.validationState = new Map<number, ValidationState>();
            this.durVstate = new Map<number, boolean | undefined>();
            this.currentStart = fromDate.plus({});
            this.currentEnd = untilDate.plus({});
            this.serverTimeEntries = await this.rootStore.api.ManagementDashboard.getEntries(
                fromDate,
                untilDate,
                this.selectedRole,
                this.approvalTimekeeperId,
                this.employee ? this.employee.timeKeeperId : undefined,
                this.approvalStatus ? this.approvalStatus.join() : undefined,
                this.billable,
                sapStatus,
                undefined,
                undefined,
                this.matter ? this.matter.id : undefined,
                this.client ? this.client.id : undefined,
                undefined,
                this.phase ? this.phase.id : undefined,
                this.task ? this.task.id : undefined
            );
            this.localTimeEntries = this.serverTimeEntries.slice().map(e => e.clone());
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    }

    @loadable()
    @action.bound
    async postDraftEntries(ids: number[]) {
        const { api, appStore, snackbarStore } = this.rootStore;
        const toPost: number[] = [];
        await Promise.all(ids.map(async (idx) => {
            let entry = this.localTimeEntries.find(e => e.id === idx)!;
            let matterEntryType: string = '';
            let matterStatusDesc: string = '';
            let narrativeMinLength;
            let narrativeMaxLength;
            if (entry.matterId) {
                if (entry.isActCode === undefined && entry.isPhaseCode === undefined && entry.isFfTaskCode === undefined) {
                    const codeSets = await api.Code.determineCodeSetFields(entry.matterId!, entry.workDateTime);
                    entry.isPhaseCode = codeSets.isPhaseCode;
                    entry.isActCode = codeSets.isActCode;
                    entry.isFfTaskCode = codeSets.isFfTaskCode;
                }
                const matter = await api.Matter.get(entry.matterId);
                if (matter) {
                    matterEntryType = matter.entryType;
                    matterStatusDesc = matter.statusDescription;
                    narrativeMinLength = matter.minLength;
                    narrativeMaxLength = matter.maxLength;
                    entry.bannedWords = matter.bannedWords;
                    entry.blockBillingWords = matter.blockBillingWords;
                }
            }
            const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(entry.workDateTime));
            let vstate = ValidatePost(
                entry,
                0,
                matterStatusDesc,
                matterEntryType,
                appStore.features,
                activeTimeKeeper,
                narrativeMinLength,
                narrativeMaxLength,
                true
            );
            if (vstate.valid && !this.durVstate.get(entry.id!)) {
                entry = entry.setPosted();
                toPost.push(entry.id!);
                this.validationState.delete(entry.id!);
            } else {
                if (!vstate.valid) {
                    this.validationState.set(entry.id!, vstate);
                }
                this.expandedEntryIds = this.expandedEntryIds.concat([entry.id!]);
                this.setCodeSetsOnExpand(entry.id!);
            }
        }));
        if (toPost.length <= 0) {
            return;
        }
        const results = await api.ManagementDashboard.postEntries(
            this.selectedRole,
            this.approvalTimekeeperId,
            toPost
        );
        if (results.some(r => r.status.failed)) {
                results.forEach(result => {
                snackbarStore.triggerSnackbar(result.status.message);
            });
        }
        snackbarStore.triggerSnackbar('app.snackbar.info.posted');
        this.getDraftEntries();
    }

    @loadable()
    @action.bound
    async approveEntries(ids: number[], key?: string) {
        const { api, snackbarStore } = this.rootStore;
        if (key) {
            const confirmDelete = await this.confirm(`dialog.confirm.message.approve_${key}`);
            if (!confirmDelete) { return };
        }

        const results = await api.ManagementDashboard.approveEntries(this.selectedRole, this.approvalTimekeeperId, ids) as ApiResult<ImmutableTimeEntry>[];

        if (results.some(r => r.status.failed)) {
            results.forEach(r => {
                snackbarStore.triggerSnackbar(r.status.message);
            });
            // TODO: revert approvalStatus for failed entries.
            return;
        }
        this.selectedEntryIds = [];
        this.getProjectEntries();
        const messageKey = key ? `action_bar.approve_${key}.success.snackbar` : 'form.snackbar.success.approve';
        snackbarStore.triggerSnackbar(messageKey, { ns: 'management_dashboard' });
    }

    @loadable()
    @action.bound
    async rejectEntries(ids: number[], individualAction?: boolean) {
        const { api, snackbarStore } = this.rootStore;
        // get rejection codes, open dialog and wait for user response
        const rejectionCodes: RejectionCode[] = await this.rootStore.api.ManagementDashboard.getRejectionCodes();
        const selectedCodeId = await this.rootStore.rejectionCodeDialogStore.open(rejectionCodes);
        if (!selectedCodeId) { return; }
        if (!individualAction) {
            const confirmDelete = await this.confirm(`dialog.confirm.message.reject_selected`);
            if (!confirmDelete) { return; }
        }

        const results = await api.ManagementDashboard.rejectEntries(
            this.selectedRole,
            this.approvalTimekeeperId,
            ids,
            selectedCodeId
        ) as ApiResult<ImmutableTimeEntry>[];

        if (results.some(r => r.status.failed)) {
            results.forEach(r => {
                snackbarStore.triggerSnackbar(r.status.message);
            });
            return false;
        }
        this.selectedEntryIds = [];
        this.getProjectEntries();
        const messageKey = individualAction ? 'form.snackbar.success.reject' : 'snackbar.success.reject_selected';
        snackbarStore.triggerSnackbar(messageKey, { ns: 'management_dashboard' });
        return true;
    }

    @loadable()
    async getActiveTkForUser(entry: ImmutableTimeEntry) {
        if (entry.userId) {
            const tkForUser = await this.rootStore.api.Session.getTimekeeperAssignments(entry.userId);
            const date = DateTime.fromISO(entry.workDateTime);
            const tkId = entry.timeKeeperId;

            let validTkas = tkForUser.filter(tk => {
                let startDate = DateTime.fromISO(tk.startDate);
                let endDate = DateTime.fromISO(tk.endDate);
                return date >= startDate && date <= endDate && tk.timeKeeperId === tkId;
            });
            return validTkas[0]!;
        }
        return;
    }

    @action.bound
    setSelectedRole(role: Role) {
        this.selectedRole = role;
    }

    @action.bound
    setDelegator(delegator: Delegator | undefined | null) {
        this.delegator = delegator;
    }

    @action.bound
    setPhase(phase: Code | null | undefined) {
        this.phase = phase;
    }

    @action.bound
    setTask(task: Code | null | undefined) {
        this.task = task;
    }

    @action.bound
    // tslint:disable-next-line:no-any
    setApprovalStatus(approvalStatus: any) {
        this.approvalStatus = approvalStatus;
    }

    @action.bound
    setEmployee(employee: TimeKeeperAssignment | undefined | null) {
        this.employee = employee;
    }

    @override
    resetTimeEntryStore() {
        this.delegator = null;
        this.client = null;
        this.matter = null;
        this.phase = null;
        this.task = null;
        this.employee = null;
        this.billable = undefined;
        this.approvalStatus = [];
        this.searchText = '';
        this.setTimeEntryDateRange()
        this.matterType = MATTER_TYPE.All;
        this.referenceTE = null;
        this.localTimeEntries = [];
        this.expandedEntryIds = [];
        // reset Pagination values
        this.startIndex = 1;
        this.endIndex = 25;
        this.setEntriesPerPage(25);
        this.setPageNum(1);
        // this.rootStore.timeEntryStore.resetTimeEntryStore();
    }

    @override
    get filteredEntries() {
        let filteredEntries = this.localTimeEntries.filter((te, idx) => {
            if (te.dirty && idx < this.serverTimeEntries.length && te.id === this.serverTimeEntries[idx].id) {
                te = this.serverTimeEntries[idx];
            }

            return this.entryWithinSearch(te);
        });
        return filteredEntries;
    }

    @computed get approvalTimekeeperId() {
        return this.delegator ? this.delegator.sourceTkId : this.rootStore.api.Session.currentTimeKeeper!;
    }

    @computed get totalHours() {
        return this.filteredEntries.reduce((sum: number, ent: ImmutableTimeEntry) => sum + ent.duration, 0);
    }

    @computed get approvedHours() {
        return this.filteredEntries.reduce((sum: number, ent: ImmutableTimeEntry) =>
            ent.approvalStatus === ApprovalStatus.APPROVED ? sum + ent.duration : sum
        , 0);
    }

    @computed get unreviewedHours() {
        return this.filteredEntries.reduce((sum: number, ent: ImmutableTimeEntry) =>
            ent.approvalStatus === ApprovalStatus.UNREVIEWED ? sum + ent.duration : sum
        , 0);
    }

    @computed get rejectedHours() {
        return this.filteredEntries.reduce((sum: number, ent: ImmutableTimeEntry) =>
            ent.approvalStatus === ApprovalStatus.REJECTED ? sum + ent.duration : sum
        , 0);
    }
}