import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import ImmutableTimeEntry, { SapStatus } from '../../api/immutables/ImmutableTimeEntry';
import { parseBillable, parseCode, parseWorkLocale } from '../../util/utils';
import { ApprovalStatus, PdfFormatType } from '../../api/types/types';
import { DateTime } from 'luxon';
import { getDailyTotals, getGrandTotals } from '../../util/SaveAsTimeEntryPDF';
import rootStore from 'store/root.store';
import { withTranslation } from 'react-i18next';

interface Props {
    timeEntriesMap: Map<string, ImmutableTimeEntry[]>,
    exportType: string,
    timeKeeper: string,
    isMgmtDashboard: boolean,
    fromDate: DateTime,
    toDate: DateTime,
    // tslint:disable-next-line:no-any
    t: any
}

class PrintAsTimeEntryPdfExport extends Component<Props> {
    
    render() {
        const { timeEntriesMap, exportType, isMgmtDashboard, t } = this.props;
        const ffCodeEnabled = rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled;
        const actionCodeEnabled = rootStore.appStore.features.EpochConfigActionCodesRequired;
        const isWorkLocaleEnabled = rootStore.appStore.features.EpochConfigWorkLocaleEnabled;
        const isProject = rootStore.appStore.isProject;
        const isApprovalEnabled = rootStore.appStore.isApprovalEnabled;

        let narrativeColSpan = 8;
        if (isMgmtDashboard) {
            narrativeColSpan++;
        }
        if (ffCodeEnabled) {
            narrativeColSpan += 2;
        }
        if (actionCodeEnabled) {
            narrativeColSpan++;
        }
        if (isProject) {
            narrativeColSpan++;
        }
        if (isApprovalEnabled) {
            narrativeColSpan += 2;
        }
        let totalFrontColSpan = 4;
        if (isWorkLocaleEnabled) {
            totalFrontColSpan++;
            narrativeColSpan++;
        }
        if (isMgmtDashboard) {
            totalFrontColSpan++;
        }
        let totalBackColSpan = narrativeColSpan - 7;
        const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
        const dateRange = DateTime.fromISO(this.props.fromDate.toString()).toFormat('MMMM dd, yyyy') + ' - '
        + DateTime.fromISO(this.props.toDate.toString()).toFormat('MMMM dd, yyyy');

        return (
            <form id={'printjs-form'}>
                <img src={'static/logodark.png'} style={{width: '100px', height: '30px'}}/>
                <Table id={'printjs-form'} className={'pdfTable'}>
                    <TableHead className={'timeKeeperH'}>
                        <TableRow className={'timeKeeperR'}>
                            <TableCell colSpan={narrativeColSpan} className={'timeKeeper'}>
                                {!isMgmtDashboard &&
                                    <div className={'dateRange'}>
                                        {this.props.timeKeeper}
                                    </div>
                                }
                                {dateRange}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {isMgmtDashboard && exportType !== PdfFormatType.TIMEKEEPER &&
                                <TableCell>
                                    {t('export.pdf.table.row.header.timekeeper')}
                                </TableCell>
                            }
                            {exportType !== PdfFormatType.DATETYPE &&
                                <TableCell>
                                    {t('export.pdf.table.row.header.date')}
                                </TableCell>
                            }
                            {exportType !== PdfFormatType.CLIENTTYPE && 
                                <TableCell>
                                    {t('export.pdf.table.row.header.client')}
                                </TableCell>
                            }
                            {exportType !== PdfFormatType.MATTERTYPE &&
                                <TableCell>
                                    {t('export.pdf.table.row.header.matter', {matterLabel})}
                                </TableCell>
                            }
                            <TableCell>
                                {t('export.pdf.table.row.header.office')}
                            </TableCell>
                            {isWorkLocaleEnabled &&
                                <TableCell>
                                    {t('export.pdf.table.row.header.work_location')}
                                </TableCell>
                            }
                            <TableCell>
                                {t('export.pdf.table.row.header.phase')}
                            </TableCell>
                            <TableCell>
                                {t('export.pdf.table.row.header.task')}
                            </TableCell>
                            <TableCell>
                                {t('export.pdf.table.row.header.activity')}
                            </TableCell>
                            <TableCell>
                                {t('export.pdf.table.row.header.hours')}
                            </TableCell>
                            <TableCell>
                                {t('export.pdf.table.row.header.status')}
                            </TableCell>
                            {ffCodeEnabled &&
                                <TableCell>
                                    {t('export.pdf.table.row.header.ff_task')}
                                </TableCell>
                            }
                            {ffCodeEnabled &&
                                <TableCell>
                                    {t('export.pdf.table.row.header.ff_activity')}
                                </TableCell>
                            }
                            {actionCodeEnabled &&
                                <TableCell>
                                    {t('export.pdf.table.row.header.action_code')}
                                </TableCell>
                            }
                            {isProject &&
                                <TableCell>
                                    {t('export.pdf.table.row.header.billable')}
                                </TableCell>
                            }
                            {isApprovalEnabled &&
                                <>
                                    <TableCell>
                                        {t('export.pdf.table.row.header.approval_status')}
                                    </TableCell>
                                    <TableCell>
                                        {t('export.pdf.table.row.header.rejection_code')}
                                    </TableCell>
                                </>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...timeEntriesMap.entries()].map(([grpHeader, entries]) => (
                            <>
                                <TableRow className={'tableRow'}>
                                    <TableCell colSpan={narrativeColSpan} className={'grpHeader'}>
                                        {grpHeader}
                                    </TableCell>
                                </TableRow>
                                {entries.map((te) => (
                                    <>
                                        <TableRow className={'tableRow'}>
                                            {isMgmtDashboard && exportType !== PdfFormatType.TIMEKEEPER &&
                                                <TableCell>
                                                    {parseCode(te.timeKeeperId, te.timeKeeperName)}
                                                </TableCell>
                                            }
                                            {exportType !== PdfFormatType.DATETYPE &&
                                                <TableCell>
                                                    {DateTime.fromISO(te.workDateTime).toFormat('DDDD')}
                                                </TableCell>
                                            }
                                            {exportType !== PdfFormatType.CLIENTTYPE &&
                                                <TableCell>
                                                    {parseCode(te.clientNumber, te.clientName)}
                                                </TableCell>
                                            }
                                            {exportType !== PdfFormatType.MATTERTYPE &&
                                                <TableCell>
                                                    {parseCode(te.matterNumber, te.matterName)}
                                                </TableCell>
                                            }
                                            <TableCell>
                                                {parseCode(te.office, te.officeName)}
                                            </TableCell>
                                            {isWorkLocaleEnabled &&
                                                <TableCell>
                                                    {parseWorkLocale(rootStore.timeEntryStore.allWorkLocales, te.workLocaleId)}
                                                </TableCell>
                                            }
                                            <TableCell>
                                                {parseCode(te.phaseName, te.phaseDesc)}
                                            </TableCell>
                                            <TableCell>
                                                {parseCode(te.taskCode, te.taskCodeDesc)}
                                            </TableCell>
                                            <TableCell>
                                                {parseCode(te.actCode, te.actCodeDesc)}
                                            </TableCell>
                                            <TableCell>
                                                {(te.duration / 3600).toFixed(2).toString()}
                                            </TableCell>
                                            <TableCell>
                                                {this.status(te.sapStatus, t)}
                                            </TableCell>
                                            {ffCodeEnabled &&
                                                <TableCell>
                                                    {parseCode(te.ffTaskCode, te.ffTaskCodeDesc)}
                                                </TableCell>}
                                            {ffCodeEnabled &&
                                                <TableCell>
                                                    {parseCode(te.ffActCode, te.ffActCodeDesc)}
                                                </TableCell>}
                                            {actionCodeEnabled &&
                                                <TableCell>
                                                    {te.actionCode}
                                                </TableCell>}
                                            {isProject &&
                                                <TableCell>
                                                    {parseBillable(te.billable)}
                                                </TableCell>}
                                            {isApprovalEnabled &&
                                                <>
                                                    <TableCell>
                                                        {this.approvalStatus(te.approvalStatus, t)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {parseCode(te.rejectionCode, te.rejectionDesc)}
                                                    </TableCell>
                                                </>}
                                        </TableRow>
                                        {te.narrative &&
                                            <TableRow className={'tableRow narrative'}>
                                                <TableCell colSpan={narrativeColSpan} className={'tableRow'}>
                                                    {te.narrative}
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </>
                                ))}
                                <TableRow className={'tableRow'}>
                                    <TableCell colSpan={totalFrontColSpan}/>
                                    <TableCell colSpan={2} className={'totals'}>
                                        {exportType === PdfFormatType.DATETYPE ? t('export.pdf.table.row.total.daily') :
                                            exportType === PdfFormatType.MATTERTYPE ? t('export.pdf.table.row.total.matter', {matterLabel}) :
                                                exportType === PdfFormatType.CLIENTTYPE ? t('export.pdf.table.row.total.client') :
                                                    exportType === PdfFormatType.TIMEKEEPER && t('export.pdf.table.row.total.timekeeper')
                                        }
                                    </TableCell>
                                    <TableCell colSpan={1} className={'totals'}>
                                        {getDailyTotals(entries).toFixed(2)}
                                    </TableCell>
                                    <TableCell colSpan={totalBackColSpan}/>
                                </TableRow>
                            </>
                        ))}
                        <TableRow className={'tableRow'}>
                            <TableCell colSpan={totalFrontColSpan}/>
                            <TableCell colSpan={2} className={'totals'}>{t('export.pdf.table.row.footer.final_total')} </TableCell>
                            <TableCell colSpan={1} className={'totals'}>
                                {getGrandTotals(timeEntriesMap).toFixed(2)}
                            </TableCell>
                            <TableCell colSpan={totalBackColSpan}/>
                        </TableRow>
                    </TableBody>
                </Table>
            </form>
        );
    }

    // tslint:disable-next-line:no-any
    private status(sapStatus: SapStatus, t: any) {
        switch (sapStatus) {
            case SapStatus.QUEUED:
                return t('export.pdf.table.column.status.posted');
                break;
            case SapStatus.SUBMITTED:
                return  t('export.pdf.table.column.status.posted');
                break;
            case SapStatus.UNSUBMITTED:
                return  t('export.pdf.table.column.status.draft');
                break;
            default:
                return '';
        }
    }

    // tslint:disable-next-line:no-any
    private approvalStatus(approvalStatus: ApprovalStatus | undefined, t: any) {
        switch (approvalStatus) {
            case ApprovalStatus.UNREVIEWED:
                return t('export.pdf.table.column.approval_status.unreviewed');
                break;
            case ApprovalStatus.APPROVED:
                return  t('export.pdf.table.column.approval_status.approved');
                break;
            case ApprovalStatus.REJECTED:
                return  t('export.pdf.table.column.approval_status.rejected');
                break;
            default:
                return '';
        }
    }
    
}

export default withTranslation(['timeentries'])(PrintAsTimeEntryPdfExport);
