import * as React from 'react';
import { Checkbox, IconButton, Tooltip, TextField, Button } from '@material-ui/core';
import {
    Cancel,
    Check,
    Delete,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Save,
    GroupWorkOutlined,
    DoneAll,
    NotInterested,
    Edit,
    Undo
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { ApprovalStatus } from 'api/types/types';
import TimeEntryForm from 'components/TimeEntryForm/TimeEntryForm';
import TimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import * as Styled from './styled';
import TEContextMenu from 'components/TEContextMenu/TEContextMenu';
import { MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { ValidationState } from 'api/immutables/validators';
import * as ReactDOM from 'react-dom';
import scrollIntoView from 'scroll-into-view-if-needed';
import { CollaborateType, Features, TimeEntryType, TimeKeeperAssignment } from '../../api/types/types';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { FlexDiv } from 'common/flex';
import { TKConsumer } from 'common/TKProvider';
import TimeKeepersList from 'components/TimeKeepersList/TimeKeepersList';
import { CollaborateBtn } from 'components/NavBar/styled';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TimeEntryDialogStore from 'store/timeentry.dialog.store';
import { DateTime } from 'luxon';
import { getDateFormat } from '../../util/date';
import { getSelectedTks, setCollaborateInfo } from '../../util/timeEntry';
import { withTranslation } from 'react-i18next';

interface TimeEntryPanelProps {
    timeEntry: TimeEntry;
    validationState?: ValidationState;
    durValidationState?: boolean;
    onChange: (t: TimeEntry, vstate?: ValidationState, durVstate?: boolean) => void;
    onEdit?: (t: TimeEntry) => void;
    onSave?: (t: TimeEntry) => void;
    onPost?: (t: TimeEntry) => void;
    onUnpost?: (t: TimeEntry) => void;
    onCancel?: (t: TimeEntry) => void;
    onDelete?: (t: TimeEntry) => void;
    onCopy?: (t: TimeEntry) => void;
    onSplit?: (t: TimeEntry) => void;
    onMerge?: (t: TimeEntry) => void;
    isMergeable?: boolean;
    onTransfer?: (t: TimeEntry) => void;
    onApprove?: (t: TimeEntry) => void;
    onReject?: (t: TimeEntry) => void;
    isTransferable?: boolean;
    selected?: boolean;
    onSelect?: (selected: boolean) => void;
    expanded?: boolean;
    onExpand?: (expanded: boolean) => void;
    posted?: boolean; /** if true, time entry is posted and cannot be edited */
    error?: boolean; /** any error messages */
    disabled?: boolean;
    noCheckBox?: boolean;
    buildCodeSets?: (id: number) => void;
    timeCastEnabledInDayView?: boolean;
    dialogStore?: TimeEntryDialogStore;
    onSetFieldLoader?: (value: boolean) => void;
    offlineWorkDate?: boolean;
    isMgmtDashboard?: boolean;
    // roles: Role[];
    // tslint:disable-next-line:no-any
    t: any;
}

const styles = {
    tooltip : {
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical' as 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '400px',
        maxHeight: '46px'
    }
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        dialogStore: rootStore.timeEntryDialogStore
    };
})
@observer
class TimeEntryPanel extends React.Component<TimeEntryPanelProps> {
    expand = async () => {
        if (!this.props.expanded) {
            const entry = this.props.timeEntry;
            if (this.props.buildCodeSets) {
                await this.props.buildCodeSets(entry.id!);
            }
            setTimeout(() => {
                let elem: HTMLElement = ReactDOM.findDOMNode(this) as HTMLElement;
                scrollIntoView(elem, { behavior: 'smooth', scrollMode: 'if-needed' });
            }, 0);
        }
        this.props.onExpand!(!this.props.expanded);
    }
    
    select = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.props.onSelect!(checked);
    }

    changeReference = (event: React.ChangeEvent<HTMLInputElement>) => {
        let entry = this.props.timeEntry.setReference(event.target.value);
        this.props.onChange(entry);
    }

    disableCollaborateBtn = (writable: boolean) => {
        const { timeEntry, dialogStore } = this.props;
        const { online } = dialogStore!.rootStore.appStore;
        return online && ((timeEntry && timeEntry.timeEntryType !== TimeEntryType.COLLABORATE && writable &&
            !(timeEntry.collaborateTks || timeEntry.collaborateInfo) && timeEntry.matterId)
            || ((timeEntry.collaborateTks || timeEntry.collaborateInfo) &&
            dialogStore!.rootStore.timeEntryStore.collaborateEntriesIds.includes(timeEntry.id!)));
    }
    
    render() {
        let {
            timeEntry,
            selected,
            expanded,
            onExpand,
            onChange,
            onPost,
            onUnpost,
            onEdit,
            onCopy,
            onDelete,
            onSave,
            onCancel,
            onSplit,
            onMerge,
            isMergeable,
            onTransfer,
            onApprove,
            onReject,
            isTransferable,
            validationState,
            disabled,
            noCheckBox,
            timeCastEnabledInDayView,
            onSetFieldLoader,
            offlineWorkDate,
            isMgmtDashboard,
            dialogStore,
            t
        } = this.props;
        let providerData = {
            entry: timeEntry,
            selected: selected,
        };
        const isProject = dialogStore!.rootStore.appStore.isProject;
        const { dirty } = timeEntry;
        const employeeText = `${timeEntry.timeKeeperId} - ${timeEntry.timeKeeperName}`;
        const clientText = timeEntry.clientId ? `${timeEntry.clientNumber} - ${timeEntry.clientName}` : '';
        const matterText = timeEntry.matterId ? `${timeEntry.matterNumber} - ${timeEntry.matterName}` : '';
        const taskText = timeEntry.taskCodeId ? `${timeEntry.taskCode} - ${timeEntry.taskCodeDesc}` : '';
        const CustomTooltip = withStyles(styles)(Tooltip);
        const { getAllTimeKeepers, filteredAllTimekeepersList, setTkSearchText } = dialogStore!.rootStore.appStore;
        const matterLoading = dialogStore!.rootStore.timeEntryStore.matterLoading ||
            dialogStore!.rootStore.homeStore.matterLoading;
        
        // Fetching the Offices to show in the office list
        let tkOfficesToShow = dialogStore!.rootStore.appStore.getActiveTKOfficesForDate(
            DateTime.fromISO(timeEntry && timeEntry.workDateTime)
        );

        let collaborators: string;
        let numberOfCollaborators: number = 0;
        // get author/collaborators name(s) from entry collaborateInfo and convert to a string
        if (timeEntry && timeEntry.collaborateInfo) {
            const collaborateInfo = JSON.parse(timeEntry.collaborateInfo);
            if (timeEntry.timeEntryType === TimeEntryType.COLLABORATE) {
                collaborators = collaborateInfo.author.tkName;
            } else {
                collaborators = collaborateInfo.collaborators.map((tk: CollaborateType) => tk.tkName).join('\n');
                numberOfCollaborators = collaborateInfo.collaborators.length;
            }
        }  else if (timeEntry && timeEntry.collaborateTks) {
            const collaborateTks = timeEntry.collaborateTks.split(',');
            if (timeEntry.timeEntryType !== TimeEntryType.COLLABORATE) {
                collaborators = collaborateTks.join('\n');
                numberOfCollaborators = collaborateTks.length;
            }
        }

        const iconColor = timeEntry.approvalStatus === ApprovalStatus.REJECTED ? 'red' :
            timeEntry.approvalStatus === ApprovalStatus.APPROVED ? 'blue' :
                timeEntry.isPosted() ? 'green' : 'orange';

        const isRejectedOrApproved = timeEntry.approvalStatus === ApprovalStatus.REJECTED || timeEntry.approvalStatus === ApprovalStatus.APPROVED;

        return (
            <FeaturesConsumer>
                { (features: Features) =>
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
            <>
            <Styled.Container 
                elevation={1}
                square={true}
                expanded={expanded || false}
                iconColor={iconColor}
            >
                <MenuProvider id={`menu_${timeEntry.id}`} data={providerData}>
                <Styled.Header 
                    onClick={onExpand && this.expand}
                >
                    {!isMgmtDashboard && timeEntry.status === 'REVERSE SYNC' ?
                            <Styled.ReverseSyncIcon iconColor={iconColor} />
                        :
                            timeEntry.approvalStatus === ApprovalStatus.REJECTED ?
                                <Styled.RejectedIcon iconColor={iconColor} />
                            :
                                timeEntry.approvalStatus === ApprovalStatus.APPROVED || (!isMgmtDashboard && timeEntry.isPosted()) ?
                                    <Styled.CheckIcon iconColor={iconColor} />
                                :
                                    <Checkbox
                                        checked={selected}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={this.select}
                                        disableRipple={true}
                                        style={{padding: 0}}
                                        disabled={noCheckBox || !tk.writable}
                                    />
                    }
                    <Styled.TEContent gridAutoFlow="row">
                        {offlineWorkDate && <Styled.ListDate>
                            {DateTime.fromISO(timeEntry.workDateTime).toFormat('EEE')
                            + ', ' + DateTime.fromISO(timeEntry.workDateTime).toFormat(getDateFormat())}
                        </Styled.ListDate>}
                        <Styled.TEDetails
                            gridAutoFlow="column"
                            gridTemplateColoums={isMgmtDashboard ? '0.8fr 0.8fr 0.8fr 0.8fr 0.8fr .2fr .18fr auto auto'
                                : timeCastEnabledInDayView ? '1.5fr 1.5fr .5fr .4fr auto'
                                    : isProject ? '0.8fr 0.8fr 0.8fr 1fr .2fr .18fr auto auto'
                                        : '1fr 1fr 1.5fr .27fr .18fr auto auto'
                            }
                        >
                            {isMgmtDashboard &&
                                <Tooltip title={employeeText}>
                                    <Styled.HeaderItem>{employeeText}</Styled.HeaderItem>
                                </Tooltip>
                            }
                            <Tooltip title={clientText}>
                                    <Styled.HeaderItem>{clientText}</Styled.HeaderItem>
                            </Tooltip>
                            <Tooltip title={matterText}>
                                <Styled.HeaderItem>{matterText}</Styled.HeaderItem>
                            </Tooltip>
                            {isProject &&
                                <Tooltip title={taskText}>
                                    <Styled.HeaderItem>{taskText}</Styled.HeaderItem>
                                </Tooltip>
                            }
                            {!timeCastEnabledInDayView &&
                                <CustomTooltip title={timeEntry.narrative}>
                                    <Styled.HeaderItem>{timeEntry.narrative}</Styled.HeaderItem>
                                </CustomTooltip>
                            }
                            <Styled.DurationHeader
                                billable={isProject ? !!timeEntry.billable : timeEntry.isBillable()}
                                matter={isProject ? typeof timeEntry.billable === 'boolean' || timeEntry.isPosted() : timeEntry.matterId}
                            >
                                {(timeEntry.duration / 3600).toFixed(2)}
                            </Styled.DurationHeader>
                            {(timeEntry.timeEntryType === TimeEntryType.COLLABORATE && (timeEntry.collaborateTks! || timeEntry.collaborateInfo!)) ?
                                <CustomTooltip title={timeEntry.collaborateTks || collaborators} >
                                    <GroupWorkOutlined/>
                                </CustomTooltip> : <div/>
                            }
                            {expanded ? <div title={t('collapse', { ns: 'common' })}><KeyboardArrowUp/></div>
                                : <div title={t('expand', { ns: 'common' })}><KeyboardArrowDown/></div>}
                            <Styled.DirtyIndicator>{dirty ? '*' : ''}</Styled.DirtyIndicator>
                        </Styled.TEDetails>
                        {timeCastEnabledInDayView &&
                            <Styled.StyledNarrative>
                                <CustomTooltip title={timeEntry.narrative} >
                                    <Styled.HeaderItem>{timeEntry.narrative}</Styled.HeaderItem>
                                </CustomTooltip>
                            </Styled.StyledNarrative>
                        }
                    </Styled.TEContent>
                </Styled.Header>
                </MenuProvider>
                    {expanded && <Styled.Form>
                        <TimeEntryForm
                            timeEntry={timeEntry}
                            validationState={validationState}
                            durValidationState={this.props.durValidationState}
                            onChange={onChange}
                            onSetFieldLoader={onSetFieldLoader}
                            actionCodesRequired={features.EpochConfigActionCodesRequired}
                            minNarrativeLength={features.EpochConfigNarrativesMinimumChars}
                            maxNarrativeLength={features.EpochConfigNarrativesMaximumChars}
                            disabled={disabled}
                            tkOfficesToShow={tkOfficesToShow!}
                            isMgmtDashboard={isMgmtDashboard}
                            isEntryPanel={true}
                        />
                        <div style={{ display: 'flex'}}>
                            {features.EpochConfigReferenceRequired && timeEntry && !timeEntry.matterId &&
                                        <TextField
                                            label={t('field.reference')}
                                            error={validationState && validationState.isReferenceEmpty}
                                            helperText={validationState && validationState.isReferenceEmpty ? t('validation.reference.invalid') : ''}
                                            value={timeEntry && timeEntry.reference ? timeEntry.reference : ''}
                                            onChange={this.changeReference}
                                        />
                                        }
                            <FlexDiv flex={1} />
                            {validationState && validationState.invalidWorkDate &&
                                <p style={{ color: '#f44336' }}>{t('validation.date.invalid')}</p>}
                            {(timeEntry.sapStatus === 'UNSUBMITTED') &&
                                <Styled.Actions>
                                    {!isMgmtDashboard &&
                                        <TimeKeepersList
                                            render={(tkMenuAnchor, openTkList) =>
                                                <CollaborateBtn
                                                    innerRef={tkMenuAnchor}
                                                    title={timeEntry.timeEntryType !== TimeEntryType.COLLABORATE ? collaborators : ''}
                                                >
                                                    <Button
                                                        onClick={(evt) => {
                                                            setTkSearchText('')
                                                            getAllTimeKeepers(timeEntry.workDateTime, timeEntry.matterId)
                                                                .then(() => openTkList(evt))
                                                        }}
                                                        aria-label={t('action.collaborate')}
                                                        disabled={!this.disableCollaborateBtn(tk.writable)}
                                                    >
                                                        {t('action.collaborate')} ({numberOfCollaborators})
                                                    </Button>
                                                </CollaborateBtn>}
                                            collaborate={true}
                                            selectedTimeKeepers={getSelectedTks(timeEntry)}
                                            saveCollaboratees={(tks: CollaborateType[]) => setCollaborateInfo(tks, timeEntry, onChange)}
                                            menuWidth={300}
                                            timeKeepers={filteredAllTimekeepersList}
                                            workDate={timeEntry.workDateTime}
                                            matterId={timeEntry.matterId}
                                        />
                                    }
                                    {onEdit && isMgmtDashboard &&
                                        <Tooltip title={t('action.edit', { ns: 'management_dashboard' })}>
                                            <IconButton disabled={!tk.writable || matterLoading} onClick={() => onEdit!(timeEntry)}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {onSave && !isMgmtDashboard &&
                                        <Tooltip title={t('save', { ns: 'common' })}>
                                            <IconButton disabled={!dirty || !tk.writable || matterLoading} onClick={() => onSave!(timeEntry)}>
                                                    <Save />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {onPost &&
                                        <Tooltip title={t('action.post')}>
                                            <IconButton disabled={!tk.writable || matterLoading} onClick={() => onPost!(timeEntry)}>
                                                <Check />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {onCancel && !isMgmtDashboard &&
                                        <Tooltip title={t('cancel', { ns: 'common' })}>
                                            <IconButton disabled={!dirty || !tk.writable} onClick={() => onCancel!(timeEntry)}>
                                                <Cancel />
                                            </IconButton>
                                        </Tooltip>}
                                    {onDelete && !isMgmtDashboard &&
                                        <Tooltip title={t('delete', { ns: 'common' })}>
                                            <IconButton disabled={!tk.writable} onClick={() => onDelete!(timeEntry)}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Styled.Actions>
                            }
                            {isProject && (timeEntry.approvalStatus === ApprovalStatus.UNREVIEWED) && (timeEntry.sapStatus === SapStatus.QUEUED) &&
                                <Styled.Actions>
                                    {onUnpost &&
                                        <Tooltip title={t('action.unpost')}>
                                            <IconButton disabled={!tk.writable || matterLoading} onClick={() => onUnpost!(timeEntry)}>
                                                <Undo />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Styled.Actions>
                            }
                            {isMgmtDashboard && !isRejectedOrApproved && timeEntry.isPosted() &&
                                <Styled.Actions>
                                    {onEdit &&
                                        <Tooltip title={t('action.edit', { ns: 'management_dashboard' })}>
                                            <IconButton disabled={!tk.writable || matterLoading} onClick={() => onEdit!(timeEntry)}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {onApprove &&
                                        <Tooltip title={t('action.approve', { ns: 'management_dashboard' })}>
                                            <IconButton disabled={!tk.writable || matterLoading} onClick={() => onApprove!(timeEntry)}>
                                                <DoneAll />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {onReject &&
                                        <Tooltip title={t('action.reject', { ns: 'management_dashboard' })}>
                                            <IconButton disabled={!tk.writable || matterLoading} onClick={() => onReject!(timeEntry)}>
                                                <NotInterested />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Styled.Actions>
                            }
                       </div>
                    </Styled.Form>}
                <TEContextMenu
                    id={`menu_${timeEntry.id}`}
                    editHandler={onEdit && (!timeEntry.isPosted() || (timeEntry.isPosted() && isMgmtDashboard && !isRejectedOrApproved)) && tk.writable ?
                        () => onEdit!(timeEntry) : undefined}
                    postHandler={onPost && !timeEntry.isPosted() && tk.writable ? () => onPost!(timeEntry) : undefined}
                    unpostHandler={onUnpost && isProject && (timeEntry.approvalStatus === ApprovalStatus.UNREVIEWED) && tk.writable &&
                        (timeEntry.sapStatus === SapStatus.QUEUED) ? () => onUnpost!(timeEntry) : undefined}
                    deleteHandler={onDelete && !timeEntry.isPosted() && tk.writable ? () => onDelete!(timeEntry) : undefined}
                    copyHandler={onCopy && tk.writable ? () => onCopy!(timeEntry) : undefined}
                    splitHandler={onSplit && timeEntry.isSplitable() && tk.writable ? () => onSplit!(timeEntry) : undefined}
                    viewHandler={onEdit && (timeEntry.isPosted() && (!isMgmtDashboard || isRejectedOrApproved)) || !tk.writable ?
                        () => onEdit!(timeEntry) : undefined}
                    mergeHandler={onMerge && isMergeable && !timeEntry.isPosted() && tk.writable ? () => 
                        onMerge!(timeEntry) : undefined}
                    transferHandler={onTransfer && isTransferable && !timeEntry.isPosted() && tk.writable ? () =>
                        onTransfer!(timeEntry) : undefined}
                    approveHandler={onApprove && timeEntry.isPosted() && !isRejectedOrApproved && tk.writable ?
                        () => onApprove!(timeEntry) : undefined}
                    rejectHandler={onReject && timeEntry.isPosted() && !isRejectedOrApproved && tk.writable ?
                        () => onReject!(timeEntry) : undefined}
                />
            </Styled.Container>
            </>
            }
            </TKConsumer>
            }
        </FeaturesConsumer>
        );
    }
}

export default withTranslation(['timeentries', 'common', 'management_dashboard'])(TimeEntryPanel);