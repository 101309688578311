import { AppBar, IconButton, Switch, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { FlexDiv } from 'common/flex';
import * as Styled from '../../containers/Home/styled';
import { DateTime } from 'luxon';
import { Add, KeyboardArrowLeft, KeyboardArrowRight, Today, ViewComfy } from '@material-ui/icons';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { Features } from '../../api/types/types';
import { NavTimerContainer } from '../../containers/Timers/styled';
import NavTimer from '../../containers/Timers/NavTimer';
import * as React from 'react';
import { ViewMode } from '../../containers/Home/Home';
import { TimeKeeperAssignment } from '../../api/types/types';
import { TKConsumer } from 'common/TKProvider';
import { useTranslation } from 'react-i18next';

interface Props {
    changeViewMode: (mode: ViewMode) => void;
    calendarMode: ViewMode;
    setToday: (date: DateTime, today?: boolean) => void;
    stepMainDate: (amt: number) => Promise<void>;
    createNewTimeEntry: () => void;
    isTCViewInProgress: boolean;
    timersLoading: boolean;
    getCalendarText: () => string;
    toggleTCView: boolean;
    toggleTimeCastView: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const HomeAppBar = (props: Props) => {
    const { t } = useTranslation(['home']);
    const {
        changeViewMode,
        calendarMode,
        setToday,
        stepMainDate,
        createNewTimeEntry,
        isTCViewInProgress,
        timersLoading,
        getCalendarText,
        toggleTimeCastView,
        toggleTCView
    } = props;
    
    function _getCalendarText(): string {
        return getCalendarText();
    }
    return(
        <AppBar position="static" elevation={0}>
            <Toolbar style={{paddingRight: '0'}}>
                <FlexDiv>
                    <Styled.ViewButton
                        color="secondary"
                        onClick={() => changeViewMode(ViewMode.MONTH)}
                        variant={
                            calendarMode === ViewMode.MONTH
                                ? 'contained'
                                : 'text'
                        }
                    >
                        {t('header.action.view.month').toUpperCase()}
                    </Styled.ViewButton>
                    <Styled.ViewButton
                        color="secondary"
                        onClick={() => changeViewMode(ViewMode.WEEK)}
                        variant={
                            calendarMode === ViewMode.WEEK
                                ? 'contained'
                                : 'text'
                        }
                    >
                        {t('header.action.view.week').toUpperCase()}
                    </Styled.ViewButton>
                    <Styled.ViewButton
                        color="secondary"
                        onClick={() => {
                            changeViewMode(ViewMode.DAY)
                        }}
                        variant={
                            calendarMode === ViewMode.DAY
                                ? 'contained'
                                : 'text'
                        }
                    >
                        {t('header.action.view.day').toUpperCase()}
                    </Styled.ViewButton>
                    <Styled.ViewButton
                        color="secondary"
                        onClick={() => changeViewMode(ViewMode.GRID)}
                        variant={
                            calendarMode === ViewMode.GRID
                                ? 'contained'
                                : 'text'
                        }
                    >
                        <ViewComfy />
                    </Styled.ViewButton>
                </FlexDiv>
                <IconButton
                    title={t('header.action.navigate.today')}
                    color="secondary"
                    onClick={() => setToday(DateTime.utc(), true)}
                >
                    <Today/>
                </IconButton>
                <IconButton
                    onClick={() => stepMainDate(-1)}
                    color="secondary"
                    aria-label={t('header.action.navigate.previous')}
                >
                    <KeyboardArrowLeft/>
                </IconButton>
                <IconButton
                    onClick={() => stepMainDate(1)}
                    color="secondary"
                    aria-label={t('header.action.navigate.next')}
                >
                    <KeyboardArrowRight/>
                </IconButton>
                <Typography variant="h6">
                    {_getCalendarText()}
                </Typography>
                <Styled.NewEntryNavButton>
                    {/* {withinEditableRange(compareDate) &&  */}
                    <FeaturesConsumer>
                        {(features: Features) => features.EpochConfigTimeCastEnabled && calendarMode === ViewMode.DAY &&
                            <Switch
                                name={'Toggle TimeCast view'}
                                checked={toggleTCView}
                                onChange={toggleTimeCastView}
                            />
                        }
                    </FeaturesConsumer>
                    <TKConsumer>
                        {(tk: TimeKeeperAssignment) => tk.writable &&
                            <Tooltip title={t('header.action.add.tooltip')}>
                                <IconButton
                                    color="secondary"
                                    aria-label={t('header.action.add.tooltip')}
                                    onClick={createNewTimeEntry}
                                    disabled={isTCViewInProgress}
                                    style={{visibility: `${calendarMode === ViewMode.GRID ? 'hidden' : 'visible'}`}}
                                >
                                    <Add/>
                                </IconButton>
                            </Tooltip>
                        }
                    </TKConsumer>
                </Styled.NewEntryNavButton>
                <FeaturesConsumer>
                    {(features: Features) => features.EpochConfigTimersEnabled ?
                        (timersLoading ? <NavTimerContainer /> : <NavTimer/>) : <></>
                    }
                </FeaturesConsumer>
                
            </Toolbar>
        </AppBar>
    );
}