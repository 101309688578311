import { action, observable, makeObservable } from 'mobx';

export default class SnackbarStore {
    @observable snackbarOpen: boolean = false;
    transaltionKey: string = '';
    isError: boolean = false;
    translationOptions: {} = {};

    constructor() {
        makeObservable(this);
    }

    @action triggerSnackbar = (key: string, options: {} = { ns: 'common' }, isError= false) => {
        this.isError = isError;
        this.transaltionKey = key;
        this.translationOptions = options;
        this.snackbarOpen = true;
    }

    @action closeSnackbar = () => {
        this.transaltionKey = '';
        this.translationOptions = {};
        this.snackbarOpen = false;
        this.isError = false;
    }
}
