export const STYLES_FOR_PDF = `
.pdfTable {
    border-collapse: collapse;
}
.tableRow {
    border: 1px solid #ddd;
}
th {
    background-color: #ff9800;
    font-size: 10px;
}
td {
    border-right: 1px solid #ddd;
    text-align: left;
    font-size: 10px;
    padding-left: 5px;
}
.grpHeader {
    font-weight: bold;
    font-size: 18px;
    background: rgb(134, 128, 128);
    color: rgb(255, 255, 255);
    text-align: center;
}
.narrative {
    background: rgb(220, 220, 220);
}
.totals {
    font-weight: bold;
    text-align: center;
}
.timeKeeper {
    font-size: 15px;
    background: #FFFFFF;
    font-weight: 400;
    text-align: left;
}
.timeKeeperR, .timeKeeperH {
    height: 0 !important;
}
.dateRange {
    padding-bottom: 5px !important;
}
body{
    -webkit-print-color-adjust: exact;
    display: block;
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}
@page { 
    size: auto;
    @bottom-left {
        content: counter(page);
    }
    margin-left: 25px;
}
`