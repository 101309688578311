import React from 'react';
import { useDrop } from 'react-dnd';
import TimeEntry from '../../api/immutables/ImmutableTimeEntry';
import { DragItemTypes } from 'components/DragNDropTimeCastSegment/SegmentDragSource';
import TimeEntryPanel from 'components/TimeEntryPanel';
import { ValidationState } from '../../api/immutables/validators';
import ImmutableTimeEntry from '../../api/immutables/ImmutableTimeEntry';

interface Props {
    timeEntry: TimeEntry;
    validationState?: ValidationState;
    durValidationState?: boolean;
    onChange: (t: TimeEntry, vstate?: ValidationState, durVstate?: boolean) => void;
    onEdit?: (t: TimeEntry) => void;
    onSave?: (t: TimeEntry) => void;
    onPost?: (t: TimeEntry) => void;
    onUnpost?: (t: TimeEntry) => void;
    onCancel?: (t: TimeEntry) => void;
    onDelete?: (t: TimeEntry) => void;
    onCopy?: (t: TimeEntry) => void;
    onSplit?: (t: TimeEntry) => void;
    onMerge?: (t: TimeEntry) => void;
    isMergeable?: boolean;
    onTransfer?: (t: TimeEntry) => void;
    onApprove?: (t: TimeEntry) => void;
    onReject?: (t: TimeEntry) => void;
    isTransferable?: boolean;
    selected?: boolean;
    onSelect?: (selected: boolean) => void;
    expanded?: boolean;
    onExpand?: (expanded: boolean) => void;
    posted?: boolean; /** if true, time entry is posted and cannot be edited */
    error?: boolean; /** any error messages */
    disabled?: boolean;
    noCheckBox?: boolean;
    onDrop?: (timeEntry: ImmutableTimeEntry) => void;
    buildCodeSets?: (id: number) => void;
    timeCastEnabledInDayView?: boolean;
    onSetFieldLoader?: (value: boolean) => void;
    isMgmtDashboard?: boolean;
}

const TimeEntryDropTarget = (props: Props) => {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: DragItemTypes.ELEMENT_TYPE,
        drop: (item, monitor) => (
            props.timeEntry // This will become available at the dragSource in endDrag method as getDropResult.
        ),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });
    
    const isActive = canDrop && isOver;
    
    const style: React.CSSProperties = {
        flex: 1,
        opacity: isActive ? 0.4 : 1
    };
    const {
        timeEntry,
        selected,
        onSelect,
        expanded,
        onExpand,
        onChange,
        onPost,
        onUnpost,
        onEdit,
        onCopy,
        onDelete,
        onSave,
        onCancel,
        onSplit,
        onMerge,
        isMergeable,
        onTransfer,
        onApprove,
        onReject,
        isTransferable,
        validationState,
        durValidationState,
        buildCodeSets,
        timeCastEnabledInDayView,
        onSetFieldLoader,
        isMgmtDashboard
    } = props;
    
    return (
        <div ref={drop} style={style}>
            <TimeEntryPanel
                validationState={validationState}
                durValidationState={durValidationState}
                timeEntry={timeEntry}
                onChange={onChange}
                onExpand={onExpand}
                expanded={expanded}
                onSelect={onSelect}
                selected={selected}
                onPost={onPost}
                onUnpost={onUnpost}
                onSave={onSave}
                onDelete={onDelete}
                onCancel={onCancel}
                onCopy={onCopy}
                onEdit={onEdit}
                onSplit={onSplit}
                onMerge={onMerge}
                isMergeable={isMergeable}
                onTransfer={onTransfer}
                onApprove={onApprove}
                onReject={onReject}
                isTransferable={isTransferable}
                buildCodeSets={buildCodeSets}
                timeCastEnabledInDayView={timeCastEnabledInDayView}
                onSetFieldLoader={onSetFieldLoader}
                isMgmtDashboard={isMgmtDashboard}
            />
        </div>
    );
}

export default TimeEntryDropTarget;