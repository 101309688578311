import TemplateAPI from 'api/interfaces/TemplateAPI';
import BaseWebImplementation from './Base.impl';
import Template from 'api/immutables/ImmutableTemplate';
import ImmutableTemplate from 'api/immutables/ImmutableTemplate';
import { ApiResult } from '../../util';
import { Platform } from '../../../util/Platform';
export default class TemplateImpl extends BaseWebImplementation implements TemplateAPI {
    handlers: (((entries: Template[]) => void) | null)[] = [];

    async getAllTemplates(searchQuery?: string) {
        // adding limit of 5000 as temporary solution, template and narrative pages should have parent component
        let { data } = await this.http.get(`templates?offset=0&limit=5000${searchQuery ? `&searchHistory=${searchQuery}` : ''}`);
        return (data.map((d: object) => Object.assign(new Template(), d)));
    }

    async getTemplate(id: number) {
        const { data } = await this.http.get(`templates/${id}`);
        return Object.assign(new Template(), data);
    }

    async saveTemplate(t: Template) {
        const sessionId = localStorage.getItem('sessionId');
        let obj = Object.assign(new ImmutableTemplate(), {
            id: t.id,
            timeKeeperId: this.root.Session.currentTimeKeeper,
            name: t.name,
            matterId: t.matterId,
            phaseId: t.phaseId,
            actCodeId: t.actCodeId,
            actionCodeId: t.actionCodeId,
            taskCodeId: t.taskCodeId,
            ffTaskCodeId: t.ffTaskCodeId,
            ffActCodeId: t.ffActCodeId,
            narrative: t.narrative,
            deleted: t.deleted
        });
        if (Platform.isWeb() && !t.id) {
            obj.localUid = `WEB_${new Date().getTime()}_${Math.random()}_${sessionId}`;
        }
        const { data } = await this.http.put(`templates`, [obj]);
        // tslint:disable-next-line:no-any
        let savedTemplates = data.filter( (r: any) => !r.status.failed).map((r: any) => r.object);
        if (savedTemplates.length > 0) {
            let syntheticSync = {
                templates: savedTemplates,
                glossaries: [],
                timers: [],
                timeEntries: []
            };
            this.root.Session.tabexClient.emit('sync', syntheticSync);
        }
        if (!data[0].status.failed) {
            return Object.assign(new Template(), data[0].object);
        } else {
            // logger.error('Templates, Error saving template', data[0].status.message);
            throw data[0].status.message;
        }
    }
    updateTemplates = async (entries: ImmutableTemplate[]): Promise<ApiResult<ImmutableTemplate>[]> => {
        let entryData = entries.map((t) => ({
            id: t.id,
            timeKeeperId: this.root.Session.currentTimeKeeper,
            name: t.name,
            matterId: t.matterId,
            phaseId: t.phaseId,
            actCodeId: t.actCodeId,
            actionCodeId: t.actionCodeId,
            taskCodeId: t.taskCodeId,
            ffTaskCodeId: t.ffTaskCodeId,
            ffActCodeId: t.ffActCodeId,
            narrative: t.narrative,
            deleted: t.deleted,
            localUid: t.localUid
        }));

        let savedTemplates: ImmutableTemplate[] = [];
        let resp: ApiResult<ImmutableTemplate>[] = [];
        
        try {
            const { data } = await this.http.put('/templates', entryData);
            // tslint:disable-next-line:no-any
            savedTemplates = data.filter((r: any) => !r.status.failed).map((r: any) => r.object);
            resp = data;
        } catch (e) {
            savedTemplates = entries;
            // logger.info('Templates, Error updating templates', e);
            throw e;
        } finally {
            if (savedTemplates.length > 0) {
                let syntheticSync = {
                    templates: savedTemplates,
                    glossaries: [],
                    timers: [],
                    timeEntries: []
                };
                this.root.Session.tabexClient.emit('sync', syntheticSync);
            }
            return resp;
        }
    }
    registerReciever = (handler: (entries: Template[]) => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return () => {
            this.handlers[theIndex] = null;
        };
    }
    recieve = (templates: Template[]) => {
        this.handlers.filter(h => h !== null).forEach(h => h!(templates));
    }
}