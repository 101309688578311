import { useContext, useRef } from 'react';
import { IconButton } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../App';

export default function BulkUploadButton() {
    const inputFileRef = useRef<HTMLInputElement>( null );
    const { t } = useTranslation('timeentries');
    const rootStore = useContext(RootStoreContext);
    const onFileChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
        rootStore.timeEntryStore.loading = true;
        let files = e.currentTarget.files ? e.currentTarget.files : [];
        let file = files[0];

        rootStore.api.TimeEntry.uploadTimeEntries(file, file.type).then(res => {
            rootStore.timeEntryStore.loading = false;
            // tslint:disable-next-line:no-any
            let count = res.filter((r: any) => r.status.failed).length
            if (count > 0) {
                rootStore.snackbarStore.triggerSnackbar(count + (count === 1 ? ' Time Entry' : ' Time Entries')
                    + ' creation failed in the upload', undefined, true);
            } else {
                rootStore.snackbarStore.triggerSnackbar('Upload successful');
            }
            if (count < res.length) {
                rootStore.appStore.synchronize();
            }
        }).catch(ex => {
            rootStore.timeEntryStore.loading = false;
            let err = ex.message;
            if (ex.response.status === 500) {
                err = ex.response.data.message;
            } else if (ex.response.status === 400) {
                err = ex.response.data.value;
            }
            rootStore.snackbarStore.triggerSnackbar(err, undefined, true);
        });
        e.currentTarget.value = '';
    }
    const onBtnClick = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    }

    return (
    <>
        <input
            accept=".csv, .xlsx, .xls"
            style={{ display: 'none' }}
            id="upload-file"
            hidden={true}
            type="file"
            ref={inputFileRef}
            onChange={onFileChange}
        />
        <IconButton
            key={'upload'}
            title={t('header.action.upload.tooltip')}
            onClick={onBtnClick}
        >
            <CloudUpload />
        </IconButton>
    </>
    )
}
