import ImmutableTimeEntry, { SapStatus } from '../api/immutables/ImmutableTimeEntry';
import { ApprovalStatus, PdfFormatType, TimeEntryPdfCell, TimeEntryPdfHeader } from '../api/types/types';
import { parseBillable, parseCode, parseWorkLocale } from './utils';
import { Styles } from 'jspdf-autotable';
import { DateTime } from 'luxon';
import rootStore from 'store/root.store';

let jsPDF = require('jspdf');
require('jspdf-autotable');

const constantsJSON = require('../constants.json');

export const COL_WIDTH = constantsJSON.pdfReport.colWidth;
export const TEXT_FONT_SIZE = constantsJSON.pdfReport.textFontSize;
export const REP_FONT_SIZE = constantsJSON.pdfReport.repFontSize;
export const HEADER_COLOR = constantsJSON.pdfReport.headerColor;

interface CustomCell {
    // tslint:disable-next-line:no-any
    styles: any,
    rowSpan: number;
    colSpan: number;
    content: string | number;
    key: string;
}

// tslint:disable-next-line:no-any
let pageContent = (pageData: any, isMgmtDashboard: boolean, t: any) => {
    // HEADER
    pageData.doc.setFontSize(REP_FONT_SIZE);
    pageData.doc.setTextColor(REP_FONT_SIZE * 4);
    pageData.doc.setFontStyle('normal');

    if (!isMgmtDashboard) {
        pageData.doc.text(
            pageData.settings.timeKeeper,
            pageData.table.settings.margin.left,
            25
        );
    }
    pageData.doc.text(
        DateTime.fromISO(pageData.settings.fromDate).toFormat('MMMM dd, yyyy') + ' - ' + DateTime.fromISO(pageData.settings.toDate).toFormat('MMMM dd, yyyy'),
        pageData.table.settings.margin.left,
        !isMgmtDashboard ? 30 : 25
    );

    // FOOTER
    let str = `${t('export.pdf.footer.page')} ` + pageData.pageCount;
    pageData.doc.setFontSize(REP_FONT_SIZE);
    pageData.doc.text(
        str, 
        pageData.table.settings.margin.left, 
        pageData.doc.internal.pageSize.height - 5
    );
};

// tslint:disable-next-line:no-any
const getImageFromUrl = (url: string) => new Promise( resolve => {
    const img = new Image();
    img.onload = () => {
        let canvas = document.createElement('canvas');
        document.body.appendChild(canvas);
        canvas.width = 100;
        canvas.height = 30;
        let ctx = canvas.getContext('2d');
        if (ctx !== null) {
            ctx.drawImage(img, 0, 0);
            // Grab the image as a jpeg encoded in base64, but only the data
            let data = canvas.toDataURL('image/png');
            document.body.removeChild(canvas);
            resolve(data);
        }
    };
    img.src = url;
});

export async function buildPdf(timeEntries: Map<string, ImmutableTimeEntry[]>, formatType: PdfFormatType, timeKeeper: string, 
                               // tslint:disable-next-line:no-any
                               fromDate: DateTime, toDate: DateTime, isMgmtDashboard: boolean, t: any) {

    const doc = new jsPDF('l');
    const imageData = await getImageFromUrl('static/logodark.png');
    doc.addImage({imageData: imageData, x: 10, y: 10});

    let allColumns = getColumnsForPDf(formatType, isMgmtDashboard, t);
    doc.setFontSize(REP_FONT_SIZE);
    let pdf = exportDataForPdf(timeEntries, formatType, doc, isMgmtDashboard, t);

    doc.autoTable(
        {
            columns: allColumns,
            body: pdf,
            pageBreak: 'auto',
            rowPageBreak: 'auto',
            theme: 'grid',
            // tslint:disable-next-line:no-any
            didDrawPage: (pageDate: any) => pageContent(pageDate, isMgmtDashboard, t),
            margin: {right: REP_FONT_SIZE, left: REP_FONT_SIZE, bottom: REP_FONT_SIZE * 2, top: REP_FONT_SIZE * (isMgmtDashboard ? 3 : 3.5)},
            headStyles: {
                fillColor: HEADER_COLOR,
                fontSize: TEXT_FONT_SIZE,
                textColor: 0,
                fontStyle: 'bold',
                halign: 'center',
                valign: 'middle',
                minCellHeight: TEXT_FONT_SIZE
            },
            bodyStyles: {
                fontSize: TEXT_FONT_SIZE
            },
            styles: {cellWidth: 'wrap', minCellHeight: REP_FONT_SIZE * 2, overflow: 'linebreak', valign: 'middle'},
            columnStyles: {
                timekeeper: columnStyles('auto'),
                workdate: columnStyles('auto'),
                client: columnStyles('auto'),
                matter: columnStyles('auto'),
                office: columnStyles(COL_WIDTH * 3),
                workLocale: columnStyles(COL_WIDTH * 3),
                phase: columnStyles(COL_WIDTH * 3),
                ffTask: columnStyles(COL_WIDTH * 3),
                ffAct: columnStyles(COL_WIDTH * 3),
                activity: columnStyles(COL_WIDTH * 3),
                task: columnStyles(COL_WIDTH * 3),
                status: columnStyles(COL_WIDTH * 2),
                action: columnStyles(COL_WIDTH * 3),
                duration: columnStyles(COL_WIDTH * 3),
                billable: columnStyles(COL_WIDTH * 3),
                approvalStatus: columnStyles(COL_WIDTH * 3),
                rejectionCode: columnStyles(COL_WIDTH * 3)
            },
            timeKeeper: timeKeeper,
            fromDate: fromDate,
            toDate: toDate
        }
    );
    return doc;
}

// tslint:disable-next-line:no-any
function createCell(content: string, key: string, rowSpan: number = 1, colSpan: number = 1, styles?: any) {
    let dataCell: CustomCell = {
        styles: styles,
        rowSpan: rowSpan,
        colSpan: colSpan,
        content: content,
        key: key
    };
    return dataCell;
}

// tslint:disable-next-line:no-any
function toPdfFormat (timeEntry: ImmutableTimeEntry, formatType: PdfFormatType, isMgmtDashboard: boolean, t: any): TimeEntryPdfCell {

    let dur: string = (timeEntry.duration / 3600).toFixed(2).toString();
    let status: string = '';

    const ffCodeEnabled = rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled;
    const actionCodeEnabled = rootStore.appStore.features.EpochConfigActionCodesRequired;
    const isWorkLocaleEnabled = rootStore.appStore.features.EpochConfigWorkLocaleEnabled;
    const isProject = rootStore.appStore.isProject;
    const isApprovalEnabled = rootStore.appStore.isApprovalEnabled;
    
    switch (timeEntry.sapStatus) {
        case SapStatus.QUEUED:
            status = t('export.pdf.table.column.status.posted');
            break;
        case SapStatus.SUBMITTED:
            status = t('export.pdf.table.column.status.posted');
            break;
        case SapStatus.UNSUBMITTED:
            status = t('export.pdf.table.column.status.draft');
            break;
        default:
            status = '';
    }
    let format: TimeEntryPdfCell = {
        office: parseCode(timeEntry.office, timeEntry.officeName),
        phase: parseCode(timeEntry.phaseName, timeEntry.phaseDesc),
        task: parseCode(timeEntry.taskCode, timeEntry.taskCodeDesc),
        activity: parseCode(timeEntry.actCode, timeEntry.actCodeDesc),
        duration: dur,
        status: status
    };

    if (isMgmtDashboard && formatType !== PdfFormatType.TIMEKEEPER) {
        format.timekeeper = parseCode(timeEntry.timeKeeperId, timeEntry.timeKeeperName);
    }
    if (formatType === PdfFormatType.MATTERTYPE) {
        format.workdate = DateTime.fromISO(timeEntry.workDateTime).toFormat('DDDD');
        format.client = parseCode(timeEntry.clientNumber, timeEntry.clientName);
    } else if (formatType === PdfFormatType.DATETYPE) {
        format.client = parseCode(timeEntry.clientNumber, timeEntry.clientName);
        format.matter = parseCode(timeEntry.matterNumber, timeEntry.matterName);
    } else if (formatType === PdfFormatType.CLIENTTYPE) {
        format.workdate = DateTime.fromISO(timeEntry.workDateTime).toFormat('DDDD');
        format.matter = parseCode(timeEntry.matterNumber, timeEntry.matterName);
    } else if (isMgmtDashboard && formatType === PdfFormatType.TIMEKEEPER) {
        format.workdate = DateTime.fromISO(timeEntry.workDateTime).toFormat('DDDD');
        format.client = parseCode(timeEntry.clientNumber, timeEntry.clientName);
        format.matter = parseCode(timeEntry.matterNumber, timeEntry.matterName);
    }
    if (isWorkLocaleEnabled) {
        format.workLocale = parseWorkLocale(rootStore.timeEntryStore.allWorkLocales, timeEntry.workLocaleId);
    }
    if (ffCodeEnabled) {
        format.ffTask = parseCode(timeEntry.ffTaskCode, timeEntry.ffTaskCodeDesc);
        format.ffAct = parseCode(timeEntry.ffActCode, timeEntry.ffActCodeDesc);
    }
    if (actionCodeEnabled) {
        format.action = parseCode(timeEntry.actionCode, '');
    }
    if (isProject) {
        format.billable = parseBillable(timeEntry.billable);
    }
    if (isApprovalEnabled) {
        let approvalStatus: string = '';
        switch (timeEntry.approvalStatus) {
            case ApprovalStatus.UNREVIEWED:
                approvalStatus = t('export.pdf.table.column.approval_status.unreviewed');
                break;
            case ApprovalStatus.APPROVED:
                approvalStatus = t('export.pdf.table.column.approval_status.approved');
                break;
            case ApprovalStatus.REJECTED:
                approvalStatus = t('export.pdf.table.column.approval_status.rejected');
                break;
            default:
                approvalStatus = '';
        }
        format.approvalStatus = approvalStatus;
        format.rejectionCode = parseCode(timeEntry.rejectionCode, timeEntry.rejectionDesc);
    }
    return format;
}

// tslint:disable-next-line:no-any
function exportDataForPdf(timeEntriesMap: Map<string, ImmutableTimeEntry[]>, formatType: PdfFormatType, doc: any, isMgmtDashboard: boolean, t: any) {
    let timeEntries = [...timeEntriesMap.entries()];
    let data: (CustomCell[] | TimeEntryPdfCell)[] = [];
    let finalTotals: number = 0;
    const ffCodeEnabled = rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled;
    const actionCodeEnabled = rootStore.appStore.features.EpochConfigActionCodesRequired;
    const isWorkLocaleEnabled = rootStore.appStore.features.EpochConfigWorkLocaleEnabled;
    const isProject = rootStore.appStore.isProject;
    const isApprovalEnabled = rootStore.appStore.isApprovalEnabled;
    let narrativeColSpan = 8;
    if (isMgmtDashboard) {
        narrativeColSpan++;
    }
    if (ffCodeEnabled) {
        narrativeColSpan += 2;
    }
    if (actionCodeEnabled) {
        narrativeColSpan++;
    }
    if (isProject) {
        narrativeColSpan++;
    }
    if (isApprovalEnabled) {
        narrativeColSpan += 2;
    }
    let totalFrontColSpan = 4;
    if (isWorkLocaleEnabled) {
        narrativeColSpan++;
        totalFrontColSpan++;
    }
    if (isMgmtDashboard) {
        totalFrontColSpan++;
    }
    let totalBackColSpan = narrativeColSpan - 7;

    timeEntries.map(([headerStr, entries]) => {
        data.push([createCell(headerStr, 'grpheader', 1, narrativeColSpan, 
            {'fillColor': [134, 128, 128], 'textColor': [255, 255, 255], 'fontSize': REP_FONT_SIZE, 'halign': 'center'})]);
        entries.forEach((timeEntry) => {
            data.push(toPdfFormat(timeEntry, formatType, isMgmtDashboard, t));
            if (timeEntry.narrative) {
                let txt = doc.splitTextToSize(timeEntry.narrative, 330);
                for (let i = 0; i < txt.length; i = i + 2) {
                    let txt1 = txt[i];
                    let txt2 = `${txt[i + 1] ? txt[i + 1] : ''}`;
                    data.push([createCell(`${txt1} ${txt2 ? '\n' + txt2 : ''}`, 'narrative', 1, narrativeColSpan,
                        {'fillColor': [220, 220, 220]})]);
                } 
            }
        });
        let totalRowKey: string = '';
        const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
        if (formatType === PdfFormatType.DATETYPE) { 
            totalRowKey = t('export.pdf.table.row.total.daily');
        } else if (formatType === PdfFormatType.MATTERTYPE) { 
            totalRowKey = t('export.pdf.table.row.total.matter', {matterLabel});
        } else if (formatType === PdfFormatType.CLIENTTYPE) { 
            totalRowKey = t('export.pdf.table.row.total.client');
        } else if (isMgmtDashboard && formatType === PdfFormatType.TIMEKEEPER) {
            totalRowKey = t('export.pdf.table.row.total.timekeeper');
        }
        data.push([createCell('',  'final_total_extra', 1, totalFrontColSpan),
            createCell(totalRowKey, formatType.toLowerCase(), 1, 2, {'fontStyle': 'bold', 'halign': 'center'}),
            createCell(getDailyTotals(entries).toFixed(2), totalRowKey + 'text', 1, 1, {'fontStyle': 'bold'}),
            createCell('', totalRowKey + 'extra', 1, totalBackColSpan)]);
        finalTotals = getGrandTotals(timeEntriesMap);
    });
    data.push([createCell('',  'final_total_extra', 1, totalFrontColSpan),
        createCell(t('export.pdf.table.row.footer.final_total'), 'final_total', 1, 2, {'fontStyle': 'bold', 'halign': 'center'}),
        createCell(finalTotals.toFixed(2), 'final_total_text', 1, 1, {'fontStyle': 'bold'}),
        createCell('',  'final_total_extra', 1, totalBackColSpan)]);
    return data;
}

// tslint:disable-next-line:no-any
function getColumnsForPDf(formatType: PdfFormatType, isMgmtDashboard: boolean, t: any) {
    let header: TimeEntryPdfHeader[] = [];
    const ffCodeEnabled = rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled;
    const actionCodeEnabled = rootStore.appStore.features.EpochConfigActionCodesRequired;
    const isWorkLocaleEnabled = rootStore.appStore.features.EpochConfigWorkLocaleEnabled;
    const isProject = rootStore.appStore.isProject;
    const isApprovalEnabled = rootStore.appStore.isApprovalEnabled;
    const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
    const translationKey = 'export.pdf.table.row.header';
    if (isMgmtDashboard && formatType !== PdfFormatType.TIMEKEEPER) {
        header.push(new TimeEntryPdfHeader(t(`${translationKey}.timekeeper`), 'timekeeper'));
    }
    if (formatType === PdfFormatType.DATETYPE) {
        header.push(new TimeEntryPdfHeader(t(`${translationKey}.client`), 'client'));
        header.push(new TimeEntryPdfHeader(t(`${translationKey}.matter`, { matterLabel }), 'matter'));
    } else if (formatType === PdfFormatType.CLIENTTYPE) {
        header.push(new TimeEntryPdfHeader(t(`${translationKey}.date`), 'workdate'));
        header.push(new TimeEntryPdfHeader(t(`${translationKey}.matter`, { matterLabel }), 'matter'));
    } else if (formatType === PdfFormatType.MATTERTYPE) {
        header.push(new TimeEntryPdfHeader(t(`${translationKey}.date`), 'workdate'));
        header.push(new TimeEntryPdfHeader(t(`${translationKey}.client`), 'client'));
    } else if (isMgmtDashboard && formatType === PdfFormatType.TIMEKEEPER) {
        header.push(new TimeEntryPdfHeader(t(`${translationKey}.date`), 'workdate'));
        header.push(new TimeEntryPdfHeader(t(`${translationKey}.client`), 'client'));
        header.push(new TimeEntryPdfHeader(t(`${translationKey}.matter`, { matterLabel }), 'matter'));
    }
    header.push(
        new TimeEntryPdfHeader(t(`${translationKey}.office`), 'office'),
        new TimeEntryPdfHeader(t(`${translationKey}.phase`), 'phase'),
        new TimeEntryPdfHeader(t(`${translationKey}.task`), 'task'),
        new TimeEntryPdfHeader(t(`${translationKey}.activity`), 'activity'),
        new TimeEntryPdfHeader(t(`${translationKey}.hours`), 'duration'),
        new TimeEntryPdfHeader(t(`${translationKey}.status`), 'status')
    );

    if (isWorkLocaleEnabled) {
        const offset = isMgmtDashboard ? 4 : 3;
        header.splice(offset, 0,
            new TimeEntryPdfHeader(t(`${translationKey}.work_location`), 'workLocale')
        );
    }

    if (ffCodeEnabled) {
        header.push(
            new TimeEntryPdfHeader(t(`${translationKey}.ff_task`), 'ffTask'),
            new TimeEntryPdfHeader(t(`${translationKey}.ff_activity`), 'ffAct')
        );
    }
    if (actionCodeEnabled) {
        header.push(
            new TimeEntryPdfHeader(t(`${translationKey}.action_code`), 'action')
        );
    }
    if (isProject) {
        header.push(
            new TimeEntryPdfHeader(t(`${translationKey}.billable`), 'billable')
        );
    }
    if (isApprovalEnabled) {
        header.push(
            new TimeEntryPdfHeader(t(`${translationKey}.approval_status`), 'approvalStatus'),
            new TimeEntryPdfHeader(t(`${translationKey}.rejection_code`), 'rejectionCode')
        );
    }

    return header;
}

function columnStyles (width: 'auto' | 'wrap' | number): Styles {
    let customStyle: Styles = {
        cellWidth: width,
        overflow: 'linebreak',
        minCellHeight: TEXT_FONT_SIZE
    };
    return customStyle;
}

export function getDailyTotals(entries: ImmutableTimeEntry[]): number {
    return entries.reduce((prev, cur) => (prev + cur.duration), 0) / 3600;
}

export function getGrandTotals(timeEntriesMap: Map<string, ImmutableTimeEntry[]>): number {
    let grandTotal: number = 0;
    [...timeEntriesMap.entries()].forEach(([header, timeEntries]) => {
        grandTotal = grandTotal + getDailyTotals(timeEntries);
    });
    return grandTotal;
}
