import NarrativeAPI from 'api/interfaces/NarrativeAPI';
import BaseElectronImplementation from './Base.impl';
import { Narrative } from 'api/types/types';
import { ApiResult } from '../../util';
import { NarrativeI } from './Dexie';
import logger from '../../../logging/logging';

export default class NarrativeImpl extends BaseElectronImplementation implements NarrativeAPI {
    AllNarratives: NarrativeI[] = [];
    FilteredNarratives: NarrativeI[] = [];
    handlers: (((entries: Narrative[]) => void) | null)[] = [];
    registerReciever = (handler: (narratives: Narrative[]) => void) => {
        this.AllNarratives = [];
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return () => {
            this.handlers[theIndex] = null;
        };
    }
    recieve = async (narratives: Narrative[]): Promise<void> => {
        const promArray = narratives.map((te) => this.root.db.narratives.get({ id: te.id! }));
        let localTes = await Promise.all(promArray);
        narratives.forEach((te, idx) => {
            if (localTes[idx]) {
                // tslint:disable-next-line: no-any
                (te as any).localId = localTes[idx]!.localId;
            }
        });
        await this.root.db.narratives.bulkPut(narratives);
        let tes = narratives.map(te => Object.assign(new Narrative(), te));
        this.handlers.filter(h => h !== null).forEach(h => h!(tes));
    }

    async getAllNarratives(offset?: number, limit?: number, search?: string) {
        try {
            const length = this.AllNarratives.length;
            const start = offset !== undefined ? offset : 0;
            const end = start + (limit !== undefined ? limit : Infinity);

            if (length === 0 || offset === undefined) {
                this.AllNarratives = await this.readAllNarratives();
                this.AllNarratives = await this.sortNarratives();
            }
            if (search && start === 0) {
                this.FilteredNarratives = this.AllNarratives.filter( n => n.key.toLowerCase().includes(search.toLowerCase())
                                            || n.replacement.toLowerCase().includes(search.toLowerCase()));
            }

            const narratives = search ? this.FilteredNarratives : this.AllNarratives;

            return Promise.all(narratives.slice(start, end));
        } catch (e) {
            logger.error('Narratives, Error fetching all narratives', e);
            throw e;
        }
    }

    async readAllNarratives() {
        try {
            return (await this.root.db.narratives.where('deleted').equals(0).toArray())
                .map((nar) => Object.assign(new Narrative(), { ...nar, deleted: !!nar.deleted }));
        } catch (e) {
            logger.error('Narratives, Error i reading all narratives', e);
            throw e;
        }
    }

    sortNarratives = async() => {
        const localNarratives = this.AllNarratives.filter(n => !n.global)
            .sort((a, b) => a.key.localeCompare(b.key));
        const globalNarratives = this.AllNarratives.filter(n => n.global)
            .sort((a, b) => a.key.localeCompare(b.key));

        return [...localNarratives, ...globalNarratives];
    }

    async getNarrative(id: number) {
        try {
            return Object.assign(new Narrative(), (await this.root.db.narratives.get(id))!);
        } catch (e) {
            logger.error('Narratives, Error fetching a narrative', e);
            throw e;
        }
    }
    getWriteableId = async (entry: Narrative): Promise<number | undefined> => {
        try {
            if (!entry.id) {
                return undefined;
            }
            if (entry.id < 0) {
                return entry.id * -1;
            }
            return (await this.root.db.narratives.get({id: entry.id}))!.localId;
        } catch (e) {
            logger.error('Narratives, Error getting writable id', e);
            throw e;
        }
    }
    trySaveOne = async (nar: NarrativeI): Promise<ApiResult<Narrative>> => {
        try {
            let insertKey = await this.getWriteableId(nar);

            let writeableEntry = JSON.parse(JSON.stringify(nar)) as NarrativeI;
            if (insertKey) {
                writeableEntry.localId = insertKey;
            }
            let deletedNarrativeCodes = await this.root.db.narratives.filter(nt => nt.deleted).toArray();
            const indx = deletedNarrativeCodes.findIndex(ni => ni.key.toLowerCase() === writeableEntry.key.toLowerCase());
            // only set localId for entries with no localId, to enable editing an existing code to be same as a previously deleted code.
            if (indx >= 0 && !insertKey) {
                writeableEntry.localId = deletedNarrativeCodes[indx].localId;
                writeableEntry.deleted = false;
            }
            writeableEntry.serverDirty = true;
            let localId = await this.root.db.narratives.put(writeableEntry);
            let safeEntry = (await this.root.db.narratives.get(localId))!;
            return {
                status: {
                    failed: false,
                    message: 'Success'
                },
                object: Object.assign(new Narrative(), safeEntry)
            };
        } catch (e) {
            logger.error('Narratives, Error saving narrative', e);
            return {
                status: {
                    failed: true,
                    message: 'Failed save'
                },
                object: nar
            };
        }
    }
    write = async () => {
        let dirtyEntries = await this.root.db.narratives.filter(te => te.serverDirty || false).toArray();
        if (dirtyEntries.length === 0) {
            return;
        }
        const emitEntries: Narrative[] = [];
        const toWrite = dirtyEntries.map(te => {
            let newte = Object.assign(new Narrative(), te);
            if (newte.id! < 0) {
                newte.id = undefined;
            }
            return newte;
        });
        const results = await this.root.webImpl.Narrative.updateNarratives(toWrite);
        // tslint:disable-next-line:no-any
        let proms: Promise<any>[] = [];
        for (let i = 0; i < results.length; i++) {
            let curRes = results[i];
            let localEntry = dirtyEntries[i]!;
            if (curRes.status.failed) {
                // failed write, do something
            } else {
                (curRes.object as NarrativeI).localId = localEntry.localId;
                proms.push(this.root.db.narratives.put(curRes.object));
                if (localEntry.id! < 0) {
                    localEntry.deleted = true;
                    // id less than 0, only local 
                    emitEntries.push( Object.assign(new Narrative(), localEntry));
                }
                emitEntries.push( Object.assign(new Narrative(), curRes.object));
            }
        }
        await Promise.all(proms);
        this.handlers.filter(h => h !== null).forEach(h => h!(emitEntries));
    }
    async saveNarrative(n: Narrative) {
        let resp = await this.trySaveOne(n);
        this.root.Session.write();
        if (!resp.status.failed) {
            this.AllNarratives = await this.readAllNarratives();
            this.AllNarratives = await this.sortNarratives();
            return resp.object;
        } else {
            throw resp.status.message;
        }
    }
}