import * as React from 'react';
import ManagementDashboard from './ManagementDashboard';

function DraftTime() {

    return (
        <ManagementDashboard draftTime={true} />
    );
}

export default DraftTime;