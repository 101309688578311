import BaseWebImplementation from './Base.impl';
import SupersetSessionAPI from 'api/interfaces/SupersetSessionAPI';
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

declare module 'axios' {
    export interface AxiosRequestConfig {
        skipRequestIntercept?: boolean;
    }
}

export default class SupersetSessionImpl extends BaseWebImplementation implements SupersetSessionAPI {
    axios: AxiosInstance;

    initialize = async () => {
        this.axios = Axios.create({
            baseURL: localStorage.getItem('reportingBaseUrl') || '',
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        });

        let accessToken = localStorage.getItem('superset-access-token');
        if (!accessToken) {
            await this.login();
        } else {
            this.axios.defaults.headers.Authorization =
                'Bearer ' + localStorage.getItem('superset-access-token');
        }

        await this.checkTokenExpiration();
        this.axios.interceptors.request.use(this.requestInterceptor);
    };

    // checks if access or refresh token is about to expire before every request
    private requestInterceptor = async (config: AxiosRequestConfig) => {
        if (!config.skipRequestIntercept) {
            await this.checkTokenExpiration();
        }
        
        return config;
    };

    private checkTokenExpiration = async () => {
        let refreshToken = localStorage.getItem('superset-refresh-token');
        let accessToken = localStorage.getItem('superset-access-token');
        if (refreshToken) {
            let parsedJWT = JSON.parse(
                Buffer.from(refreshToken.split('.')[1], 'base64').toString()
            );
            if (Date.now() >= parsedJWT.exp * 1000) {
                // refresh token expired. login again
                await this.login();
            }
        }

        if (accessToken) {
            let parsedJWT = JSON.parse(
                Buffer.from(accessToken.split('.')[1], 'base64').toString()
            );
            if (Date.now() >= parsedJWT.exp * 1000) {
                // access token expired. get new token
                await this.getNewAccessToken();
            }
        }
    };

    private login = async () => {
        await this.root.Report.getToken();
        this.axios.defaults.headers.Authorization = 'Bearer ' + localStorage.getItem('superset-access-token');
    };

    private async getNewAccessToken() {
        this.axios.defaults.headers.Authorization = 'Bearer ' + localStorage.getItem('superset-refresh-token');
        let response = await this.axios.post('/security/refresh', undefined, {
            skipRequestIntercept: true
        });
        if (response.status === 200) {
            localStorage.setItem('superset-access-token', response.data.access_token);
            this.axios.defaults.headers.Authorization = 'Bearer ' + response.data.access_token;
        }
    }
}
