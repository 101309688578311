import { action, computed, observable, makeObservable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import ImmutableTimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { DateTime } from 'luxon';
import Template from 'api/immutables/ImmutableTemplate';
import ImmutableTemplate from 'api/immutables/ImmutableTemplate';
import {
    ValidatePost,
    ValidateSave,
    ValidateTemplate,
    ValidationState,
    ValidationTemplateState
} from 'api/immutables/validators';
import DialogRootStore from 'store/dialog.root.store';
import { CodeSetFlags, TimeEntryType, LocalStorageWorkLocale, ApprovalStatus, TimeKeeperAssignment } from 'api/types/types';
import { RootStore } from './root.store';
import { debounce } from 'typescript-debounce-decorator';
import { ApiResult } from '../api/util';
import { Platform } from '../util/Platform';
import logger from '../logging/logging';

// tslint:disable-next-line:no-any
export default class TimeEntryDialogStore extends DialogRootStore<any, any> {
    @observable entry: TimeEntry;
    @observable templateName: string = '';
    @observable selectedTemplate?: Template | null;
    @observable createAnotherFlag: boolean = false;
    @observable validationState?: ValidationState;
    @observable durationValidationState?: boolean;
    @observable templateValidationState?: ValidationTemplateState;
    @observable saving: boolean = false;
    @observable disableCreateAnother: boolean | undefined = false;
    @observable narrativeText: string | null = '';
    @observable auditLog: TimeEntry[] = [];
    @observable auditLogIndex: number = -1;
    isMgmtDashboard?: boolean;

    @computed get minNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMinimumChars;
    }
    @computed get maxNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMaximumChars;
    }
    
    createHandler?: (timeEntry: TimeEntry) => void;
    saveHandler?: (results: ApiResult<ImmutableTimeEntry>[]) => void;
    
    constructor(rs: RootStore) {
        super(rs);
        makeObservable(this);
        this.wrappedSave = this.wrappedSave.bind(this);
        this.wrappedPost = this.wrappedPost.bind(this);
    }
    
    open = (input?: TimeEntry, createHandler?: (timeEntry: TimeEntry) => void, disableFeature?: boolean,
            saveHandler?: (results: ApiResult<ImmutableTimeEntry>[]) => void, isMgmtDashboard?: boolean): Promise<{}> => {
        this.createHandler = createHandler;
        this.disableCreateAnother = disableFeature;
        this.isMgmtDashboard = !!isMgmtDashboard;
        this.saveHandler = saveHandler;
        return super.open(input);
    }

    @loadable()
    @action.bound 
    async onOpen(entry: TimeEntry) {
        try {
            if (!entry.timeEntryType) {
                entry.timeEntryType = TimeEntryType.NORMAL;
            }
            this.entry = entry;
            this.narrativeText = entry.narrative;
            if (this.entry.id) {
                this.createAnotherFlag = false;
            }
            if (!this.isMgmtDashboard && !this.entry.id) {
                this.entry.workLocaleId = this.rootStore.timeEntryStore.getTkWorkLocale();
            }
            this.validationState = undefined;
            if (entry.id && entry.isPosted() && entry.status === 'REVERSE SYNC') {
                this.auditLog = await this.rootStore.api.TimeEntry.getAuditLog!(entry.id);
                this.auditLogIndex = this.auditLog.length - 1;
                this.entry = Object.assign(this.entry, this.auditLog[this.auditLogIndex]);
            } else {
                this.auditLog = [];
                this.auditLogIndex = -1;
            }
        } catch (e) {
            logger.info('Time Entries, Setting Entry failed.\n', e);
            throw e;
        }
    }
    
    @action.bound
    setAuditLogEntry(ind: number) {
        const status = this.entry.status;
        this.auditLogIndex = ind;
        this.entry = Object.assign(this.entry, this.auditLog[ind]);
        this.entry.status = status;
    }

    @action changeEntry = (entry: TimeEntry, newVState?: ValidationState, durVstate?: boolean) => {
        this.entry = entry;
        this.narrativeText = entry.narrative;
        this.validationState = newVState;
        if (durVstate !== undefined) {
            this.durationValidationState = durVstate;
        }
        this.templateName = (entry.matterId === null) ? '' : this.templateName;
        this.templateValidationState = undefined;
    }
    
    @action.bound 
    setFieldLoaderFn(value: boolean) {
        this.saving = value;
    }
    
    @action toggleCreateAnotherFlag = () => {
        this.createAnotherFlag = !this.createAnotherFlag;
    }
    @debounce(500, {leading: false})
    @action 
    async wrappedPost() {
        if (this.saving) {
            return;
        }
        this.saving = true;
        await this.postEntry();
        this.saving = false;
    }
    @debounce(500, {leading: false})
    @action.bound
    async wrappedUnpost() {
        if (this.saving) {
            return;
        }
        this.saving = true;
        await this.unpostEntry();
        this.saving = false;
    }
    @debounce(500, {leading: false})
    @action 
    async wrappedSave() {
        if (this.saving) {
            return;
        }
        this.saving = true;
        await this.saveEntry();
        this.saving = false;
    }
    @debounce(500, {leading: false})
    @action.bound
    async wrappedApprove() {
        if (this.saving) {
            return;
        }
        this.saving = true;
        await this.approveEntry();
        this.saving = false;
    }
    @debounce(500, {leading: false})
    @action.bound
    async wrappedReject() {
        if (this.saving) {
            return;
        }
        this.saving = true;
        await this.rejectEntry();
        this.saving = false;
    }
    @loadable()
    @action.bound
    async approveEntry() {
        const { approveEntries, getProjectEntries, getDraftEntries} = this.rootStore.managementDashboardStore;
        const approvalStatus = this.entry.approvalStatus;
        this.entry.approvalStatus = ApprovalStatus.APPROVED;
        // use approveEntries for entry from Project Time that is not edited, otherwise use postEntry
        if (this.entry.isPosted() && !this.entry.dirty) {
            await approveEntries([this.entry.id!]);
            this.resolveAndClose(null);
        } else {
            const projectTime = !this.entry.id || this.entry.isPosted();
            await this.postEntry();
            if (this.entry.isDraft()) {
                this.entry.approvalStatus = approvalStatus;
            }
            projectTime ? getProjectEntries() : getDraftEntries();
        }
    }
    @loadable()
    @action.bound
    async rejectEntry() {
        const res = await this.rootStore.managementDashboardStore.rejectEntries([this.entry.id!], true);
        if (res) {
            this.resolveAndClose(this.entry);
        }
    }
    @loadable()
    @action.bound
    async postEntry() {
        try {
            let matterEntryType: string = '';
            let matterStatusDesc: string = '';
            let narrativeMinLength;
            let narrativeMaxLength;
            if (this.entry.matterId) {
                const matter = await this.rootStore.api.Matter.get(this.entry.matterId);
                if (matter) {
                    matterEntryType = matter.entryType;
                    matterStatusDesc = matter.statusDescription;
                    narrativeMinLength = matter.minLength;
                    narrativeMaxLength = matter.maxLength;
                    this.entry.bannedWords = matter.bannedWords;
                    this.entry.blockBillingWords = matter.blockBillingWords;
                }
            }

            let activeTimeKeeper;
            if (this.isMgmtDashboard && this.entry.userId) {
                activeTimeKeeper = await this.rootStore.managementDashboardStore.getActiveTkForUser(this.entry);
            } else {
                activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(this.entry.workDateTime));
            }
            // todo 24 hour validation
            let vstate = ValidatePost(
                this.entry,
                await this.getTotalDurationExclusive(this.entry.workDateTime, this.entry.id!),
                matterStatusDesc,
                matterEntryType,
                this.rootStore.appStore.features,
                activeTimeKeeper,
                narrativeMinLength,
                narrativeMaxLength,
                this.isMgmtDashboard
            );
            let template: ImmutableTemplate | undefined;
            if (this.templateName.trim().length > 0) {
                // TODO validate template
                template = this.entry.createTemplate();
                template.name = this.templateName;
                let templateValidationState = ValidateTemplate(
                    template,
                    await this.rootStore.api.Template.getAllTemplates(),
                    this.maxNarrativeLength
                );
                if (!templateValidationState.valid) {
                    this.templateValidationState = templateValidationState;
                    return;
                }
            }
            // if (Platform.isElectron()) {
            //     vstate = await this.validateCodeSets(this.entry, vstate);
            // }
            if (vstate.valid && !this.durationValidationState) {
                this.entry = this.entry.setPosted();
                await this.saveEntry();
                this.rootStore.homeStore.setTimersForDay();
                return;
            }
            if (!vstate.valid) {
                this.validationState = vstate;
            }
        } catch (e) {
            logger.info('Time Entries, Posting Entry failed.\n', e);
            throw e;
        }
    }
    @loadable()
    @action.bound
    async unpostEntry() {
        const store = window.location.hash.includes('home') ? 'homeStore' : 'timeEntryStore';
        const res = await this.rootStore[store].unpostEntries([this.entry.id!]);
        if (res) {
            this.entry.sapStatus = SapStatus.UNSUBMITTED;
        }
    }
    @action validateCodeSets = async(entry: TimeEntry, vstate: ValidationState) => {
        try {
            if (entry.phaseId) {
                let phaseCode = await this.rootStore.api.Code.get(entry.phaseId);
                if (phaseCode && phaseCode.deleted) {
                    vstate.missing.phase = true;
                }
            }
            if (entry.taskCodeId) {
                let taskCode = await this.rootStore.api.Code.get(entry.taskCodeId);
                if (taskCode && taskCode.deleted) {
                    vstate.missing.task = true;
                }
            }
            if (entry.actCodeId) {
                let actCode = await this.rootStore.api.Code.get(entry.actCodeId);
                if (actCode && actCode.deleted) {
                    vstate.missing.activity = true;
                }
            }
            if (this.rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled) {
                if (entry.ffTaskCodeId) {
                    let ffTaskCode = await this.rootStore.api.Code.get(entry.ffTaskCodeId);
                    if (ffTaskCode && ffTaskCode.deleted) {
                        vstate.missing.ffTask = true;
                    }
                }
                if (entry.ffActCodeId) {
                    let ffActCode = await this.rootStore.api.Code.get(entry.ffActCodeId);
                    if (ffActCode && ffActCode.deleted) {
                        vstate.missing.ffAct = true;
                    }
                }
            }
            return vstate;
        } catch (e) {
            logger.info('Time Entries, Validating Code Sets failed.\n', e);
            throw e;
        }
    }
    @action setWorkDate = async (date: DateTime) => {
        let actTk: TimeKeeperAssignment | undefined;
        if (this.isMgmtDashboard) {
            this.entry = this.entry.setWorkDate(date);
            actTk = await this.rootStore.managementDashboardStore.getActiveTkForUser(this.entry);
            if (!actTk) {
                this.entry = this.entry.setEmployee(undefined)
                    .setOffice(undefined)
                    .setOfficeName(undefined);
            }
        } else {
            actTk = this.rootStore.appStore.getActiveTimeKeeperForDate(date);
            this.entry = this.entry.setWorkDate(date)
                .setOffice(actTk ? actTk.office : undefined)
                .setOfficeName(actTk ? actTk.officeName : undefined);
        }
        if (this.validationState) {
            this.validationState.invalidWorkDate = false;
        }
    }
    
    @action setTemplateName = (name: string) => {
        this.templateValidationState = undefined;
        this.templateName = name;
    }
    
    @loadable()
    @action.bound
    async setTemplate(t?: Template) {
        try {
            this.selectedTemplate = t;
            if (t) {
                if (t.matter) {
                    const codeSetFlags: CodeSetFlags =
                        await this.rootStore.api.Code.determineCodeSetFields(t.matter.id, this.entry.workDateTime);
                    t.isPhaseCode = codeSetFlags.isPhaseCode;
                    t.isFfTaskCode = codeSetFlags.isFfTaskCode;
                    t.isActCode = codeSetFlags.isActCode;
                    if (t.phaseId && t.taskCode) {
                        let tasks = await this.rootStore.api.Code.getTaskCodes(t.phaseId, this.entry.workDateTime, t.taskCode);
                        this.entry.billable = tasks[0].billable;
                    } else {
                        this.entry.billable = null;
                    }
                }
                this.entry = await this.setTemplateProps(t);
                this.validationState = undefined;
                this.templateValidationState = undefined;
                this.templateName = '';
            } else {
                if (this.entry.narrative !== this.narrativeText) {
                    this.entry.narrative = this.narrativeText!.replace(this.entry.narrative!, '').trim();
                }
            }
        } catch (e) {
            logger.info('Time Entries, Setting Templates failed.\n', e);
            throw e;
        }
    }
    setTemplateProps = async (t: Template) => {
        let te: ImmutableTimeEntry = this.entry.clone();
        te = this.entry.loadFromTemplate(t);
        te.narrative  = [te.narrative, this.narrativeText ].join(' ').trim();
        te.selectedCodeSetTemplate = null;
        return te;
    }
    
    @loadable()
    async getTotalDurationExclusive (workDate: string, id: number) {
        return await this.rootStore.api.TimeEntry.getTotalForDateExclusive(workDate, [id]);
    }

    @loadable()
    @action.bound
    async getInvalidWords(matterId: number) {
        try {
            let invalidWords = await Promise.all([
                this.rootStore.api.Matter.getBannedWords!(matterId),
                this.rootStore.api.Matter.getBlockBillingWords!(matterId)
            ]);
            this.entry.bannedWords = invalidWords[0];
            this.entry.blockBillingWords = invalidWords[1];
        } catch (e) {
            logger.info('Time Entries, Getting Invalid words failed.\n', e);
            throw e;
        }
    }
    
    @loadable()
    @action.bound
    async saveEntry() {
        const { appStore, api, managementDashboardStore, snackbarStore, timeEntryStore } = this.rootStore;
        try {
            let narrativeMinLength;
            let narrativeMaxLength;
            if (this.entry.matterId) {
                const matter = await this.rootStore.api.Matter.get(this.entry.matterId);
                if (matter) {
                    this.entry.bannedWords = matter.bannedWords;
                    this.entry.blockBillingWords = matter.blockBillingWords;
                    narrativeMinLength = matter.minLength;
                    narrativeMaxLength = matter.maxLength;
                }
            }

            const activeTimeKeeper = appStore.getActiveTimeKeeperForDate(DateTime.fromISO(this.entry.workDateTime));

            let vstate = ValidateSave(
                this.entry,
                await this.getTotalDurationExclusive(this.entry.workDateTime, this.entry.id!),
                appStore.features,
                activeTimeKeeper,
                narrativeMinLength,
                narrativeMaxLength
            );
            if (Platform.isElectron()) {
                vstate = await this.validateCodeSets(this.entry, vstate);
            }
            if (!vstate.valid) {
                this.validationState = vstate;
                return;
            }
            if (this.durationValidationState) {
                return;
            }
            let template: ImmutableTemplate | undefined;
            if (this.templateName.trim().length > 0) {
                // TODO validate template
                template = this.entry.createTemplate();
                template.name = this.templateName;
                let templateValidationState = ValidateTemplate(
                    template,
                    await api.Template.getAllTemplates(),
                    this.maxNarrativeLength
                );
                if (!templateValidationState.valid) {
                    this.templateValidationState = templateValidationState;
                    return;
                }
            }
            const role = this.isMgmtDashboard ? managementDashboardStore.selectedRole : undefined;
            const approverTkId = this.isMgmtDashboard ? managementDashboardStore.approvalTimekeeperId : undefined;
            let results = await api.TimeEntry.updateEntries([this.entry], role, approverTkId);
            let result = results[0];

            if (result.status.failed) {
                snackbarStore.triggerSnackbar(result.status.message);
                this.entry.sapStatus = SapStatus.UNSUBMITTED;
                this.entry = this.entry.clone();
                return;
            }
            snackbarStore.triggerSnackbar('app.snackbar.info.saved');

            if (template) {
                await this.rootStore.api.Template.saveTemplate(template);
            }
            let entry = Object.assign(new TimeEntry(), JSON.parse(JSON.stringify(results[0].object)));
            if (!this.isMgmtDashboard) {
                timeEntryStore.setLocalStorageWorkLocale(entry.workLocaleId);
            }
            entry.isActCode = this.entry.isActCode;
            entry.isPhaseCode = this.entry.isPhaseCode;
            entry.isFfTaskCode = this.entry.isFfTaskCode;

            if (this.saveHandler) {
                this.saveHandler(results);
            }

            if (this.createAnotherFlag) {
                // this.rootStore.homeStore.changeEntry(entry);
                if (this.createHandler) {
                    this.createHandler(entry);
                }
                this.createAnotherEntry(entry);
                this.rootStore.timeEntryStore.resetNarrativePreviousPopulatedCodes();
            } else {
                this.resolveAndClose(entry);
            }

            // @onSave is not getting called on clicking on Save. had to explicitly call clear function.
            this.clear();

            return;
        } catch (e) {
            logger.info('Time Entries, Posting Entry failed.\n', e);
            throw e;
        }
    }

    @action.bound
    async createAnotherEntry(oldEntry: TimeEntry) {
        let actTk = this.rootStore.appStore.getActiveTimeKeeperForDate(
            DateTime.fromISO(oldEntry.workDateTime)
        );
        let entry = new TimeEntry()
            .setWorkDate(DateTime.fromISO(oldEntry.workDateTime))
            .setOffice(actTk ? actTk.office : undefined)
            .setOfficeName(actTk ? actTk.officeName : undefined)
            .setClient(oldEntry.client)
            .setMatter(oldEntry.matter)
            .setDuration(0)
            .setActionCode(null)
            .setStatus(SapStatus.UNSUBMITTED)
            .setNarrative('')
            .setWorkLocaleId(oldEntry.workLocaleId);
        entry.timeKeeperId = oldEntry.timeKeeperId;
        if (oldEntry.matter) {
            entry = await this.rootStore.timeEntryStore.setCodeSetFields(oldEntry.matter, entry);
        }
        this.selectedTemplate = undefined;
        this.templateName = '';
        this.changeEntry(entry);
    }
    
    @action
    clear() {
        this.templateName = '';
        this.selectedTemplate = undefined;
        this.durationValidationState = undefined;
        this.templateValidationState = undefined;
        this.rootStore.homeStore.selectedSegments = [];
        this.rootStore.homeStore.selectedTimerSegments = [];
        this.rootStore.homeStore.validationState.clear();
        this.rootStore.timeEntryStore.validationState.clear();
    }
}