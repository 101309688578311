import { action, computed, observable, makeObservable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import BaseStore from 'store/base.store';
import { RootStore } from 'store/root.store';
import { TimeCastSettings } from '../containers/TimeCastSettings/TimeCastSettings';
import { Setting, TimeCastProgram } from '../api/types/types';
import { TimeCast, TimeCastConnectionStatus } from '../util/TimeCast';
import logger from '../logging/logging';
const constants = require('../constants.json');

export const TIMECAST_SETTING_KEY = constants.timecast.settingKey;
export const TIMECAST_IPC_CHANNEL = constants.timecast.ipcChannel;
export const TIMECAST_BACKGROUND_SERVER_PORT = constants.timecast.backgroundServerPort;

export default class TimeCastSettingsStore extends BaseStore {
    // the currently edited version of settings
    @observable current: TimeCastSettings;
    // the original settings
    @observable original: TimeCastSettings;
    // all programs (even those that aren't tracked by timecast)
    @observable allPrograms: TimeCastProgram[] = [];
    
    @observable connected: TimeCastConnectionStatus | undefined;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
        rootStore.api.Settings.registerReceiver(this.receiveSettings);
        rootStore.api.TimeCast.registerReceiverForPrograms(this.receivePrograms);
    }
    /**
     * Called when the user logs in and accepts the EULA!
     */
    @loadable()
    @action
    async init() {
        this.current = this.original;
        this.allPrograms = await this.rootStore.api.TimeCast.allPrograms();

        let setting = (await this.rootStore.api.Settings.getByKey(constants.timecast.settingKey));
        
        let allowedPrograms: string[] = this.allPrograms.map(prog => prog.code);
        
        let defaultSettings = TimeCastSettings.default().setAllowedPrograms(allowedPrograms);
        
        if (!setting) {
            // setting doesn't exists
            const response = await this.rootStore.api.Settings.save({
                key: constants.timecast.settingKey,
                deleted: false,
                global: false,
                value: JSON.stringify(defaultSettings)
            });
            setting = response.object;
        }
        
        // setup for reverting edits
        this.original = TimeCastSettings.fromSetting(setting || {});
        this.current = this.original;

        this.updateConnectionState(0);
    }
    
    @action.bound
    async updateConnectionState(delay: number = 2000) {
        this.connected = undefined;
        try {
            setTimeout(async () => {
                this.connected = await TimeCast.getConnectionStatus();
            }, delay);
        } catch (e) {
            logger.info('Error in getting TimeCast connection status', e);
        }
    }

    @action.bound update(settings: TimeCastSettings) {
        this.current = settings;
    }

    @action.bound
    async connectToTimeCast() {
        this.rootStore.appStore.connectToTimeCast();
        this.updateConnectionState(5000);
    }

    @action.bound
    async disconnectServerFromTimeCast() {
        this.rootStore.appStore.disconnectFromTimeCast('server');
        this.updateConnectionState();
    }

    @action.bound
    async disconnectDesktopFromTimeCast() {
        this.rootStore.appStore.disconnectFromTimeCast('desktop')
        this.updateConnectionState()
    }
    
    /**
     * Saves the current form by calling API
     */
    @loadable()
    @action.bound
    async save() {
        let existingSetting = (await this.rootStore.api.Settings.getByKey(TIMECAST_SETTING_KEY));
        let settingId: number | undefined;
        if (existingSetting && typeof existingSetting.id === 'number') {
            settingId = existingSetting.id;
        }
        
        try {
            const setting = this.current.toSetting({ id: settingId });

            await this.rootStore.api.Settings.save({
                ...setting,
                lastModified: (new Date().toISOString())
            });

            this.original = this.current;
            this.rootStore.homeStore.timeCastSettings = this.original;
            this.rootStore.snackbarStore.triggerSnackbar('app.snackbar.info.saved');
            
            this.rootStore.appStore.updateTimeCast()
        } catch (e) {
            logger.error('Error saving TimeCast settings', e);
            throw e;
        }
    }

    /**
     * This computes whether the current form is different from the original form.
     */
    @computed get dirty(): boolean {
        return this.current !== this.original;
    }

    /**
     * This is called when the user wants to revert any changes that were made.
     */
    @action.bound reset(): void {
        this.current = this.original;
    }
    
    private receiveSettings = (settings: Setting[]) => {
        settings.forEach(s => {
            if (s.key === constants.timecast.settingKey) {
                // if they are editing stuff, don't change the current draft
                if (!this.dirty) {
                    // otherwise, update the settings live!
                    this.original = TimeCastSettings.fromSetting(s);
                    this.current = this.original;
                } else {
                    this.original = TimeCastSettings.fromSetting(s);
                }
            }
        });
    };

    private receivePrograms = (programs: TimeCastProgram[]) => {
        programs.forEach(program => {
            let found = false;
            for (let i = 0; i < this.allPrograms.length; i++) {
                const existingProgram = this.allPrograms[i];
                if (existingProgram.code === program.code) {
                    this.allPrograms[i] = program;
                    found = true;
                }
            }
            if (!found) {
                this.allPrograms.push(program);
            }
        });
    };
    
}