// tslint:disable:no-any
import BaseElectronImplementation from './Base.impl';
import SessionAPI from 'api/interfaces/SessionAPI';
import {
    EULA,
    Features,
    LoginMode,
    OfflineItems,
    TimeKeeperAssignment,
    TimerChunk,
    TkGoals,
    User,
    WorkLocale
} from 'api/types/types';
import { AxiosInstance } from 'axios';
import { SyncResponse } from './SyncResponse';
import WebSessionImpl from '../web/Session.impl';
import ElectronRootImpl from './Root.impl';
import ImmutableTimeEntry from 'api/immutables/ImmutableTimeEntry';
import ImmutableTemplate from 'api/immutables/ImmutableTemplate';
import Mutex from 'api/util';
import { DateTime } from 'luxon';
import ImmutableTimer from '../../immutables/ImmutableTimer';
import { MatterWordsMapping, TimeCastSegmentI } from './Dexie';
import { FileSaver } from '../../../util/ExportToExcel';
import XLSX from 'xlsx';
import logger from '../../../logging/logging';
import { fileNameForExport } from '../../../util/utils';
import Papa from 'papaparse';

let IDBExportImport = require('indexeddb-export-import');
let JSZip = require('jszip');
let zip = new JSZip();

export default class SessionImpl extends BaseElectronImplementation implements SessionAPI {
    writeMutex = new Mutex();
    onlineHandler?: (online: boolean) => void;
    pendingItemHandlers: (((entries: OfflineItems | undefined) => void) | null )[] = [];
    updatedTKHandlers: (((timeKeepers: TimeKeeperAssignment[]) => void) | null )[] = [];

    constructor(root: ElectronRootImpl) {
        super(root);
        this.webSession.onlineStatusChange = this.statusChange;
        this.webSession.syncHandler = this.onSync;
    }

    syncStatusProgressListener: (message: string, progress?: number, option?: {}) => void = () => {
        /* this function gets set by `setProgressListener` */
    }

    setProgressListener = (listener: (message: string, progress?: number, option?: {}) => void) => {
        this.syncStatusProgressListener = listener
        this.webSession.syncStatusProgressListener = listener
    }

    setOnlineStatusChangeHandler = (handler: (online: boolean) => void ) => {
        this.onlineHandler = handler;
    }

    onSync = (sync: SyncResponse) => {
        this.saveSyncResponse(sync);
        // todo
    }

    get currentTimeKeeper(): number | undefined {
        return this.webSession.currentTimeKeeper;
    }

    get axios(): AxiosInstance {
        return this.root.webImpl.Session.axios;
    }

    get webSession(): WebSessionImpl {
        return this.root.webImpl.Session;
    }

    setServer = async (url: string, wsUri: string) => {
        await this.root.webImpl.Session.setServer(url, wsUri);
    }

    silentSSOLogin = async (features: Features) => {
        // if SSO fall back feature is enabled, then login directly using kerberos
        if (features) {
            if (features.EpochConfigLoginMode === LoginMode.EAGER_SSO_FALLBACK) {
                try {
                    if (features.EpochConfigKerberosEnabled) {
                        await this.webSession.kerberosLogin();
                        this.setRetentionDates();
                    } else {
                        await this.ssoEntry();
                    }
                } catch (e) {
                    logger.error(e);
                    throw e;
                }
            }
        }
    }

    setRetentionDates() {
        const features = JSON.parse(localStorage.getItem('features') || '{}');
        if (features) {
            const { EpochConfigTimeCastSegmentsRetentionDays, EpochConfigTimeCastSegmentsFetchDays } = features;
            const segmentsOnGetAll = DateTime.local()
                .minus({days: EpochConfigTimeCastSegmentsFetchDays})
                .startOf('day')
                .toISO();
            const retainUpto = DateTime.local()
                .minus({days: EpochConfigTimeCastSegmentsRetentionDays})
                .startOf('day')
                .toISO();
            localStorage.setItem('tcSegmentsFrom', segmentsOnGetAll);
            localStorage.setItem('tcSegmentsRetentionDays', retainUpto);
        }
        const from = DateTime.local().minus({ months: 2 }).startOf('month');
        localStorage.setItem('retentionFromDate', JSON.stringify(from));
    }

    get serverSet() {
        return !!localStorage.getItem('serverUrl');
    }

    // after this runs the api should be ready to handle calls.
    logIn = async (user: string, password: string) => {
        try {
            await this.root.webImpl.Session.logIn(user, password);
            this.setRetentionDates();
            this.webSession.loginInProgress = false;
        } catch (e) {
            // logger.info('Login failed: ', e)
            throw e;
        }
    }

    syncLastPushNotification = () => {
        this.webSession.syncLastPushNotification();
    }

    ssoLogin = async (token: string) => {
        try {
            await this.root.webImpl.Session.ssoLogin(token);
            this.setRetentionDates();
        } catch (e) {
            // logger.error('ssoLogin error: ', e)
            throw e;
        } finally {
            this.webSession.loginInProgress = false;
        }
    }

    logOut = async () => {
        try {
            await this.webSession.logOut();
            localStorage.removeItem('retentionFromDate');
            localStorage.removeItem('tcDatesBfrRetentionDateArray')
            await this.exportTimeEntriesData();
            this.root.deleteDatabase();
        } catch (e) {
            // logger.info('Logout error info: ', e);
            throw e;
        } finally {
            return;
        }
    }

    initialize = async (): Promise<boolean | 'needs-soft-login'> => {
        this.syncStatusProgressListener('app.login.progress.desktop.checking_tokens')
        const token = localStorage.getItem('token');
        const refToken = localStorage.getItem('refreshToken');
        const user = localStorage.getItem('user');

        localStorage.removeItem('tcDatesBfrRetentionDateArray');

        if (!token || !refToken) {
            if (!user) {
                this.syncStatusProgressListener('app.login.progress.desktop.clearing_session');
                try {
                    this.root.db.destroy();
                } catch (e) {
                    logger.warn(e);
                }
                return false;
            } else {
                this.syncStatusProgressListener('app.login.progress.session_expired.', 100);
                return 'needs-soft-login';
            }
        }
        let timeKeeperId = localStorage.getItem('timeKeeperId') || undefined;
        if (timeKeeperId) {
            this.syncStatusProgressListener('app.login.progress.desktop.setting_timeKeeper');
            await this.setTimeKeeper(Number(timeKeeperId));
        }
        let us = await this.check();
        if (timeKeeperId && !!this.axios) {
            await this.getAll();
        }
        this.syncStatusProgressListener('app.login.progress.desktop.checking_authentication_status');
        this.webSession.authenticated = true;
        try {
            if (!window.location.href.includes('timersPopOut')) {
                await this.write();
            }
        } catch (e) {
            // logger.error('Error in write', e)
        } finally {
            return us;
        }
    } // if this returns true the api will be ready to receive else, show login

    check = async (): Promise<boolean> => {
        let token = localStorage.getItem('token');
        if (!this.webSession.online && token) {
            this.webSession.buildAxios();
            return true;
        }
        logger.info('Application is online at this moment, so sending for api check!');
        return this.webSession.check();
    } // does a liveness check on the session

    getTimekeeperAssignments = async () => {
        let results = await this.root.db.timeKeeperAssignments.toArray();
        if (results.length === 0) {
            results = await this.webSession.getTimekeeperAssignments();
        }
        return results;
    }

    getTimeKeeperOffices = async (tkId: number) => {
        // Need to filter data based on Timekeeper id and user id
        return this.root.db.tkOffices.where({timekeeperId: tkId}).toArray();
    }

    setTimeKeeper = async (timeKeeperId: number) => {
        this.setRetentionDates();
        await this.webSession.setTimeKeeper(timeKeeperId);
        return;
        // todo
    }

    me = async () => {
        let localStorageVariable = localStorage;
        return {
            displayName: localStorageVariable.displayName,
            name: localStorageVariable.userName,
            id: localStorageVariable.userId,
            valid: localStorageVariable.valid
        } as User;
    }

    setReinitializeHandler = async (handler: (attemptSoftLogin?: boolean) => void) => {
        this.webSession.setReinitializeHandler(handler);
        // todo
    }

    statusChange = async (online: boolean) => {
        if (this.onlineHandler) {
            this.onlineHandler(online);
        }
        if (online) {
            if (this.root.webImpl.Session.tabElection.isLeader) {
                this.write();
            }
            this.root.webImpl.Session.periodicSync();
        }
    }

    saveSyncResponse = async (resp: SyncResponse, shouldCallTracked?: boolean) => {
        if (resp.lastSync) {
            localStorage.setItem('lastSync', resp.lastSync);
        }
        let invalidBannedWords: MatterWordsMapping[] = [];
        let invalidBlockBillingWords: MatterWordsMapping[] = [];
        let tkGoals: TkGoals[] = [];
        let workLocales: WorkLocale[] = [];
        const features: Features = JSON.parse(localStorage.getItem('features') || '{}');
        const matterLabel = features ? features.EpochConfigMatterLabel : '';

        if (resp.matters) {
            this.syncStatusProgressListener('app.login.progress.desktop.loading.banned_words', 5);
            invalidBannedWords = resp.matters.map((m) => {
                return {
                    matterId: m.id,
                    words: m.bannedWords
                }
            });
            this.syncStatusProgressListener('app.login.progress.desktop.loading.block_billing_words', 10);
            invalidBlockBillingWords = resp.matters.map((m) => {
                return {
                    matterId: m.id,
                    words: m.blockBillingWords
                }
            });
        }
        if (resp.tkGoals) {
            tkGoals = resp.tkGoals;
        }
        if (resp.workLocales) {
            workLocales = resp.workLocales;
        }
        try {
            await Promise.all([
                this.root.db.clients.bulkPut(resp.clients || [])
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.clients');
                    }).catch(e => logger.info(e)),
                this.root.db.matters.bulkPut(resp.matters || [])
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.matters', undefined, { matterLabel });
                    }).catch(e => logger.info(e)),
                this.root.db.codeSetMappings.bulkPut(resp.codeSetMappings || [])
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.code_set_mappings');
                    }).catch(e => logger.info(e)),
                this.root.db.timeKeeperAssignments.bulkPut(resp.timeKeepers || [])
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.timekeeper');
                    }).catch(e => logger.info(e)),
                this.root.db.codes.bulkPut(resp.codes || [])
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.codes');
                    }).catch(e => logger.info(e)),
                this.root.db.matterTkMappings.bulkPut(resp.matterTkMappings || [])
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.matter_tk_mappings');
                    }).catch(e => logger.info(e)),
                this.root.db.matterOfficeMappings.bulkPut(resp.matterOfficeMappings || [])
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.matter_offic_mappings');
                    }).catch(e => logger.info(e)),
                this.root.db.tkHours.bulkPut(resp.hours || [])
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.tk_hours');
                    }).catch(e => logger.info(e)),
                this.root.db.actionCodeMappings.bulkPut(resp.actionCodeMapping || [])
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.action_code_mappings');
                    }).catch(e => logger.info(e)),
                this.root.db.actionCodes.bulkPut(resp.actionCode || [])
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.action_codes');
                    }).catch(e => logger.info(e)),
                this.root.db.bannedWordsMapping.bulkPut(invalidBannedWords)
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.banned_words_mappings');
                    }).catch(e => logger.info(e)),
                this.root.db.blockBillingWordsMapping.bulkPut(invalidBlockBillingWords)
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.block_billing_words_mappings');
                    }).catch(e => logger.info(e)),
                this.root.db.tkGoals.bulkPut(tkGoals)
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.timekeeper_goals');
                    }).catch(e => logger.info(e)),
                this.root.db.tkOffices.bulkPut(resp.tkOffices)
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.timekeeper_offices');
                    }).catch(e => logger.info(e)),
                this.root.db.workLocales.bulkPut(workLocales)
                    .then(() => {
                        this.syncStatusProgressListener('app.login.progress.desktop.loading.work_location');
                    }).catch(e => logger.info(e))
            ]);
        } catch (e) {
            logger.error('Error resolving bulkPut in Dexie', e);
        }
        if (resp.templates && resp.templates.length > 0) {
            await this.root.Template.recieve((resp.templates as unknown as ImmutableTemplate[]) || [])
                .then(() => {
                    this.syncStatusProgressListener('app.login.progress.desktop.receiving.templates');
                }).catch((e) => {
                    logger.info('Error receiving Templates', e);
                });
        }
        if (resp.glossaries && resp.glossaries.length > 0) {
            await this.root.Narrative.recieve(resp.glossaries || [])
                .then(() => {
                    this.syncStatusProgressListener('app.login.progress.desktop.receiving.narratives');
                }).catch((e) => {
                    logger.info('Error receiving Narratives', e);
                });
        }
        if (resp.timeEntries && resp.timeEntries.length > 0) {
            await this.root.TimeEntry.recieve(resp.timeEntries || [])
                .then(() => {
                    this.syncStatusProgressListener('app.login.progress.desktop.receiving.timeentries');
                }).catch((e) => {
                    logger.info('Error receiving TimeEntries', e);
                });
        }
        if (resp.userDictionaries && resp.userDictionaries.length > 0) {
            await this.root.CustomDictionary.recieve(resp.userDictionaries || [])
                .then(() => {
                    this.syncStatusProgressListener('app.login.progress.desktop.receiving.dictionaries');
                }).catch((e) => {
                    logger.info('Error receiving CustomDictionaries', e);
                });
        }
        if (resp.timers && resp.timers.length > 0) {
            await this.root.Timer.recieve(resp.timers || [])
                .then(() => {
                    this.syncStatusProgressListener('app.login.progress.desktop.receiving.timers');
                }).catch((e) => {
                    logger.info('Error receiving Timers', e);
                });
        }
        if (resp.matters || resp.matterTkMappings) {
            if (resp.matters.length > 0 || resp.matterTkMappings.length > 0) {
                this.syncStatusProgressListener('app.login.progress.desktop.receiving.matters', undefined, { matterLabel });
                if (shouldCallTracked) {
                    await this.root.Matter.recieve(resp.matters);
                }
            }
        }
        if (resp.timeCastSegments && resp.timeCastSegments.length > 0) {
            await this.root.TimeCast.receiveSegments(resp.timeCastSegments || [])
                .then(() => {
                    this.syncStatusProgressListener('app.login.progress.desktop.receiving.timecast_segments');
                });
        }
        if (resp.timeCastPrograms && resp.timeCastPrograms.length > 0) {
            await this.root.TimeCast.receivePrograms(resp.timeCastPrograms || [])
                .then(() => {
                    this.syncStatusProgressListener('app.login.progress.desktop.receiving.timecast_programs');
                });
        }
        if (resp.settings && resp.settings.length > 0) {
            await this.root.Settings.receive(resp.settings || [])
                .then(() => {
                    this.syncStatusProgressListener('app.login.progress.desktop.receiving.settings');
                });
        }
        await this.updatedTKHandlers.filter(h => h !== null)
            .forEach(async h => h!(await this.getTimekeeperAssignments()));

        if (resp.timers || resp.timeEntries) {
            await this.pendingItemHandlers.filter(h => h !== null)
                .forEach(async h => h!(await this.getAllOfflineEntries()));
        }
        this.syncStatusProgressListener('app.login.progress.desktop.done', 100);
    }

    getAll = async () => {
        try {
            let from = DateTime.local().minus({ months: 2 }).startOf('month');
            let to = DateTime.local().endOf('month');
            this.syncStatusProgressListener('app.login.progress.desktop.fetching_data');
            let {data} = await this.axios.get(`/getAll?fromDate=${from.toISO()}&toDate=${to.toISO()}`);
            await this.saveSyncResponse(data, false);
        } catch (e) {
            logger.error('Error getAll', e);
            if (e.code) {
                throw e;
            }
        }
    }

    sync = async () => {
        try {
            this.syncStatusProgressListener('app.login.progress.desktop.fetching_data');
            if (this.axios && !this.webSession.loginInProgress) {
                let {data} = await this.axios.get(`/sync`);
                await this.saveSyncResponse(data, true);
            }
        } catch (e) {
            logger.error('Error sync', e);
            if (e.code) {
                throw e;
            }
        }
    }

    write = async () => {
        return this.writeMutex.execute(async () => {
            await Promise.all([
                this.root.TimeEntry.write()
                    .then(() => this.syncStatusProgressListener('app.login.progress.desktop.writing.timeentries'))
                    .catch((e) => {
                        logger.info('Error writing TimeEntries', e);
                    }),
                this.root.Template.write()
                    .then(() => this.syncStatusProgressListener('app.login.progress.desktop.writing.templates'))
                    .catch((e) => {
                        logger.info('Error writing Templates', e);
                    }),
                this.root.Narrative.write()
                    .then(() => this.syncStatusProgressListener('app.login.progress.desktop.writing.narratives'))
                    .catch((e) => {
                        logger.info('Error writing Narratives', e);
                    }),
                this.root.CustomDictionary.write()
                    .then(() => this.syncStatusProgressListener('app.login.progress.desktop.writing.dictionaries'))
                    .catch((e) => {
                        logger.info('Error writing CustomDictionaries', e);
                    }),
            ]);
            await this.root.Timer.write()
                .then(() => this.syncStatusProgressListener('app.login.progress.desktop.writing.timers'))
                .catch((e) => {
                    logger.info('Error writing Timers', e);
                });
            await this.root.Timer.writeChunks()
                .then(() => this.syncStatusProgressListener('app.login.progress.desktop.writing.timer_chunks'))
                .catch((e) => {
                    logger.info('Error writing TimerChunks', e);
                });
            await this.root.Settings.write()
                .then(() => this.syncStatusProgressListener('app.login.progress.desktop.writing.settings'))
                .catch((e) => {
                    logger.info('Error writing Settings', e);
                });
            await this.root.TimeCast.write()
                .then(() => this.syncStatusProgressListener('app.login.progress.desktop.writing.timecast_data'))
                .catch((e) => {
                    logger.info('Error writing TimeCast Data', e);
                });

            this.pendingItemHandlers.filter(h => h !== null)
                .forEach(async h => h!(await this.getAllOfflineEntries()));
        });
    }

    getAllOfflineEntries = async (): Promise<OfflineItems | undefined> => {
        if (this.currentTimeKeeper) {
            const offlineTimeEntries = await this.root.db.timeEntries
                .where({timeKeeperId: this.currentTimeKeeper!})
                .filter((t) => t.serverDirty!)
                .toArray();
            const offlineTimers = (await this.root.db.timers
                    .where({timeKeeperId: this.currentTimeKeeper!})
                    .toArray()
                ).filter(a => a.serverDirty);

            const offlineTemplates = await this.root.db.templates
                .where({timeKeeperId: this.currentTimeKeeper!})
                .filter((t) => t.serverDirty!)
                .toArray();

            const offlineTimerChunks: Map<number, TimerChunk[]> = new Map<number, TimerChunk[]>();
            for (let i = 0; i < offlineTimers.length; i++) {
                let timer = offlineTimers[i];
                if (timer.id) {
                    let timerId = timer.id;
                    let chunks = (await this.root.db.timerChunks.where({timerId}).toArray())
                        .filter((chk) => chk.serverDirty);
                    offlineTimerChunks.set(timer.id, chunks);
                }
            }
            return {
                TimeEntries: offlineTimeEntries.map((entry) => Object.assign(new ImmutableTimeEntry(), entry)),
                Timers: offlineTimers.map((timer) => Object.assign(new ImmutableTimer(), timer)),
                Templates: offlineTemplates.map((temp) => Object.assign(new ImmutableTemplate(), temp)),
                TimerChunks: offlineTimerChunks
            };
        } else {
            return;
        }
    }

    getFeatures = async () => {
        return JSON.parse(localStorage.getItem('features') || '{}');
    }

    getFeaturesApi = async () => {
        return this.webSession.getFeaturesApi();
    }

    getEulaText = async (): Promise<EULA> => {
        return await this.root.webImpl.Session.getEulaText();
    }

    ssoEntry = async () => {
        const urlOrigin: string = this.webSession.rootURI.replace('api', '');
        window.location.href = `${this.webSession.rootURI}/oidc/idp/entry?referer=${urlOrigin}/features`;
    }

    pendingItemsReciever = (handler: (entries: OfflineItems | undefined) => void ) => {
        this.pendingItemHandlers.push(handler);
        const theIndex = this.pendingItemHandlers.length - 1;
        return () => {
            this.pendingItemHandlers[theIndex] = null;
        }
    }

    updatedTKsReciever = (handler: (tks: TimeKeeperAssignment[]) => void ) => {
        this.updatedTKHandlers.push(handler);
        const theIndex = this.updatedTKHandlers.length - 1;
        return () => {
            this.updatedTKHandlers[theIndex] = null;
        }
    }

    exportLocalDBToJSON = async () => {
        let idb = this.root.db;
        if (idb) {
            let backendDB = idb.backendDB();
            await IDBExportImport.exportToJsonString(
                backendDB,
                (error: string, jsonString: string) => {
                    if (error) {
                        throw error;
                    } else {
                        let zipFolder = zip.folder('Epoch');
                        let epochDB = JSON.parse(jsonString);
                        for (let i in epochDB) {
                            if (epochDB[i].length > 0) {
                                zipFolder.file('epochDB.' + i + '.csv',
                                    this.JSONToCSVConvertor(JSON.stringify(epochDB[i])));
                            }
                        }
                        zipFolder.file('EpochLocalStorage.json', JSON.stringify(localStorage));
                        zip.generateAsync( { type: 'blob'}).then((blob: Blob) =>
                            FileSaver.saveAs(blob, 'epoch.zip')
                        );
                    }
                }
            );
        }
    }

    JSONToCSVConvertor = async (jsonString: string, fileName?: string) => {
        let data = JSON.parse(jsonString);
        /* Create Worksheet */
        let ws = XLSX.utils.json_to_sheet(data);
        /* Add to WorkBook */
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws);
        /* write */
        if (fileName) {
            return XLSX.writeFile(wb, fileName);
        }
        return XLSX.write(wb, {
            bookType: 'csv',
            type: 'binary'
        });
    }

    exportTimeEntriesData = async () => {
        let idb = this.root.db;
        if (idb) {
            let backendDB = idb.backendDB();
            await IDBExportImport.exportToJsonString(
                backendDB,
                (error: string, jsonString: string) => {
                    if (error) {
                        throw error;
                    } else {
                        let epochDB = JSON.parse(jsonString);
                        if (epochDB) {
                            const appDataPath = localStorage.getItem('appDataPath');
                            if (appDataPath) {
                                const path = `${appDataPath}\\logs\\${fileNameForExport(undefined, 'timeEntries')}.csv`;
                                if (epochDB.timeEntries.length > 0) {
                                    this.JSONToCSVConvertor(JSON.stringify(epochDB.timeEntries), path);
                                }
                            }
                        }
                    }
                }
            );
        }
    }

    /**
     * parses zip file generated with Export Epoch Database, reads files and overwrites indexedDB with given data
     * @param file zip file to read data from
     */
    importDB = async (file: File) => {
        zip.loadAsync(file).then((content: any) => {
            content.file('Epoch/epochDB.timecastSegments.csv').async('string').then((segData: any) => {
                this.loadTimecastSegments(segData);
            }, (error: any) => {
                logger.error(`Failed getting timecast segment csv file: ${error.toString()}`);
            });
            content.file('Epoch/epochDB.timeEntries.csv').async('string').then((entries: any) => {
                this.loadTimeEntries(entries);
            }, (error: any) => {
                logger.error(`Failed getting time entries csv file: ${error.toString()}`);
            });
        });
    }

    getTkGoals = async (year: number): Promise<TkGoals | undefined> => {
        let tkId = this.currentTimeKeeper;
        if (tkId) {
            return this.root.db.tkGoals.where({timekeeperId: tkId, goalYear: year}).first();
        } else {
            return undefined;
        }
    }

    getAllTimeKeepersList = async (search: string, offset: number, limit: number, workDate: string, matterId?: number | null) => {
        return this.webSession.getAllTimeKeepersList(search, offset, limit, workDate, matterId);
    }

    get online(): boolean {
        return this.webSession.online;
    }

    /**
     * helper function to read and load timecast segment data
     * @param segmentData csv file data containing timecast segments
     */
    private async loadTimecastSegments(segmentData: any) {
        const parsedResult = Papa.parse(segmentData, {header: true});
        const timeSegs: TimeCastSegmentI[] = [];
        let arr = parsedResult.data as any[];
        for (const row of arr) {
            timeSegs.push({
                id: row.id,
                createdBy: row.createdBy,
                serverDirty: row.serverDirty === 'TRUE',
                startTime: row.startTime,
                lastModified: row.lastModified,
                endTime: row.endTime,
                data: row.data ? JSON.parse(row.data) : undefined,
                foreignIdentifier: row.foreignIdentifier,
                type: row.type,
                deleted: row.deleted ? true : undefined,
                createdOn: row.createdOn,
                associatedTimeEntry: row.associatedTimeEntry
            });
        }
        this.root.db.timecastSegments.clear();
        this.root.db.timecastSegments.bulkPut(timeSegs);
    }

    /**
     * helper function to read and load time entries from csv data
     * @param entriesData csv file data containing time entries
     */
    private async loadTimeEntries(entriesData: any) {
        const parsedResult = Papa.parse(entriesData, {header: true});
        const entries: any[] = [];
        let arr = parsedResult.data as any[];
        for (const row of arr) {
            entries.push({
                id: row.id,
                matterId: row.matterId,
                clientId: row.clientId,
                timeKeeperId: row.timeKeeperId,
                workDateTime: row.workDateTime
            });
        }
        this.root.db.timeEntries.clear();
        this.root.db.timeEntries.bulkPut(entries);
    }
}
