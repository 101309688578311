import ManagementDashboardAPI from 'api/interfaces/ManagementDashboardAPI';
import BaseWebImplementation from './Base.impl';
import { DateTime } from 'luxon';
import ImmutableTimeEntry from 'api/immutables/ImmutableTimeEntry';
import { Role } from 'api/types/types';

export default class ManagementDashboardImpl extends BaseWebImplementation implements ManagementDashboardAPI {
    async getRoles() {
        const { data } = await this.http.get('/user/rolesPermissions');
        return data.roles;
    }

    async getEntries(
        fromDate: DateTime, toDate: DateTime, role: Role, approverTkId: number, tkId?: number, approvalStatus?: string, billable?: boolean, sapStatus?: string,
        offset?: number, limit?: number, matterId?: number, clientId?: number, actCodeId?: number, phaseId?: number, taskId?: number
    ) {
        const { data } = await this.http.get(
            '/timeEntries/manage?' +
            `fromDate=${fromDate.toISODate()}` +
            `&toDate=${toDate.toISODate()}` +
            `&role=${role}` +
            `&approverTkId=${approverTkId ? approverTkId : tkId}` +
            `&approvalStatus=${approvalStatus ? approvalStatus : ''}` +
            `${tkId ? '&tkId=' + tkId : ''}` +
            `${billable !== undefined ? '&billable=' + billable : ''}` +
            `${sapStatus ? '&sapStatus=' + sapStatus : ''}` +
            `${offset ? '&offset=' + offset : ''}` +
            `${limit ? '&limit=' + limit : ''}` +
            `${matterId ? '&matterId=' + matterId : ''}` +
            `${clientId ? '&clientId=' + clientId : ''}` +
            `${actCodeId ? '&actCodeId=' + actCodeId : ''}` +
            `${phaseId ? '&phaseId=' + phaseId : ''}` +
            `${taskId ? '&taskId=' + taskId : ''}`
        );

        return data.map((d: object) => Object.assign(new ImmutableTimeEntry(), d));
    }

    async getDelegators(role: Role) {
        const { data } = await this.http.get(`/managerDelegation?role=${role}`);
        return data;
    }

    async getEmployees(role: Role, approverTkId: number, search?: string, offset?: number, limit?: number) {
        const { data } = await this.http.get(
            `/timeKeepers/all?role=${role}&approverTkId=${approverTkId}` +
            `${search ? '&search=' + search : ''}` +
            `${offset ? '&offset=' + offset : ''}` +
            `${limit ? '&limit=' + limit : ''}`
        );
        return data;
    }

    async postEntries(role: Role, approverTkId: number, ids: number[]) {
        const { data } = await this.http.post(`/timeEntries/post?role=${role}&approverTkId=${approverTkId}`, ids);
        return data;
    }

    async approveEntries(role: Role, approverTkId: number, ids: number[]) {
        const { data } = await this.http.post(`/timeEntries/approve?role=${role}&approverTkId=${approverTkId}`, ids);
        return data;
    }

    async rejectEntries(role: Role, rejectorTkId: number, ids: number[], rejectionCodeId: string) {
        const { data } = await this.http.post(
            `/timeEntries/reject?role=${role}&rejectorTkId=${rejectorTkId}&rejectionCodeId=${rejectionCodeId}`
        , ids);
        return data;
    }

    async getRejectionCodes(offset?: number, limit?: number) {
        const { data } = await this.http.get(
            'rejectionCodes' +
            `${offset || limit ? '?' : ''}` +
            `${offset ? 'offset=' + offset : ''}` +
            `${offset && limit ? '&' : ''}` +
            `${limit ? 'limit=' + limit : ''}`
        );
        return data;
    }

    // // Delegation Page APIs
    // async getManagerDelegation() {
    //     const { data } = await this.http.get('/managerDelegation');
    //     return data;
    // }

    // async addManagerDelegation(delegator: any) {
    //     const { data } = await this.http.put('/managerDelegation', delegator);
    //
    //     [{
    //         "sourceTkId": 10,
    //         "targetTkId": 802,
    //         "role": "PROJMANAGER",
    //         "startDateTime": "2024-01-19T22:08:30.967Z",
    //         "endDateTime": "2024-02-02T22:08:30.967Z"
    //     }]
    //     return data;
    // }
}