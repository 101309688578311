// tslint:disable:max-line-length
import CodeAPI from 'api/interfaces/CodeAPI';
import { Code, CodeSetFlags } from 'api/types/types';
import BaseWebImplementation from './Base.impl';
import { dateISOFormat } from '../../../util/utils';

// limit and offset for API calls
const limit: number = 200;
const offset: number = 0;
export default class CodeImpl extends BaseWebImplementation implements CodeAPI  {
    get = async (id: number) => {
        const { data } = await this.http.get(`/codes/${id}`);
        return data as Code;
    }
    getPhaseCodes = async (matterId: number, workDate?: string, search?: string) => {
        if (workDate) {
            workDate = dateISOFormat(workDate);
        }
        const { data } = await this.http.get(
            `/matters/${matterId}/phaseCodes?limit=${limit}&offset=${offset}` +
            `${search ? `&search=${encodeURIComponent(search)}` : ''}` +
            `${workDate ? `&date=${workDate}` : ''}`
        );
        return data;
    }
    getActivityCodes = async (matterId: number, workDate?: string, search?: string) => {
        if (workDate) {
            workDate = dateISOFormat(workDate);
        }
        const { data } = await this.http.get(
            `/matters/${matterId}/actCodes?limit=${limit}&offset=${offset}` +
            `${search ? `&search=${encodeURIComponent(search)}` : ''}` +
            `${workDate ? `&date=${workDate}` : ''}`
        );
        return data;
    }
    getFFTaskCodes = async (matterId: number, workDate?: string, search?: string) => {
        if (workDate) {
            workDate = dateISOFormat(workDate);
        }
        const { data } = await this.http.get(
            `/matters/${matterId}/ffTaskCodes?limit=${limit}&offset=${offset}` +
            `${search ? `&search=${encodeURIComponent(search)}` : ''}` +
            `${workDate ? `&date=${workDate}` : ''}`
        );
        return data;
    }
    getTaskCodes = async (phaseId: number, workDate?: string, search?: string) => {
        if (workDate) {
            workDate = dateISOFormat(workDate);
        }
        const { data } = await this.http.get(
            `/codes/${phaseId}/taskCodes?limit=${limit}&offset=${offset}` +
            `${search ? `&search=${encodeURIComponent(search)}` : ''}` +
            `${workDate ? `&date=${workDate}` : ''}`
        );
        return data;
    }
    getFFActCodes = async (ffTaskCodeId: number, workDate?: string, search?: string) => {
        if (workDate) {
            workDate = dateISOFormat(workDate);
        }
        const { data } = await this.http.get(
            `/codes/${ffTaskCodeId}/ffActCodes?imit=${limit}&offset=${offset}` +
            `${search ? `&search=${encodeURIComponent(search)}` : ''}` +
            `${workDate ? `&date=${workDate}` : ''}`
        );
        return data;
    }
    getActionCodes = async (matterId?: number, actionCode?: string, search?: string) => {
        const { data } = await this.http.get(
            `/codes/actionCodes?limit=${limit}&offset=${offset}` +
            `${search ? `&search=${encodeURIComponent(search)}` : ''}` +
            `${matterId ? `&matter=${matterId}` : ''}` +
            `${actionCode ? `&actionCode=${actionCode}` : ''}`
        );
        return data;
    }
    determineCodeSetFields = async (matterId: number, workDate: string): Promise<CodeSetFlags> => {
        if (workDate) {
            workDate = dateISOFormat(workDate);
        }
        
        let flags: CodeSetFlags = {
            isPhaseCode: false,
            isFfTaskCode: false,
            isActCode: false,
            phases: [],
            ffTasks: [],
            activities: []
        };
        
        const { data } = await this.http.get(
            `/matters/${matterId}/isCodes${workDate && '?workDate='}${workDate}`
        );
        flags = {...data};
        if (flags.isPhaseCode) {
            const phases = await this.getPhaseCodes(matterId, workDate, '');
            flags.phases = phases;
        } else {
            flags.phases = [];
        }
        if (flags.isFfTaskCode) {
            const ffTasks = await this.getFFTaskCodes(matterId, workDate, '');
            flags.ffTasks = ffTasks;
        } else {
            flags.ffTasks = [];
        }
        if (flags.isActCode) {
            const activities = await this.getActivityCodes(matterId, workDate, '');
            flags.activities = activities;
        } else {
            flags.activities = [];
        }
        
        return flags as CodeSetFlags;
    }
}