import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    TextField
} from '@material-ui/core';
import EditTimerTimeDialogStore from 'store/Timer/edit.timer.time.dialog.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import DateFnsUtils from '@date-io/date-fns';
import { InlineTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { FlexDiv } from 'common/flex';
import { EditTimeLabel } from '../../containers/Timers/styled';
import { withTranslation } from 'react-i18next';

interface Props {
    editTimerTimeDialogStore?: EditTimerTimeDialogStore;
    // tslint:disable-next-line:no-any
    t: any;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        editTimerTimeDialogStore: rootStore.editTimerChunkTimeDialogStore
    };
})
@observer
class EditTimerChunkTimeDialog extends React.Component<Props> {
    cancel = () => {
        this.props.editTimerTimeDialogStore!.cancel();
    };
    changeHours = (timeEvent: React.ChangeEvent<HTMLInputElement>, isStartTime: boolean) => {
        const hour = Number(timeEvent.target.value);
        this.props.editTimerTimeDialogStore!.setHours(hour, isStartTime);
    }
    changeMinutes = (timeEvent: React.ChangeEvent<HTMLInputElement>, isStartTime: boolean) => {
        const minute = Number(timeEvent.target.value);
        this.props.editTimerTimeDialogStore!.setMinutes(minute, isStartTime);
    }
    changeSeconds = (timeEvent: React.ChangeEvent<HTMLInputElement>, isStartTime: boolean) => {
        const sec = Number(timeEvent.target.value);
        this.props.editTimerTimeDialogStore!.setSeconds(sec, isStartTime);
    }
    changeEndTimeWithClock = (timeStr: Date) => {
        this.props.editTimerTimeDialogStore!.setEndTime(timeStr.toISOString());
    }
    changeStartTimeWithClock = (timeStr: Date) => {
        this.props.editTimerTimeDialogStore!.setStartTime(timeStr.toISOString());
    }
    save = async () => {
        await this.props.editTimerTimeDialogStore!.saveChunkTime();
    }
    changeMeridiem = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.editTimerTimeDialogStore!.setMeridiem(evt.target.value);
    }
    render() {
        const { t, editTimerTimeDialogStore } = this.props;
        const {
            isOpen,
            startTime,
            endTime,
            hours,
            minutes,
            seconds,
            stHours,
            stMinutes,
            stSeconds,
            timeDuration,
            invalidEndTime,
            timerChunk,
            invalidHours,
            invalidMinutes,
            invalidSeconds
        } = editTimerTimeDialogStore!;
        
        return(
            <Dialog
                open={isOpen}
                onClose={this.cancel}
                maxWidth="md"
            >
                <DialogTitle>
                    <div>
                        <label>{t('dialog.edit_chunk_time.title')}</label>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <EditTimeLabel style={{textAlign: 'center', fontSize: 18}}>
                        {timeDuration.match(/\d{2}:\d{2}:\d{2}/) ? timeDuration : t(timeDuration)}
                    </EditTimeLabel>
                    <EditTimeLabel>
                        {t('dialog.edit_chunk_time.start_time')}
                    </EditTimeLabel>
                    <FlexDiv style={{paddingBottom: 16}}>
                        <TextField
                            id="start-hour"
                            label={t('dialog.edit_chunk_time.time.hours')}
                            value={stHours}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 23
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.changeHours(e, true) }}
                            disabled={timerChunk.timeEntryId ? true : false}
                        />
                        <TextField
                            id="start-minute"
                            label={t('dialog.edit_chunk_time.time.minutes')}
                            value={stMinutes}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 59
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.changeMinutes(e, true) }}
                            disabled={timerChunk.timeEntryId ? true : false}
                        />
                        <TextField
                            id="start-second"
                            label={t('dialog.edit_chunk_time.time.seconds')}
                            value={stSeconds}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 59
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.changeSeconds(e, true) }}
                            style={{
                                paddingRight: 10
                            }}
                            disabled={timerChunk.timeEntryId ? true : false}
                        />
                        <div style={{width: 68}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <InlineTimePicker
                                    value={new Date(startTime)}
                                    onChange={this.changeStartTimeWithClock}
                                    labelFunc={() => { return t('dialog.edit_chunk_time.clock')}}
                                    variant={'outlined'}
                                    seconds={true}
                                    ampm={false}
                                    disabled={timerChunk.timeEntryId ? true : false}
                                    mergePreviousDateOnChange={false}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </FlexDiv>
                    <EditTimeLabel>
                        {t('dialog.edit_chunk_time.end_time')}
                    </EditTimeLabel>
                    <FlexDiv>
                        <TextField
                            id="hour"
                            label={t('dialog.edit_chunk_time.time.hours')}
                            value={hours}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 23
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.changeHours(e, false) }}
                            disabled={timerChunk.timeEntryId ? true : false}
                        />
                        <TextField
                            id="minute"
                            label={t('dialog.edit_chunk_time.time.minutes')}
                            value={minutes}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 59
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.changeMinutes(e, false) }}
                            disabled={timerChunk.timeEntryId ? true : false}
                        />
                        <TextField
                            id="second"
                            label={t('dialog.edit_chunk_time.time.seconds')}
                            value={seconds}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 59
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.changeSeconds(e, false) }}
                            style={{
                                paddingRight: 10
                            }}
                            disabled={timerChunk.timeEntryId ? true : false}
                        />
                        <div style={{width: 68}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <InlineTimePicker
                                    value={new Date(endTime)}
                                    onChange={this.changeEndTimeWithClock}
                                    labelFunc={() => { return t('dialog.edit_chunk_time.clock')}}
                                    variant={'outlined'}
                                    seconds={true}
                                    ampm={false}
                                    disabled={timerChunk.timeEntryId ? true : false}
                                    mergePreviousDateOnChange={false}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </FlexDiv>
                    {invalidEndTime &&
                    <FormHelperText error={true}>
                        {t('dialog.edit_chunk_time.validation.end_time')}
                    </FormHelperText>
                    }
                    {invalidHours &&
                    <FormHelperText error={true}>
                        {t('dialog.edit_chunk_time.validation.hours')}
                    </FormHelperText>
                    }
                    {invalidMinutes &&
                    <FormHelperText error={true}>
                        {t('dialog.edit_chunk_time.validation.minutes')}
                    </FormHelperText>
                    }
                    {invalidSeconds &&
                    <FormHelperText error={true}>
                        {t('dialog.edit_chunk_time.validation.seconds')}
                    </FormHelperText>
                    }
                </DialogContent>
                <DialogActions>
                    {!timerChunk.timeEntryId &&
                        <Button 
                            onClick={this.save}
                            disabled={invalidHours || invalidMinutes || invalidSeconds}
                        >
                            {t('save', { ns: 'common' })}
                        </Button>
                    }
                    <Button onClick={this.cancel}>{t('cancel', { ns: 'common' })}</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withTranslation(['timers', 'common'])(EditTimerChunkTimeDialog);