import { action, computed, observable, override, makeObservable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import { ActionCode, Client, Code, Matter, TimeEntryType } from '../api/types/types';
import TimeEntry, { SapStatus } from '../api/immutables/ImmutableTimeEntry';
import { DateTime } from 'luxon';
import TimeEntryDialogStore from 'store/timeentry.dialog.store';
import { Platform } from '../util/Platform';
import { ValidateSave, ValidateTemplate } from 'api/immutables/validators';
import ImmutableTemplate from 'api/immutables/ImmutableTemplate';
import logger from '../logging/logging';
import { RootStore } from 'store/root.store';

export default class MergeEntryDialogStore extends TimeEntryDialogStore {
    @observable mergeNarrativeFlag: boolean = false;
    @observable mergedNarrative: string;
    @observable entries: TimeEntry[] = [];
    @observable isMergedNarrativeAppended: boolean = false;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @computed get matters(): Matter[] {
        let matterList: Matter[] = [];
        this.entries.forEach((te) => {
            if (te.matter) {
                matterList.push(te.matter);
            }
        });
        return matterList;
    }

    async getTotalDurationExclusive(workDate: string) {
        let totalDuration = await this.rootStore.api.TimeEntry.getTotalForDateExclusive(
            workDate,
            this.entries.map(e => e.id!)
        );
        return totalDuration;
    }

    @loadable()
    async openMerge(entries: TimeEntry[]) {
        try {
            this.entries = entries;
            this.entry = new TimeEntry();
            this.mergeNarrativeFlag = false;
            this.selectedTemplate = undefined;
            this.validationState = undefined;
            this.durationValidationState = false;
            this.isMergedNarrativeAppended = false;
            this.narrativeText = '';
            // Check if same client
            let isSameClient: boolean = entries.every((c: TimeEntry, ind, cArray) =>
                c.clientId === cArray[0].clientId),
            mClient: Client | null = isSameClient ? entries[0].client : null;

            // Check if all selected entries has same matter, then set matter obj with that matter.
            let isSameMatter: boolean = entries.every((mat: TimeEntry, index, array) =>
                mat.matterId === array[0].matterId),
            mMatter: Matter | null = isSameMatter ? entries[0].matter : null;

            // getting banned words on opening the dialog when matter ids are same, coz bannned words array will be empty.
            if (mMatter) {
                mMatter = await this.rootStore.api.Matter.get(mMatter.id);
            }

            let isSamePhase: boolean = entries.every((phs: TimeEntry, index, array) =>
                phs.phaseId === array[0].phaseId),
            mPhase: Code | null = isSamePhase ? entries[0].phase : null;

            let isSameTask: boolean = entries.every((tsk: TimeEntry, index, array) =>
                tsk.taskCodeId === array[0].taskCodeId),
            mTask: Code | null = isSameTask ? entries[0].task : null;

            let isSameActivity: boolean = entries.every((act: TimeEntry, index, array) =>
                act.actCodeId === array[0].actCodeId),
            mActivity: Code | null = isSameActivity ? entries[0].activity : null;

            let isSameFFTask = entries.every((te, index, array) =>
                te.ffTaskCodeId === array[0].ffTaskCodeId),
            mFFTask: Code | null = isSameFFTask ? entries[0].ffTask : null;

            let isSameFFActivity = entries.every((te, index, array) =>
                te.ffActCodeId === array[0].ffActCodeId),
            mFFActivity: Code | null = isSameFFActivity ? entries[0].ffActivity : null;

            let isSameActionCode: boolean = entries.every((accd: TimeEntry, index, array) =>
                accd.actionCodeId === array[0].actionCodeId),
            mActionCode: ActionCode | null = isSameActionCode ? entries[0].actionCodeObj : null;

            let isSameReference: boolean = entries.every((ref: TimeEntry, index, array) =>
                ref.reference === array[0].reference),
            mReference: string | null = isSameReference ? entries[0].reference : null;

            // If entries are from same work date, else today's date.
            let isSameWorkDate: boolean = entries.every((e: TimeEntry, i, arr) => e.workDateTime === arr[0].workDateTime),
            workDate: string = isSameWorkDate ? entries[0].workDateTime : DateTime.local().startOf('day').toISO();
            this.setWorkDate(DateTime.fromISO(workDate));

            // Set office of the TimeKeeper
            let actTk = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(workDate));

            let isSameOffice: boolean = entries.every((so: TimeEntry, index, array) =>
                so.office === array[0].office),
            mOffice: string | undefined = isSameOffice ? entries[0].office : actTk ? actTk.office : undefined;

            let isSameOfficeName: boolean = entries.every((so: TimeEntry, index, array) =>
                so.officeName === array[0].officeName),
            mOfficeName: string | undefined = isSameOfficeName ? entries[0].officeName : actTk ? actTk.officeName : undefined;

            // Merge Narrative
            this.mergedNarrative = entries.reduce((first: string, current: TimeEntry) => {
                return (first ? first.trim() : '') + ' ' + (current.narrative ? current.narrative!.trim() : '');
            }, '');

            // Merge Duration
            let duration = entries.reduce((prev: number, curr: TimeEntry) => prev + curr.duration, 0);

            // Check if all selected entries has same work location, then set work location obj with that location.
            const workLocaleId: number | null | undefined = entries[0].workLocaleId;
            const isSameWorkLocale: boolean = entries.every((entry: TimeEntry) => entry.workLocaleId === workLocaleId);
            const mWorkLoacleId: number | null = workLocaleId && isSameWorkLocale ? workLocaleId : null;

            this.entry = this.entry
                .setClient(mClient)
                .setMatter(mMatter)
                .setNarrative('')
                .setOffice(mOffice)
                .setOfficeName(mOfficeName)
                .setDuration(duration)
                .setPhase(mPhase)
                .setTask(mTask)
                .setAct(mActivity)
                .setActionCode(mActionCode)
                .setFFTask(mFFTask)
                .setFFAct(mFFActivity)
                .setReference(mReference)
                .setWorkLocaleId(mWorkLoacleId);
            if (mMatter) {
                const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(mMatter.id, workDate);
                this.entry.isActCode = codeSetFlags.isActCode;
                this.entry.isPhaseCode = codeSetFlags.isPhaseCode;
                this.entry.isFfTaskCode = codeSetFlags.isFfTaskCode;
            }
            this.entry.sapStatus = SapStatus.UNSUBMITTED;
            this.entry.timeKeeperId = this.rootStore.api.Session.currentTimeKeeper!;
            this.entry.timeEntryType = TimeEntryType.MERGE;
            this.entry.collaborateTks = '';
            this.entry.collaborateInfo = null;
            return await this.open(this.entry);
        } catch (e) {
            if (e) {
                throw e;
            }
            return {};
        }
    }

    @action toggleMergeNarrativeFlag = () => {
        this.mergeNarrativeFlag = !this.mergeNarrativeFlag;
        if (this.mergeNarrativeFlag) {
            let idx = this.entry.narrative!.includes(this.mergedNarrative.trim());
            if (!idx) {
                this.entry.narrative = [this.entry.narrative, this.mergedNarrative].join(' ').trim();
                this.narrativeText = this.entry.narrative;
                if (this.validationState) {
                    this.validationState.narrativeLength = false;
                }
            }
        }
    }

    @loadable()
    @override
    async saveEntry() {
        try {
            let narrativeMinLength;
            let narrativeMaxLength;
            if (this.entry.matterId) {
                const matter = await this.rootStore.api.Matter.get(this.entry.matterId);
                if (matter) {
                    this.entry.bannedWords = matter.bannedWords;
                    this.entry.blockBillingWords = matter.blockBillingWords;
                    narrativeMinLength = matter.minLength;
                    narrativeMaxLength = matter.maxLength;
                }
            }

            let vstate = ValidateSave(
                this.entry,
                await this.getTotalDurationExclusive(this.entry.workDateTime),
                this.rootStore.appStore.features,
                this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(this.entry.workDateTime)),
                narrativeMinLength,
                narrativeMaxLength
            );
            if (Platform.isElectron()) {
                vstate = await this.validateCodeSets(this.entry, vstate);
            }
            if (!vstate.valid) {
                this.validationState = vstate;
                return;
            }
            if (this.durationValidationState) {
                return;
            }
            let template: ImmutableTemplate | undefined;
            if (this.templateName.trim().length > 0) {
                // TODO validate template
                template = this.entry.createTemplate();
                template.name = this.templateName;
                let templateValidationState = ValidateTemplate(
                    template,
                    await this.rootStore.api.Template.getAllTemplates(),
                    this.maxNarrativeLength
                );
                if (!templateValidationState.valid) {
                    this.templateValidationState = templateValidationState;
                    return;
                }
            }

            let preMergeEntries = this.entries.map((e) => {
                let newEntry = e.clone();
                newEntry.deleted = true;
                return newEntry;
            });

            let results = await this.rootStore.api.TimeEntry.updateEntries([...preMergeEntries, this.entry]);
            let entriesFromResults = results.map((r) => Object.assign(new TimeEntry(), JSON.parse(JSON.stringify(r.object))));
            let result = results[results.length - 1];

            // result is merged entry
            if (result.status.failed) {
                this.rootStore.snackbarStore.triggerSnackbar(result.status.message);
                this.entry.sapStatus = SapStatus.UNSUBMITTED;
                this.entry = this.entry.clone();
                return;
            }
            this.rootStore.snackbarStore.triggerSnackbar('app.snackbar.info.saved');

            if (template) {
                await this.rootStore.api.Template.saveTemplate(template);
            }
            let entry = entriesFromResults[0];
            entry.isActCode = this.entry.isActCode;
            entry.isPhaseCode = this.entry.isPhaseCode;
            entry.isFfTaskCode = this.entry.isFfTaskCode;

            this.resolveAndClose(entriesFromResults);
            this.clear();
            return;
        } catch (e) {
            logger.info('Time Entries, Saving Merge Entries failed.\n', e);
            throw e;
        }
    }
}