import TemplateAPI from 'api/interfaces/TemplateAPI';
import BaseElectronImplementation from './Base.impl';
// import ImmutableTemplate from 'api/immutables/ImmutableTemplate';
import { Template, TimeEntry } from './Dexie';
import ImmutableBaseEntry from '../../immutables/ImmutableBaseEntry';
import ImmutableTemplate from '../../immutables/ImmutableTemplate';
import { ApiResult } from '../../util';
import logger from '../../../logging/logging';

export default class TemplateImpl extends BaseElectronImplementation implements TemplateAPI {
    handlers: (((entries: ImmutableTemplate[]) => void) | null )[] = [];
    hydrateTemplate = async (template: ImmutableTemplate): Promise<ImmutableTemplate> => {
        let newTemp = Object.assign(new ImmutableTemplate(), template);
        // todo parallelize this
        if (template.matterId) {
            try {
                let matter = await this.root.Matter.get(template.matterId);
                newTemp = ImmutableBaseEntry.applyMatter<ImmutableTemplate>(newTemp, matter);
            } catch (e) {
                logger.info('Templates, Error hydrating template with Matter', e);
            }
        }
        if (template.phaseId) {
            try {
                let phase = await this.root.Code.get(template.phaseId);
                newTemp = ImmutableBaseEntry.applyPhase<ImmutableTemplate>(newTemp, phase);
            } catch (e) {
                logger.info('Templates, Error hydrating template with Phase Id', e);
            }
        }
        if (template.taskCodeId) {
            try {
                let task = await this.root.Code.get(template.taskCodeId);
                newTemp = ImmutableBaseEntry.applyTask<ImmutableTemplate>(newTemp, task);
            } catch (e) {
                logger.info('Templates, Error hydrating template with TaskCode Id', e);
            }
        }
        if (template.actCodeId) {
            try {
                let act = await this.root.Code.get(template.actCodeId);
                newTemp = ImmutableBaseEntry.applyActivity<ImmutableTemplate>(newTemp, act);
            } catch (e) {
                logger.info('Templates, Error hydrating template with ActCode Id', e);
            }
        }
        if (template.ffTaskCodeId) {
            try {
                let fftask = await this.root.Code.get(template.ffTaskCodeId);
                newTemp = ImmutableBaseEntry.applyFFTask<ImmutableTemplate>(newTemp, fftask);
            } catch (e) {
                logger.info('Templates, Error hydrating template with ffTaskCode Id', e);
            }
        }
        if (template.ffActCodeId) {
            try {
                let ffact = await this.root.Code.get(template.ffActCodeId);
                newTemp = ImmutableBaseEntry.applyFFActivity<ImmutableTemplate>(newTemp, ffact);
            } catch (e) {
                logger.info('Templates, Error hydrating template with ffActCode Id', e);
            }
        }
        newTemp.dirty = false;
        return newTemp;
    }
    async getAllTemplates(searchQuery?: string) {
        const tkid = this.root.Session.currentTimeKeeper;
        try {
            if (this.root.Session.online && searchQuery && searchQuery.charAt(0) === '^') {
                return this.root.webImpl.Template.getAllTemplates(searchQuery);
            }
            return await Promise.all(
                (await this.root.db.templates.where({timeKeeperId: tkid!}).toArray())
                .filter(a => !a.deleted)
                .map(this.hydrateTemplate)
            );
        } catch (e) {
            logger.error('Templates, Error getting all templates' , e);
            throw e;
        }
    }

    async getTemplate(id: number) {
        try {
            const template = await this.root.db.templates.get(id < 0 ? { localId: id * -1 } : { id: id });
            return await this.hydrateTemplate(Object.assign(new ImmutableTemplate(), template!));
        } catch (e) {
            logger.error('Templates, Error getting a selected template', e);
            throw e;
        }
    }

    async saveTemplate(t: ImmutableTemplate) {
        t.timeKeeperId = this.root.Session.currentTimeKeeper!;
        let resp = await this.trySaveOne(t);
        this.root.Session.write();
        if (!resp.status.failed) {
            return resp.object;
        } else {
            throw resp.status.message;
        }
    }
    registerReciever = (handler: (entries: ImmutableTemplate[]) => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return () => {
            this.handlers[theIndex] = null;
        }
    }
    getWriteableId = async (entry: ImmutableBaseEntry): Promise<number | undefined> => {
        if (!entry.id) {
            return undefined;
        }
        if (entry.id < 0) {
            return entry.id * -1;
        }
        try {
            return (await this.root.db.templates.get({id: entry.id}))!.localId;
        } catch (e) {
            logger.error('Templates, Error getting writable Id', e);
            throw e;
        }
    }
    trySaveOne = async (entry: ImmutableTemplate): Promise<ApiResult<ImmutableTemplate>> => {
        try {
            let insertKey = await this.getWriteableId(entry);
            let writeableEntry = entry.toWriteable() as Template;
            if (insertKey) {
                writeableEntry.localId = insertKey;
            }
            writeableEntry.serverDirty = true;
            if (writeableEntry.deleted && writeableEntry.id) { // if Template is deleted, update Timer
                this.root.db.timers.where({templateId: writeableEntry.id})
                    .modify({templateId: null});
            }
            let localId = await this.root.db.templates.put(writeableEntry);
            let safeEntry = (await this.root.db.templates.get(localId))!;
            return {
                status: {
                    failed: false,
                    message: 'Success'
                },
                object: Object.assign(new ImmutableTemplate(), safeEntry)
            };
        } catch (e) {
            logger.error('Templates, Error saving template', e);
            return {
                status: {
                    failed: true,
                    message: 'Failed save'
                },
                object: entry
            };
        }
    }
    write = async () => {
        let dirtyEntries = await this.root.db.templates.filter(te => te.serverDirty || false).toArray();
        if ( dirtyEntries.length === 0) {
            return;
        }
        const emitEntries: ImmutableTemplate[] = [];
        const sessionId = localStorage.getItem('sessionId');
        const toWrite = dirtyEntries.map(te => {
            let newte = Object.assign(new ImmutableTemplate(), te);
            if ( newte.id! < 0) {
                // localUid must be unique to troubleshoot duplicate timers
                newte.localUid = `${newte.localId}_${sessionId}`;
                newte.id = undefined;
            }
            return newte;
        });
        const results = await this.root.webImpl.Template.updateTemplates(toWrite);
        // tslint:disable-next-line:no-any
        let proms: Promise<any>[] = [];
        for (let i = 0; i < results.length; i++) {
            let curRes = results[i];
            let localEntry = dirtyEntries[i]!;
            const localEntryId = localEntry.localId! * -1;
            if (curRes.status.failed) {
                // failed write, do something
            } else {
                (curRes.object as Template).localId = localEntry.localId;
                proms.push(this.root.db.templates.put(curRes.object));
                if (curRes.object.deleted && curRes.object.id) { 
                    this.root.db.timers.where({templateId: curRes.object.id})
                        .modify({templateId: null});
                }
                if (localEntry.id! < 0) {
                    localEntry.deleted = true;
                    // id less than 0, only local 
                    emitEntries.push(Object.assign(new ImmutableTemplate(), localEntry));
                }
                emitEntries.push(Object.assign(new ImmutableTemplate(), curRes.object));
            }
        }
        await Promise.all(proms);
        this.handlers.filter(h => h !== null).forEach(h => h!(emitEntries));
    }
    recieve = async (templates: Template[]): Promise<void> => {
        const promArray = templates.map((te) => this.root.db.templates.get({ id: te.id! }));
        let localTes = await Promise.all(promArray);
        templates.forEach((te, idx) => {
            if (localTes[idx]) {
                // tslint:disable-next-line: no-any
                (te as any).localId = localTes[idx]!.localId;
            }
        });
        await this.root.db.templates.bulkPut(templates);
        let tes = templates.map(te => Object.assign(new ImmutableTemplate(), te));
        this.handlers.filter(h => h !== null).forEach(h => h!(tes));
    }
}