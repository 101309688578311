import { observable, action, makeObservable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import DialogRootStore from 'store/dialog.root.store';
import { DateTime } from 'luxon';
import { EULA } from 'api/types/types';
import { RootStore } from 'store/root.store';

export default class EULADialogStore extends DialogRootStore<void, boolean> {
    @observable hideEula: boolean = false;
    @observable eulaText: string = '';
    lastModified: Date;
    eula: EULA;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @loadable()
    @action.bound 
    async onOpen() {
        if (this.eula === undefined) {
            this.eula = await this.rootStore.api.Session.getEulaText();
        }
        this.eulaText = this.eula.eulaText;
        this.lastModified = this.eula.lastModified;
    }

    @action.bound
    setHideEula(value: boolean) {
        this.hideEula = value;
    }

    @action.bound 
    saveEulaAcceptance() {
        const today = DateTime.local().setZone('local');
        localStorage.setItem('hideEula', JSON.stringify(this.hideEula));
        localStorage.setItem('EulaAcceptedDate', JSON.stringify(today));
        this.resolveAndClose(true);
    }
}