// tslint:disable:max-line-length
import CodeAPI from 'api/interfaces/CodeAPI';
import { ActionCode, Code, CodeType, CodeSetFlags } from 'api/types/types';
import BaseElectronImplementation from './Base.impl';
import { DateTime } from 'luxon';
const CodeFilterer = (searchText: string) => (code: Code): boolean => {
    const compare = `${code.name} - ${code.description}`.toLowerCase();
    return compare.includes(searchText.toLowerCase());
}
export default class CodeImpl extends BaseElectronImplementation implements CodeAPI  {
    validateCodeSetOnWorkDate = (start: string, end: string, workDate: string) => {
        return DateTime.fromISO(start) <= DateTime.fromISO(workDate) && DateTime.fromISO(end) >= DateTime.fromISO(workDate);
    }
    get = async (id: number) => {
        return (await this.root.db.codes.get(id))!;
    }
    getAll = async() => {
        return (await this.root.db.codes.toArray());
    }
    getPhaseCodes = async (matterId: number, workDate?: string, search?: string) => {
        let codeSets = (await this.root.db.codeSetMappings
            .where({matterId, type: CodeType.PHASE})
            /* In Templates, there is no Work Date, so return all codes */
            .filter(c => workDate ? this.validateCodeSetOnWorkDate(c.startDate, c.endDate, workDate) : true)
            .toArray()
        ).map(cs => cs!.codeSet);

        let codeFetchProms = codeSets.map(cs => {
            return this.root.db.codes
                .where({codeSet: cs, type: CodeType.PHASE})
                .filter(c => {
                    if (c.deleted) {
                        return false;
                    }
                    if (workDate) {
                        return this.validateCodeSetOnWorkDate(c.startDate!, c.endDate!, workDate);
                    }
                    return true;
                })
                .toArray()
        });
        let codeArrays = await Promise.all(codeFetchProms);
        return codeArrays.reduce((prev, cur) => prev.concat(cur), [])
            .filter(CodeFilterer(search || ''))
            .sort(this.sortCodesByAscending);
    }
    getActivityCodes = async (matterId: number, workDate?: string, search?: string) => {
        let codeSets = (await this.root.db.codeSetMappings.where({matterId, type: CodeType.ACT})
            .filter(c => workDate ? this.validateCodeSetOnWorkDate(c.startDate, c.endDate, workDate) : true)
            .toArray()
        ).map(cs => cs!.codeSet);

        let codeFetchProms = codeSets.map(cs => {
            return this.root.db.codes.where({codeSet: cs, type: CodeType.ACT})
                .filter(c => {
                    if (c.deleted) {
                        return false;
                    }
                    if (workDate) {
                        return this.validateCodeSetOnWorkDate(c.startDate!, c.endDate!, workDate);
                    }
                    return true;
                })
                .toArray()
        });
        let codeArrays = await Promise.all(codeFetchProms);
        
        return codeArrays.reduce((prev, cur) => prev.concat(cur), [])
            .filter(CodeFilterer(search || ''))
            .sort(this.sortCodesByAscending);
    }
    getFFTaskCodes = async (matterId: number, workDate?: string, search?: string) => {
        let codeSets = (await this.root.db.codeSetMappings.where({matterId, type: CodeType.FFTASK})
            .filter((c) => workDate ? this.validateCodeSetOnWorkDate(c.startDate, c.endDate, workDate) : true)
            .toArray()
        ).map(cs => cs!.codeSet);

        let codeFetchProms = codeSets.map(cs => {
            return this.root.db.codes.where({codeSet: cs, type: CodeType.FFTASK})
                .filter(c => {
                    if (c.deleted) {
                        return false;
                    }
                    if (workDate) {
                        return this.validateCodeSetOnWorkDate(c.startDate!, c.endDate!, workDate);
                    }
                    return true;
                })
                .toArray()
        });
        let codeArrays = await Promise.all(codeFetchProms);
        
        return codeArrays.reduce((prev, cur) => prev.concat(cur), [])
            .filter(CodeFilterer(search || ''))
            .sort(this.sortCodesByAscending);
    }
    getTaskCodes = async (phaseId: number, workDate?: string, search?: string) => {
        const phase = await this.get(phaseId);
        return (await this.root.db.codes.where({codeSet: phase.name, type: CodeType.TASK})
                .filter(c => {
                    if (c.deleted) {
                        return false;
                    }
                    if (workDate) {
                        return this.validateCodeSetOnWorkDate(c.startDate!, c.endDate!, workDate);
                    }
                    return true;
                })
                .filter(CodeFilterer(search || ''))
                .toArray()
            ).sort(this.sortCodesByAscending);
    }
    getFFActCodes = async (ffTaskCodeId: number,  workDate?: string, search?: string) => {
        const fftaskcode = await this.get(ffTaskCodeId);
        return (await this.root.db.codes.where({codeSet: fftaskcode.name, type: CodeType.FFACT})
                .filter(c => {
                    if (c.deleted) {
                        return false;
                    }
                    if (workDate) {
                        return this.validateCodeSetOnWorkDate(c.startDate!, c.endDate!, workDate);
                    }
                    return true;
                })
                .filter(CodeFilterer(search || ''))
                .toArray()
            ).sort(this.sortCodesByAscending);
    }
    getActionCodes = async (matterId?: number, actionCode?: string, search?: string) => {
        const ActionCodeFilter = (code: ActionCode | null) => {
            if (code === null) {
                return false;
            }
            return `${code.actionCode} - ${code.actionText}`.toLowerCase().includes((search || '').toLowerCase());
        }
        if (!matterId) {
            return (await this.root.db.actionCodes.filter(aci => !aci.inactive).toArray())
                .map(aci => ({
                    id: aci.id,
                    actionCode: aci.actionCode,
                    actionResponse: '',
                    actionText: aci.actionText,
                    stopEntry: false
                })).filter(ActionCodeFilter);
        } else {
            const matterGroup = (await this.root.Matter.get(matterId)).matterGroup || '';
            let codes = await Promise.all((await this.root.db.actionCodeMappings
                    .where({matterGroup})
                    .toArray()
                ).map(async (acm) => {
                    let co = await this.root.db.actionCodes.get(acm.actionCodeId);
                    if (!co) {
                        return null;
                    }
                    return {
                        id: co!.id,
                        actionCode: co!.actionCode,
                        actionResponse: acm.actionResponse,
                        actionText: co!.actionText,
                        stopEntry: acm.stopEntry
                    };
                })
            );
            return codes.filter(ActionCodeFilter) as ActionCode[];
        }
    }
    determineCodeSetFields = async (matterId: number, workDate?: string): Promise<CodeSetFlags> => {
        const phaseCodes = await this.getPhaseCodes(matterId, workDate);
        const actCodes = await this.getActivityCodes(matterId, workDate);
        const ffTaskCodes = await this.getFFTaskCodes(matterId, workDate);
        const isCodes: CodeSetFlags = {
            isPhaseCode: phaseCodes.length > 0,
            isActCode: actCodes.length > 0,
            isFfTaskCode: ffTaskCodes.length > 0,
            phases: phaseCodes,
            activities: actCodes,
            ffTasks: ffTaskCodes
        };
        return isCodes;
    }
    sortCodesByAscending = (code1: Code, code2: Code): number => {
        let compare1 = code1.name.localeCompare(code2.name);
        let compare2 = code1.description.localeCompare(code2.description);
        return compare1 || compare2;
    }
}