import * as React from 'react';
import * as Styled from './styled';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

const NUM_DAYS_IN_WEEK: number = 7;
const DAYS_IN_WEEK: string[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export enum Mode {
    MONTH = 'MONTH', 
    WEEK = 'WEEK'
}

export interface Props {
    style?: React.CSSProperties;
    
    /** Current date of the calender */
    currentDate: DateTime;
    /** View calendar as Month or Week */
    mode?: Mode;
    /** Sets the start day of the week. 1 = Monday, 7 = Sunday */
    /** MON TUES WED THURS FRI SAT SUN */
    startOfWeek?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
    /**
     *  A component that renders each day cell
     *  @param date luxon DateTime for which the cell will be rendered
     *  @return component to be rendered
     */
    cellComponent?: (date: DateTime) => JSX.Element;
    alignDays?: 'default' | 'center';
    // tslint:disable-next-line:no-any
    t?: any;
}

/**
 *  Gets the beginning of month or weekday
 *  @param date luxon DateTime to find the start of
 *  @param type specify the start of either week or month
 *  @param startOfWeek the day that is start of the week
 *  @return luxon DateTime that is start of week or month
 */
export const getStartOf = (date: DateTime, type: 'week' | 'month', startOfWeek?: number) => {
    if (!startOfWeek) {
        startOfWeek = 7;
    }
    let ret = DateTime.utc(date.year, date.month, date.day);
    if (type === 'month') {
        ret = ret.startOf('month');
    }
    let diff = startOfWeek! - ret.weekday;
    const offset = diff > 0 ? diff - 7 : diff;
    ret = ret.plus({ days: offset });
    return ret;
}

export const getEndOfWeek = (date: DateTime, startOfWeek: number) => {
    return date.endOf('day').plus({ days: 6 });
}

/**
 *  Get a shifted array of days in the week
 *  @return string[] of days in week
 */
// tslint:disable-next-line:no-any
export const getDayNames = (startOfWeek: number, t: any) => {
    let ret: string[] = [];
    let idx = startOfWeek! - 1;
    for (let i = 0; i < NUM_DAYS_IN_WEEK; i++) {
        ret[i] = t(`weekdays.appreviation.${DAYS_IN_WEEK[idx]}`, { ns: 'home' });
        if (++idx === 7) {
            idx = 0;
        }
    }
    return ret;
}
@observer
class Calendar extends React.Component<Props> {
    public static defaultProps: Props = {
        style: {},
        currentDate: DateTime.local(),
        mode: Mode.MONTH,
        startOfWeek: 7,
        cellComponent: (date: DateTime) => <Styled.DayBox>{date.day}</Styled.DayBox>,
    }
    
    /**
     *  @return days of week component that is to be rendered above the calendar
     */
    weekHeader = () => {
        const { startOfWeek } = this.props;
        return (
            <Styled.WeekHeaderContainer>
                {
                    getDayNames(startOfWeek!, this.props.t).map((day, idx) => {
                        return (
                            <Styled.Day 
                                key={idx}
                                align={this.props.alignDays}
                            >
                                {day}
                            </Styled.Day>
                        )
                    })
                }
            </Styled.WeekHeaderContainer>
        );
    }
    
    /**
     *  @return JSX.Element[] of cells to be rendered
     */
    buildCalender = () => {
        const { currentDate, cellComponent, startOfWeek, mode } = this.props;
        let ret: JSX.Element[] = [];
        let key = 0;
        if (mode === Mode.MONTH) {
            let curDate = getStartOf(currentDate, 'month', startOfWeek!);
            while (curDate <= currentDate.endOf('month').startOf('day')) {
                for (let i = 0; i < NUM_DAYS_IN_WEEK; i++) {
                    ret.push(
                        <Styled.DayBoxWrapper key={key}>
                            {cellComponent!(curDate)}
                        </Styled.DayBoxWrapper>
                    );
                    curDate = curDate.plus({ days: 1 });
                    key++;
                }
            }
        } else if (mode === Mode.WEEK) {
            let curDate = getStartOf(currentDate, 'week', startOfWeek!);
            for (let i = 0; i < NUM_DAYS_IN_WEEK; i++) {
                ret.push(
                    <Styled.DayBoxWrapper key={key}>
                        {cellComponent!(curDate)}
                    </Styled.DayBoxWrapper>
                );
                curDate = curDate.plus({ days: 1 });
                key++;
            }
        }
        return ret;
    }
    
    render() {
        const { mode } = this.props;
        
        return (
            <Styled.Container style={this.props.style}>
                {this.weekHeader()}
                <Styled.Calendar>
                    {this.buildCalender()}
                </Styled.Calendar>
            </Styled.Container>
        );
    }
}

export default withTranslation(['home'])(Calendar);