import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { Save, Check, Add, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import * as Styled from './styled';
import scrollIntoView from 'scroll-into-view-if-needed';
import { RootStoreContext } from '../../App';
import GridItem from './GridItem';
import { LoadingDiv } from 'containers/Home/Home';

interface Props {
    startDate: DateTime;
    endDate: DateTime;
}

function GridView(props: Props) {
    const actionRef = useRef(null);
    const rootStore = useContext(RootStoreContext);
    const { t } = useTranslation([ 'home', 'common' ]);
    const { startDate, endDate } = props;
    const {
        loading,
        timeEntryGroups,
        validationStates,
        validationStatesDurations,
        totalPerDayMap,
        expandAll,
        toggleExpandAll,
        init,
        wrappedSaveAll,
        wrappedPostAll,
        addGroup,
        weekDays
    } = rootStore.gridViewStore;

    useEffect(() => {
        const workLocaleId = rootStore.homeStore.getTkWorkLocale();
        if (workLocaleId) {
            rootStore.gridViewStore.setTempWorkLocaleId(workLocaleId);
        }
    }, []);

    useEffect(() => {
        init({ startDate, endDate });
    }, [startDate, endDate]);

    const isTotalDurationValid = (): boolean => (
        weekDays.every(day => {
            const total: number = totalPerDayMap.get(day) || 0;
            return (total >= 0) && (total <= 24);
        })
    );

    const getValue = (day: string) => {
        const value = totalPerDayMap.get(day) || 0;
        return Math.round(value * 100) / 100;
    }

    const getWeekTotal = () => {
        let weekTotal = 0;
        totalPerDayMap.forEach(totalPerDay => {
            weekTotal += totalPerDay;
        });
        return Math.round(weekTotal * 100) / 100;
    }

    const onAdd = () => {
        setTimeout(() => {
            let elem: HTMLElement = ReactDOM.findDOMNode(actionRef.current) as HTMLElement;
            scrollIntoView(elem, { behavior: 'smooth', scrollMode: 'if-needed' });
        }, 0);
        addGroup();
    }

    return (
        <Styled.GridViewContainer>
            <Styled.GridHeaderContainer>
                <Styled.HeaderIcon>
                    <Tooltip title={t(`view.grid.header.icon.${expandAll ? 'collapse_all' : 'expand_all'}`)}>
                        <IconButton onClick={toggleExpandAll} style={{ height: '48px' }}>
                            {expandAll ? <KeyboardArrowUp/> : <KeyboardArrowDown />}
                        </IconButton>
                    </Tooltip>
                </Styled.HeaderIcon>
                <Styled.HeaderDate>
                    {weekDays.map((day, idx) =>
                        <Styled.Date key={idx}>
                            <Styled.Day>{day}</Styled.Day>
                            <Styled.Day>
                                {startDate.plus({ day: idx })
                                    .toLocaleString()
                                    .match(/[0-9]{1,2}\/[0-9]{1,2}/i)}
                            </Styled.Day>
                        </Styled.Date>
                    )}
                </Styled.HeaderDate>
            </Styled.GridHeaderContainer>
            {timeEntryGroups.map((group) =>
                <GridItem
                    key={group.id}
                    group={group}
                    validationState={validationStates.get(group.id)}
                    validationStateDurations={validationStatesDurations.get(group.id)}
                    expandAll={expandAll}
                />
            )}
            <Styled.GridFooterContainer>
                <Styled.FooterLabel>
                    {t('view.grid.footer.total_hours.label')}
                </Styled.FooterLabel>
                <Styled.FooterDuration>
                    <Styled.Days>
                        {weekDays.map((day, idx) =>
                            <Styled.Day key={idx}>
                                <TextField
                                    disabled={true}
                                    variant="outlined"
                                    value={getValue(day)}
                                    inputProps={{
                                        style: { padding: '14px 2px' }
                                    }}
                                />
                            </Styled.Day>
                        )}
                    </Styled.Days>
                    {!isTotalDurationValid() &&
                        <Styled.Error>
                            {t('view.grid.footer.total_hours.validation')}
                        </Styled.Error>
                    }
                </Styled.FooterDuration>
                <Styled.FooterTotalDuration>
                    <Styled.Day>
                        <TextField
                            disabled={true}
                            variant="outlined"
                            value={getWeekTotal()}
                            inputProps={{
                                style: { padding: '14px 2px' }
                            }}
                        />
                    </Styled.Day>
                </Styled.FooterTotalDuration>
            </Styled.GridFooterContainer>
            <Styled.Actions ref={actionRef}>
                <Tooltip title={t('view.grid.action.add.tooltip')}>
                    <IconButton onClick={onAdd}>
                        <Add />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('save', { ns: 'common' })}>
                    <IconButton onClick={wrappedSaveAll}>
                        <Save />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('view.grid.action.post.tooltip')}>
                    <IconButton onClick={wrappedPostAll}>
                        <Check />
                    </IconButton>
                </Tooltip>
            </Styled.Actions>
            {loading && <LoadingDiv />}
        </Styled.GridViewContainer>
    );
}

export default observer(GridView);