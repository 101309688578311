import * as React from 'react';
import ManagementDashboard from './ManagementDashboard';

function ProjectTime() {

    return (
        <ManagementDashboard />
    );
}

export default ProjectTime;