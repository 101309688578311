import SupersetSessionAPI from 'api/interfaces/SupersetSessionAPI';
import BaseElectronImplementation from './Base.impl';
import { AxiosInstance } from 'axios';

export default class SupersetSessionImpl extends BaseElectronImplementation implements SupersetSessionAPI {
    axios: AxiosInstance;
    initialize = async () => {
        this.root.webImpl.SupersetSession.initialize();
        this.axios = this.root.webImpl.SupersetSession.axios;
    };
}
